.to-do-teams{
  .container-message{
    font-size: 44px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: #1D2130;
    color: #FFF;
  }

  @media only screen and (max-width: 1024px){
    .container-message{
      font-size: 16px;
      padding: 12px;
    }
  }

}