@import "utils";

$icon-color : #787878;
$icon-arrow-height-svg : 8px;
$button-color : #2194F1;
$button-color-green : #4AD991;
$button-color-disabled: #D7DAE2;
$text-color : #787878;
$button-color-red : #FF6565;
$icon-arrow-color : #A4AFB7;
$placeholder-color: #D7DAE2;
$tablet-view-pixel: 1024px;
$mobile-view-pixel: 768px;
$font-family: 'Open Sans', sans-serif;
$font-weight-bold: bold;
$font-weight-semi-bold: 600;
$font-weight-regular: 400;
$font-weight-light: 200;
$button-color-blue : #2194F1;
$height-container-header: 56px;
$input-border-color: #d9d9d9;
$input-background-color: #fff;

.bold-label{
  font-weight: $font-weight-bold !important;
}

.item-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-next-done{
  width: 70px;
  height: 32px;
  border-radius: 5px;
  color: #FFF;
}

.shadow-body{
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.shadow-header{
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
}

.container-icon-body-title{
  width: 100%;
  padding: 5px;
  border: 1px solid #f4be52;
  display: flex;
  border-radius: 5px;
  .container-icon-exclamation{
    padding-left: 3px;
    .icon-error{
      >div{
        display: flex;
        svg{
          fill: #ffca83;
          border: 1px solid #fff4e5;
          border-radius: 50px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .container-message{
    padding-left: 8px;
    .title-message{
      margin: 0;
      font-weight: $font-weight-semi-bold;
      color: #797979;
    }
  }
}

.payment-type{
  width: 160px;
  display: flex;
  align-items: center;
  border: 1px solid #D7DAE2;
  border-radius: 5px;
  padding: 5px 5px;
  height: 31px;
  .icon-getting-finance, .icon-cash{
    float: left;
    padding: 3px 5px 0 0;
    .icon-getting-finance-svg{
      width: 18px;
      height: 23px;
      .a{
        fill: #ffffff;
      }
    }
    .icon-cash-svg{
      width: 19px;
      height: 21px;
      .a{
        stroke: none;
        fill: #ffffff;
      }
      .b{
        fill: #c4c8ce;
      }
      .d{
        fill: #2fa52f;
      }
    }
  }
  label{
    color: #7F7F7F;
    font-size: 13px;
    margin-bottom: 3px;
  }
}

@mixin user-box-general(){
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0 0 10px #A4AFB7;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D7DAE2;
  .image-user {
    box-shadow: 0 0 10px #A4AFB7;
  }
  .image-user-default {
    width: 16px;
    height: 16px;
  }
}

.user-box-general{
 @include user-box-general;
}

@mixin text-salesman(){
  display: block;
  width: calc(100% - 50px);
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.text-salesman{
 @include text-salesman;
}

.sales-box{
  width: 100%;
  background-color: #fff;
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  .sales-filter{
    float: left;
    width: 100%;
    .left{
      float: left;
      padding: 14px 20px 14px 25px;
      .button-add{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: $button-color-green;
        border-radius: 4px;
        border: 1px solid $button-color-green;
        height: 32px;
        width: 80px;
        font-size: 13px;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
    }
  }

  .btn-container-dp-user {
    padding-right: 10px;
    width: 180px;
  }

  .table-customer .tr-customer .td-phone{
    .image-user{
      width: 19px;
      height: 19px;
      margin: 0 3px 0 5px;
    }

    .image-user-default{
      width: 10px;
      height: 10px;
    }
  }

  .table-customer {
    .th-photo{
      padding-left: 25px;
    }
    .th-user{
      width: 180px !important;
      padding-left: 10px;
    }
    .th-car{
      width: 300px;
    }
    .th-status{
      width: 100px;
    }
    .td-image{
      padding-left: 25px;
    }
    .td-name,.td-car{
      padding-left: 10px;
    }
    .container-car-name-message{
      width: 100%;
      display: block;
      position: relative;
      .container-car-temporary{
        position: relative;
        float: left;
        .car-temporary-floating{
          display: none;
        }
        .car-temporary{
          width: 100%;
          color: #EAA734;
          font-size: 11px;
          padding: 2px 5px;
          border: 1px solid;
          border-radius: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 114px;
          float: left;
        }
        &:hover{
          .car-temporary-floating{
            display: block;
            position: absolute;
            left: 0;
            top: 23px;
            background: #FFF;
            color: $text-color;
            font-size: 11px;
            padding: 2px 5px;
            border: 1px solid $text-color;
            border-radius: 4px;
            width: 114px;
          }
        }
      }

      .car-name{
        padding-right: 5px;
        width: auto;
        float: left;
      }
    }
  }

  .btn-general-message-dp-customer{
    padding: 2px;
    display: flex;
    align-items: center;
    span{
      width: calc(100% - 19px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .table-customer .tr-customer td, .table-customer .tr-customer .td-phone {
    color: #8F979D;
  }

  .table-customer .tr-customer .image-user-default {
    width: 16px;
    height: 16px;
  }

  .user-box{
    @include user-box-general;
  }

  .table-customer .tr-customer .image-user {
    box-shadow: 0 0 10px #A4AFB7;
  }

  .td-phone .user-box{
    width: 20px;
    height: 20px;
    float: left;
    margin: 2px 3px 0 5px;
  }

  .table-customer{
    .th-actions{
      min-width: 62px;
      width: 62px;
      padding-right: 25px;
    }
    .tr-customer{
      &:hover{
        .customer-actions{
          position: relative;
          right: 15px;
          margin-top: 0;
          span{
            display: flex !important;

            .ic-cancel-sale{
              .ic-cancel-sale-svg{
                fill: $button-color-red;
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
      .img-delete{
        margin-left: 8px !important;
        width: 15px !important;
        height: 16px !important;
      }
    }
  }

  .salesman-assigned{
    border: 1px solid #ccc !important;
    border-radius: 4px;
    height: 31px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    position: relative;
    width: 160px;
    background-color: white;
    label{
      @include text-salesman;
      color: #4D4F5C;
      font-size: 12px;
      margin-bottom: 0;
      font-weight: $font-weight-regular;

    }
    .salesman-img{
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      box-shadow: 1px 1px 4px 2px rgba(164,175,183,1);
      margin-right: 8px;
    }
    .icon-arrow-content{
      position: absolute;
      padding: 1px 0;
      right: 10px;
      .icon-arrow-expand{
        height: $icon-arrow-height-svg;
        fill: #A4AFB7;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }

  .btn-filters, .btn-filters-background{
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: center;
    border: 1px solid #D7DAE2 !important;
    background-color: #ffffff;
    border-radius: 5px;
    height: 31px;
    padding: 0;
  }

  .btn-filters-background{
    background-color: #2194F1;
  }

  .label-btn-filter, .label-btn-filter-background{
    color: #8F979D;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    margin-bottom: 0;
    font-size: 13px;
    &:hover{
      cursor: pointer;
    }
    .label-num-filters{
      color: #2194F1;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  .label-btn-filter-background{
    color: #ffffff;
  }

  .ic-more-filter{
    margin: 2px 5px 0 0;
    .ic-more-filter-svg, .ic-more-filter-svg-background{
      height: 12px;
      width: 12px;
      fill: #8F979D;
    }
    .ic-more-filter-svg-background{
      fill: #ffffff;
    }
  }

  .content-filters{
    width: 100%;
    border-top: 1px solid #EFEFEF;
    float: left;
    padding: 20px 25px;
    .first-row-filter, .second-row-filter{
      width: 100%;
      display: flex;
      .filter-sale-field, .filter-sale-field-checkbox{
        width: 16%;
        margin-right: 10px;
        .title-field{
          font-size: 9px;
          color: #3C3C3C;
          font-family: $font-family;
          font-weight: $font-weight-regular;
        }
        .form-sale{
          height: 34px;
        }
      }
      .filter-sale-field-checkbox{
        display: flex;
        padding-top: 22px;
        .container-check-form{
          margin-left: 18px;
          .checkmark-form{
            height: 20px;
            width: 20px;
          }
          .i-check{
            left: -14px;
            top: 10px;
            font-size: 13px;
          }
        }
        .span-text{
          padding: 8px 0 0 7px;
          font-size: 13px;
          color: $text-color;
          font-family: $font-family;
          font-weight: $font-weight-regular;
        }
      }
    }
    .second-row-filter{
      margin-top: 10px;
    }
    .third-row-filter{
      width: 100%;
      margin-top: 15px;
      position: relative;
      .btn-filter{
        float: right;
        background-color: #3A3A53;
        border: 1px solid #D7DAE2;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 60px;
        height: 32px;
        border-radius: 4px;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
      .btn-clear{
        float: right;
        color: #8F979D;
        background-color: #ffffff;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 32px;
        margin-right: 20px;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
    }
  }

  .sale-date{
    width: 100%;
    div{
      cursor: pointer !important;
      input{
        font-size: 13px !important;
        font-family: $font-family !important;
        font-weight: $font-weight-regular !important;
      }
    }
    div.MuiInput-underline::before{
      border-bottom: none;
    }
    div.MuiInput-underline::after{
      border-bottom: none;
    }
  }

  .ic-calendar{
    display: flex;
    padding-top: 3px;
    .ic-calendar-svg{
      fill: #A4AFB7;
      width: 18px;
      height: 16px;
      &:hover{
        cursor: pointer;
      }
    }
  }

  .field-assigned{
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    position: relative;
    label{
      color: #D7DAE2;
      font-size: 13px;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      margin-bottom: 0;
      &:hover{
        cursor: pointer;
      }
    }
    .icon-arrow-content{
      position: absolute;
      right: 10px;
      padding: 1px 0;
      .icon-arrow-expand{
        height: $icon-arrow-height-svg;
        fill: #A4AFB7;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }

  .message-not-found{
    width: 100%;
    background-color: #e5e9ec;
    height: 40px;
    float: left;
    h3{
      padding-top: 0;
      margin-top: 7px;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }
  }
}

.container-loading{
  @include item-center;
}

.sale-cosigner{
  display: flex;
  padding-bottom: 15px;
  img{
    width: 32px;
  }

  .cosigner-info{
    padding-left: 10px;
    color: $text-color;
    font-size: 13px;
    span{
      display: flex;
    }
  }
}

.cosigner-title{
  color: #2194F1;
  font-size: 15px;
  margin-bottom: 10px;
}

.sales-pagination {
  float: right;
  padding: 13px 22px;
  right: 35px;
}

.col100 {
  width: 100%;
  float: left;
}

@mixin label-info(){
  font-size: 13px;
  margin-bottom: 0;
  font-weight: $font-weight-regular;
  color: $text-color;
  width: 100%;
}

.label-info{
  @include label-info;
}

.message-bar{
  position: absolute;
  top: $height-container-header;
  display: flex;
  align-items: center;
  height: 34px;
  width: 100%;
  left: 50px;
  label{
    color: #ffffff;
    font-size: 13px;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    padding-left: 40px;
    margin-bottom: 0;
  }
  .icon-close{
    position: absolute;
    right: 70px;
    .icon-close-svg{
      fill: #ffffff;
      width: 10px;
      height: 10px;
      &:hover{
        cursor: pointer;
      }
    }
  }
}

.container-image-purchaser{
  width: 100%;
  display: flex;
  padding: 10px 0 0 0;
  margin-bottom: 10px;
  .container-icon{
    height: 58px;
    @include item-center;
    width: 10px;
    .icon-arrow-content{
      float: left;
      margin-right: 10px;
      .icon-arrow{
        float: left;
        .icon-arrow-svg{
          height: $icon-arrow-height-svg;
          fill: #A4AFB7;
          width: 8px;
        }
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
  .container-icon-purchaser{
    height: 58px;
    width: 58px;
    border-radius: 50%;
    box-shadow: 0 0 10px #A4AFB7;
    margin-left: 5px;
    @include item-center;
    .icon-purchaser{
      height: 58px;
      width: 58px;
      border-radius: 50%;
      object-fit: cover;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .container-info-purchaser{
    height: 100%;
    margin-left: 15px;
    width: 80%;
    .content-name-purchaser{
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .name-purchaser{
        font-size: 13px;
        color: $text-color;
        font-weight: $font-weight-regular;
        margin-bottom: 0;
        //width: 90%;
        font-family: $font-family;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 190px;
        white-space: nowrap;
        &:hover{
          cursor: pointer;
        }
      }
      .icon-right{
        color: #808495;
        font-size: 8px;
        right: 10px;
        position: absolute;
        &:hover{
          //--cursor: pointer;
        }
      }
    }
    .email-purchaser, .phone-purchaser{
      font-size: 13px;
      color: $text-color;
      margin-bottom: 0;
      width: 100%;
      font-weight: $font-weight-regular;
      font-family: $font-family;
      &:hover{
        cursor: pointer;
      }
    }
    .phone-purchaser{
      margin-bottom: 5px;
      &:hover{
        cursor: pointer;
      }
    }
    .content-credit-score{
      border: 1px solid #8F979D;
      border-radius: 3px;
      width: 120px;
      @include item-center;
      padding: 1px 0;
      .title-credit-score{
        font-size: 10px;
        color: $text-color;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 0;
        font-family: $font-family;
        &:hover{
          //--cursor: pointer;
        }
      }
      &:hover{
        cursor: pointer;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
  &:hover{
    cursor: pointer;
  }
}

.sale-content{
  .container-header{
    z-index: 30;
    left: 20px;
    @include shadow-header;
    padding-left: 20px;
    display: flex;
    align-items: center;
    height: $height-container-header;
    .title{
      flex: 1;
      display: flex;
      color: #787878;
      font-size: 21px;
      margin: 0;
      padding-left: 30px;
      font-weight: $font-weight-bold;
    }
    .left {
      float: left;
      display: flex;
      align-items: center;
      position: fixed;
      padding-left: 5px;
      label {
        font-size: 21px;
        color: $icon-color;
        font-weight: $font-weight-bold;
      }

      .arrow-back {
        float: left;
        padding-right: 20px;

        .icon-back {
          width: 16px;
          fill: #2194F1;
          .a, .d {
            fill: #2194F1;
          }
        }
        &:hover{
          cursor: pointer;
        }
      }

      .action-add-car{
        float: left;
        padding-left: 10px;

        .icon-add-task{
          width: 44px;
          height: 47px;
          fill: #4ad991;
        }
      }

      .icon-add{
        float: left;
        padding-right: 20px;
        width: 44px;
        height: 47px;
        .icon-add-svg{
          width: 44px;
          height: 47px;
          fill: #4ad991;
        }
      }
    }
    .container-button-next {
      padding-right: 40px;
      display: flex;
      position: absolute;
      right: 0;
      .container-button-cancel{
        display: flex;
        .container-label-reject, .container-message{
          padding-right: 20px;
          display: flex;
          align-items: center;
          label{
            font-weight: $font-weight-semi-bold;
            margin: 0;
            &:hover{
              cursor: pointer;
            }
          }
          .label-show-modal{
            color: $button-color-red;
            text-decoration: underline;
            font-size: 10px;
          }
          .btn-cancel-sale{
            color: $text-color;
            background-color: #ffffff;
            border: none;
            font-family: $font-family;
            font-weight: $font-weight-regular;
          }
        }
        .box-button{
          padding-right: 5px;
          .btn-next{
            @include buttons-next-done;
            border: 1px solid $button-color;
            background-color: $button-color;
            &:focus{
              border: 1px solid $button-color !important;
            }
          }
          .btn-done{
            @include buttons-next-done;
            width: 114px;
            border: 1px solid $button-color-green;
            background-color: $button-color-green;
            font-family: $font-family;
            font-weight: $font-weight-regular;
            &:focus{
              border: 1px solid $button-color-green !important;
            }
          }
          .btn-done-disabled{
            @include buttons-next-done;
            width: 114px;
            border: 1px solid $button-color-disabled;
            background-color: $button-color-disabled;
          }
          .btn-completed{
            background-color: white;
            color: $button-color-green;
            width: 120px;
            height: 34px;
            &:hover{
              background-color: $button-color-green;
              color: white;
            }
          }
          .btn-cancelled{
            background-color: white;
            color: $button-color-red;
            border-color: $button-color-red;
            width: 120px;
            height: 34px;
            &:focus{
              border-color: $button-color-red !important;
            }
            &:hover{
              background-color: $button-color-red;
              color: white;
            }
          }
        }
      }
      .container-salesman{
        display: flex;
        align-items: center;
        padding-right: 10px;
        .title-salesman{
          color: #A4AFB7;
          float: left;
          padding-right: 10px;
          margin-bottom: 0;
          font-size: 13px;
          font-weight: 600;
        }
        .salesman-assigned{
          border: 1px solid #ccc;
          border-radius: 4px;
          height: 34px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          position: relative;
          width: 160px;
          background-color: #FFFFFF;
          label{
            @include text-salesman;
            color: #4D4F5C;
            font-size: 12px;
            margin-bottom: 0;
            font-weight: $font-weight-regular;
            font-family: $font-family;
          }
          .salesman-img{
            width: 21px;
            height: 21px;
            border-radius: 50%;
            border: 1px solid #FFFFFF;
            box-shadow: 1px 1px 4px 2px rgba(164,175,183,1);
            margin-right: 8px;
          }
          .icon-arrow-content{
            position: absolute;
            padding: 1px 0;
            right: 10px;
            .icon-arrow-expand{
              height: $icon-arrow-height-svg;
              fill: #A4AFB7;
            }
          }
          &:hover{
            //--cursor: pointer;
          }
          &:focus{
            border: 1px solid #ccc !important;
          }
        }
      }
    }
  }
  .container-message-delete{
    background-color: #2194F1;
    position: absolute;
    top: $height-container-header;
    display: flex;
    align-items: center;
    height: 30px;
    width: 100%;
    left: 50px;
    label{
      color: #ffffff;
      font-size: 13px;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      padding-left: 40px;
      margin-bottom: 0;
    }
  }
  .message-bar-sale {
    background-color: #2194F1;
  }
  .message-bar-complete {
    background-color: #EAA734;
  }
  .container-center {
    padding: 80px 30px 0 30px;
    @mixin panel-sale(){
      min-height: 680px;
      background: white;
      border-radius: 7px;
      float: left;
      @include shadow-body;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
      display: flex;
      .panel-sale-left{
        width: 100%;
        min-height: 100%;
        float: left;
        padding: 20px;

        .right-element{
          width: 25px;
          position: absolute;
          right: 0;
          .icon-add-trade_in, .icon-view-trade_in{
            width: 14px;
            display: inline-block;
            vertical-align: middle;
            div{
              width: 100%;
              display: inline-block;
              vertical-align: middle;
              svg{
                fill: $button-color;
              }
            }
            &:hover{
              cursor: pointer;
            }
          }
          .icon-view-trade_in div svg{
            width: 16px;
            g .a{
              fill: #2194f1;
              stroke: none;
            }
          }
        }

        .panel-sale-left-top{
          width: 100%;
          height: calc(67% - 5px);
          display: flex;
          float: left;
          margin-bottom: 10px;
          .content-panel{
            height: 100%;
            width: 27%;
            padding-right: 10px;
          }
          .content-panel-sale-notes{
            width: 19%;
          }

          .sale-note-box-content{
            overflow: auto;
            max-height: calc(100% - 114px);
          }
          .panel-sale-purchaser, .panel-financing{
            border-radius: 4px;
            border: 1px solid #D7DAE2;
            width: 100%;
            .title-purchaser, .title-payment{
              border-bottom: 1px solid #D7DAE2;
              display: flex;
              align-items: center;
              padding: 0 10px;
              height: 30px;
              label{
                font-size: 13px;
                margin-bottom: 0;
                font-weight: $font-weight-regular;
                font-family: $font-family;
              }
              .icon-check{
                float: left;
                padding-right: 5px;
                div{
                  height: 100%;
                  display: flex;
                }
                .icon-check-svg{
                  width: 15px;
                  .cls-1{
                    fill: #D7DAE2;
                  }
                }
                .icon-check-svg-completed{
                  width: 15px;
                  .cls-1{
                    fill: #4ad991;
                  }
                }
              }
            }
            .title-purchaser {
              position: relative;
              .add-co-purchaser, .add-co-purchaser-readonly{
                right: 10px;
                position: absolute;
                border: none;
                background-color: #ffffff;
                .btn-add-co-purchaser{
                  color: #2194F1;
                  font-size: 13px;
                  margin-bottom: 0;
                  background-color: #ffffff;
                  border: none;
                  font-family: $font-family;
                  font-weight: $font-weight-regular;
                }
                &:hover{
                  .btn-add-co-purchaser{
                    cursor: pointer;
                  }
                }
              }
              .add-co-purchaser-readonly{
                &:hover{
                  .btn-add-co-purchaser{
                    cursor: not-allowed;
                  }
                }
              }
              .name-purchaser-selected{
                font-size: 13px;
                color: $text-color;
                font-weight: $font-weight-regular;
                margin-bottom: 0;
                padding-left: 20px;
                font-family: $font-family;
              }
              .container-icon-left{
                position: absolute;
                display: flex;
                align-items: center;
                width: 15px;
                height: 100%;
                .icon-left{
                  color: #808495;
                  font-size: 8px;
                }
                &:hover{
                  cursor: pointer;
                }
              }
            }
          }
          .panel-sale-purchaser{
            height: 100%;
            >div{
              height: 100%;
            }
            .body-purchaser{
              padding: 10px;
              width: 100%;
              height: calc(100% - 30px);
              display: flex;
              justify-content: center;
              position: relative;
              overflow: auto;
              .container-purchaser-image{
                float: left;
                padding-top: 100px;
                text-align: center;
                .icon-purchaser-image{
                  width: 92.8px;
                  padding-bottom: 20px;
                }
                label{
                  width: 100%;
                  font-size: 15px;
                  color: $text-color;
                  font-weight: $font-weight-bold;
                  text-align: center;
                  margin-bottom: 0;
                }
                .container-button-add-purchaser{
                  display: flex;
                  justify-content: center;
                  margin-top: 10px;
                  .btn-add-purchaser{
                    border-radius: 4px;
                    border: 1px solid #D7DAE2;
                    background-color: #2194F1;
                    color: #ffffff;
                    height: 32px;
                    width: 110px;
                    font-family: $font-family;
                    font-weight: $font-weight-regular;
                    font-size: 13px;
                  }
                }
              }
              .container-purchaser-selected{
                width: 100%;
                float: left;
                .purchaser-info{
                  width: 100%;
                  display: flex;
                  padding: 10px;
                  margin-bottom: 10px;
                  .purchaser-info-icon{
                    float: left;
                    height: 58px;
                    width: 58px;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    box-shadow: 0 0 10px #A4AFB7;
                    .icon-purchaser-selected{
                      height: 58px;
                      width: 58px;
                      border-radius: 50%;
                    }
                  }
                  .customer-info-text{
                    float: left;
                    margin-left: 20px;
                    .purchaser-name{
                      @include label-info;
                      font-family: $font-family;
                    }
                    .purchaser-category, .purchaser-date{
                      font-size: 9px;
                      margin-bottom: 0;
                      font-weight: $font-weight-regular;
                      width: 100%;
                      font-family: $font-family;
                    }
                    .purchaser-category{
                      color: #2194F1;
                      display: block;
                    }
                    .purchaser-email, .purchaser-phone, .purchaser-address{
                      @include label-info;
                      font-family: $font-family;
                    }
                    .bar-purchaser{
                      border: 1px solid #D7DAE2;
                      width: 45px;
                    }
                  }
                }
                .customer-credit-score{
                  height: 100px;
                  width: 100%;
                  border-radius: 4px;
                  border: 1px solid #F5F6FA;
                  padding: 8px 10px 8px 10px;
                  .container-title-credit-score{
                    display: flex;
                    position: relative;
                    .credit-score{
                      color: $text-color;
                      font-family: $font-family;
                      font-size: 16px;
                      float: left;
                      margin-bottom: 0;
                      font-weight: $font-weight-bold;
                    }
                    .edit-pencil{
                      height: 7px;
                      width: 7px;
                      position: absolute;
                      right: 0;
                      top: 0;
                      div{
                        svg{
                          fill: #8F979D;
                          position: absolute;
                          right: 0;
                          top: 0;
                        }
                      }
                      &:hover{
                        cursor: pointer;
                      }
                    }
                    .container-icon-credit-score{
                      position: absolute;
                      right: 0;
                      top: 0;
                      .icon-close-credit-score, .icon-edit-credit-score{
                        height: 25px;
                        width: 25px;
                        float: left;
                        border: 1px solid #D7DAE2;
                        border-radius: 50%;
                        &:hover{
                          cursor: pointer;
                        }
                      }
                      .icon-edit-credit-score{
                        float: right;
                        margin-left: 5px;
                      }
                      .icon-close-credit-score{
                        .cls-1{
                          fill: $button-color-red;
                        }
                      }
                    }
                  }
                  .number-credit-score{
                    color: $text-color;
                    font-family: $font-family;
                    font-size: 21px;
                    margin-bottom: 10px;
                    font-weight: $font-weight-bold;
                  }
                  .bar-credit-score{
                    width: 100%;
                    display: flex;
                    .bar-red, .bar-orange, .bar-yellow, .bar-green, .bar-light-green, .bar-general{
                      width: 20%;
                      margin-right: 1px;
                      border: 1px solid #FFFFFF;
                      display: flex;
                      float: left;
                      height: 4px;
                    }
                    .bar-red{
                      background-color: $button-color-red;
                      border-radius: 10px 0 0 10px;
                    }
                    .bar-orange{
                      background-color: #F3A200;
                    }
                    .bar-yellow{
                      background-color: #FFDE00;
                    }
                    .bar-green{
                      background-color: #71D6A3;
                    }
                    .bar-light-green{
                      background-color: #0DB762;
                      border-radius: 0 10px 10px 0;
                      margin-right: 0;
                    }
                  }
                  .credit-rating{
                    font-family: $font-family;
                    color: #43425D;
                    font-size: 9px;
                    font-weight: $font-weight-light;
                  }
                }
              }
              .icon-trash-customer{
                position: absolute;
                right: 10px;
                bottom: 10px;
                height: 15px;
                width: 15px;
                &:hover{
                  cursor: pointer;
                }
              }
            }
            .body-content-purchaser{
              padding: 10px 15px 10px 15px;
              width: 100%;
              height: calc(100% - 30px);
              float: left;
              overflow: auto;
              .container-purchase-principal, .container-co-purchaser{
                width: 100%;
                float: left;
                .title-purchase-principal{
                  label{
                    color: $button-color-green;
                    font-size: 13px;
                    margin-bottom: 0;
                    font-weight: $font-weight-regular;
                  }
                }
                .container-image-purchaser{
                  width: 100%;
                  display: flex;
                  padding: 10px 0 0 0;
                  margin-bottom: 10px;
                  .container-icon{
                    height: 58px;
                    @include item-center;
                    width: 10px;
                    .icon-arrow-content{
                      float: left;
                      margin-right: 10px;
                      padding: 1px 0 1px 6px;
                      .icon-arrow{
                        float: left;
                        .icon-arrow-svg{
                          height: $icon-arrow-height-svg;
                          fill: #A4AFB7;
                          width: 8px;
                        }
                      }
                    }
                    &:hover{
                      cursor: pointer;
                    }
                  }
                  .container-icon-purchaser{
                    height: 58px;
                    width: 58px;
                    border-radius: 50%;
                    box-shadow: 0 0 10px #A4AFB7;
                    margin-left: 5px;
                    @include item-center;
                    .icon-purchaser{
                      height: 58px;
                      width: 58px;
                      border-radius: 50%;
                    }
                  }
                  .container-info-purchaser{
                    height: 100%;
                    margin-left: 15px;
                    width: 80%;
                    .content-name-purchaser{
                      width: 100%;
                      display: flex;
                      align-items: center;
                      position: relative;
                      .name-purchaser{
                        font-size: 13px;
                        color: $text-color;
                        font-weight: $font-weight-regular;
                        margin-bottom: 0;
                        width: 90%;
                        font-family: $font-family;
                      }
                      .icon-right{
                        color: #808495;
                        font-size: 8px;
                        right: 10px;
                        position: absolute;
                      }
                    }
                    .email-purchaser, .phone-purchaser{
                      font-size: 13px;
                      color: $text-color;
                      margin-bottom: 0;
                      width: 100%;
                      font-weight: $font-weight-regular;
                      font-family: $font-family;
                    }
                    .phone-purchaser{
                      margin-bottom: 5px;
                    }
                    .content-credit-score{
                      border: 1px solid #8F979D;
                      border-radius: 3px;
                      width: 120px;
                      @include item-center;
                      padding: 1px 0;
                      .title-credit-score{
                        font-size: 10px;
                        color: $text-color;
                        font-weight: $font-weight-semi-bold;
                        margin-bottom: 0;
                        font-family: $font-family;
                      }
                    }
                  }
                  &:hover{
                    cursor: pointer;
                  }
                }
                .container-image-purchaser-readonly{
                  &:hover{
                    cursor: default;
                    .container-info-purchaser, .container-icon-purchaser, .icon-right,
                    .phone-purchaser, .email-purchaser, .name-purchaser, .container-icon{
                      cursor: default;
                    }
                    .container-info-purchaser .content-credit-score{
                      cursor: default;
                    }
                  }
                }
              }
              .container-co-purchaser{
                padding-top: 5px;
                .title-co-purchaser{
                  label{
                    color: #2194F1;
                    font-size: 13px;
                    font-weight: $font-weight-regular;
                  }
                }
              }
            }
          }
          .panel-sale-payment{
            height: 100%;
            .body-payment{
              width: 100%;
              padding: 10px;
              display: flex;
              .payment-finance, .payment-cash{
                width: 50%;
                display: flex;
                align-items: center;
                border: 1px solid #F5F6FA;
                border-radius: 5px;
                padding: 2px 5px;
                .icon-getting-finance, .icon-cash{
                  float: left;
                  padding: 3px 5px 0 0;
                  .icon-getting-finance-svg, .icon-cash-svg{
                    width: 26px;
                    height: 28px;
                    .a{
                      fill: #ffffff;
                    }
                  }
                  .icon-cash-svg{
                    .a{
                      stroke: none;
                    }
                    .b{
                      fill: #c4c8ce;
                    }
                    .d{
                      fill: #2fa52f;
                    }
                  }
                }
                label{
                  color: #7F7F7F;
                  font-size: 11px;
                }
              }
            }
          }
          .panel-sale-car-tools{
            width: 100%;
            height: 100%;
            .panel-sale-car, .panel-sale-trade-ins, .panel-sale-balance{
              height: calc(50% - 5px);
              border-radius: 4px;
              border: 1px solid #D7DAE2;
              width: 100%;
              float: left;
              .title-car, .title-trade-ins, .title-balance{
                border-bottom: 1px solid #D7DAE2;
                display: flex;
                align-items: center;
                padding: 0 10px;
                height: 30px;
                label{
                  font-size: 13px;
                  margin-bottom: 0;
                  font-weight: $font-weight-regular;
                  font-family: $font-family;
                }
                .icon-check, .icon-trade-ins{
                  float: left;
                  padding-right: 5px;;
                  div{
                    height: 100%;
                    display: flex;
                  }
                  .icon-check-svg, .icon-trade-ins-svg{
                    width: 15px;
                    .cls-1{
                      fill: #D7DAE2;
                    }
                  }
                  .icon-trade-ins-svg{
                    height: 24px;
                    fill: #8F979D
                  }
                }
              }
            }
            .panel-sale-car{
              margin-bottom: 10px;
              .body-car{
                height: calc(100% - 30px);
                width: 100%;
                padding: 10px;
                float: left;
                position: relative;
                .body-car-section{
                  width: 100%;
                  position: relative;
                  height: 100%;
                }
                .container-option-car{
                  display: flex;
                  width: 100%;
                  height: 30px;
                  margin-bottom: 5px;
                  .car-inventory, .car-not-inventory{
                    width: 50%;
                    display: flex;
                    align-items: center;
                    border: 1px solid #F5F6FA;
                    border-radius: 5px;
                    padding: 5px 0;
                    justify-content: center;
                    label{
                      color: #7F7F7F;
                      margin: 0;
                      font-size: 11px;
                      font-weight: 600;
                      &:hover{
                        cursor: pointer;
                      }
                    }
                    &:hover{
                      cursor: pointer;
                    }
                  }
                  .car-inventory{
                    margin-right: 5px;
                  }
                  .after-selected-car{
                    border: 1px solid #2194F1;
                    display: flex;
                  }
                }
                .container-car-image{
                  width: 100%;
                  border-radius: 5px;
                  //--background-color: #F5F6FA;
                  background-color: transparent;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: calc(100% - 35px);
                  .container-icon-image-not-selected {
                    float: left;
                    .icon-car-image{
                      width: 100%;
                      .icon-car-image-svg{
                        width: 113px;
                        .a{
                          fill: #2194F1;
                          stroke: none  ;
                        }
                      }
                    }
                    .car-not-selected{
                      font-weight: $font-weight-bold;
                      font-size: 15px;
                      font-family: $font-family;
                      color: #787878;
                    }
                  }
                  .container-icon-image{
                    float: left;
                    height: 100%;
                    display: flex;
                    width: 100%;
                    .selected-car-image{
                      width: 50%;
                      padding: 0;
                      margin: 0;
                    }
                    .selected-car-info{
                      float: right;
                      width: 50%;
                      text-align: left;
                      padding: 15px 0 10px 5px;
                      position: relative;
                      p {
                        margin-bottom: 0;
                        margin-left: 10px;
                      }
                      .make-model-year-car {
                        color: $text-color;
                        font-family: $font-family;
                        font-size: 12px;
                        font-weight: $font-weight-regular;
                      }
                      .make-model-year-car-pointer {
                        color: $text-color;
                        font-family: $font-family;
                        font-size: 12px;
                        font-weight: $font-weight-regular;
                        cursor: pointer;
                      }
                      .active-car, .not-available {
                        color: #4AD991;
                        font-family: $font-family;
                        font-size: 11px;
                        font-weight: $font-weight-regular;
                      }
                      .price-mileage-car {
                        color: #43425D;
                        font-family: $font-family;
                        font-size: 7px;
                        font-weight: $font-weight-regular;
                      }
                      .container-title-button{
                        width: 100%;
                        position: absolute;
                        bottom: 7px;
                        padding-right: 7px;
                        .car-temporary{
                          font-family: $font-family;
                          color: #EAA734;
                          font-size: 11px;
                          //position: absolute;
                          //bottom: 7px;
                          float: left;
                          font-weight: $font-weight-regular;
                        }
                        .container-button-edit,.container-button-add{
                          width: 12px;
                          float: right;
                          &:hover{
                            cursor: pointer;
                            .title-suggestion{
                              //display: block;
                              display: flex;
                              font-size: 11px;
                              color: $text-color;
                              text-align: center;
                              justify-content: center;
                              border: 1px solid $text-color;
                              border-radius: 4px;
                              top: 20px;
                              position: absolute;
                              /* right: 28px; */
                              background-color: white;
                              width: auto;
                              padding: 3px;
                              box-sizing: border-box;
                              span{
                                white-space: nowrap;
                              }
                            }
                          }
                          .title-suggestion{
                            display: none;
                          }
                        }
                        .container-button-edit{
                          margin-left: 7px;
                        }
                        .container-button-add{
                          &:hover{
                            .title-suggestion{
                              right: 30px;
                            }
                          }
                        }
                        .icon-edit-temporary-car,.icon-add-car-to-inventory{
                          width: 12px;
                          float: right;
                          svg{
                            fill: $button-color-blue;
                          }
                        }
                      }
                      .not-available{
                        color: #787878;
                      }
                    }
                    label{
                      width: 100%;
                      color: $text-color;
                      font-size: 15px;
                      margin: 0;
                    }
                  }
                }
                .after-selected-car{
                  display: block;
                  background-color: white;
                }
              }
              .icon-check-svg-completed{
                width: 15px;
                .cls-1{
                  fill: #4ad991;
                }
              }
            }
            .panel-sale-trade-ins{
              .title-trade-ins{
                position: relative;
              }
              .not-trade-ins{
                @include item-center;
                width: 100%;
                height: calc(100% - 30px);
                .container-trade-in{
                  width: 100%;
                  .content-image-trade-ins{
                    width: 100%;
                    text-align: center;
                    .trade-in-image{
                      width: 90px;
                    }
                  }
                  .content-label{
                    padding-top: 10px;
                    text-align: center;
                    .not-trade-in{
                      color: $text-color;
                      font-size: 15px;
                      font-weight: $font-weight-bold;
                    }
                  }
                }
              }
              .trade-ins-body{
                .tool-body-option, .tool-body-option-color{
                  &:hover{
                    .trade-actions{
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100%;
                      .img-edit-pencil{
                        cursor: pointer;
                        width: 13px;
                        margin-right: 5px;
                      }
                      .img-delete{
                        cursor: pointer;
                        width: 13px;
                      }
                      .img-check-added, .img-check{
                        width: 10px;
                        height: 10px;
                        fill: #4AD991;
                        &:hover{
                          cursor: pointer;
                        }
                      }
                      .img-check{
                        fill: #D7DAE2;
                      }
                    }
                  }
                  display: block;
                  width: 100%;
                  padding-left: 10px;
                  height: 20px;
                  align-items: center;
                  .car-title{
                    margin-bottom: 0;
                    float: left;
                  }
                  .trade-actions{
                    font-size: 10px;
                  }
                  .trade-in,.trade-actions{
                    display: none;
                    float: right;
                    padding-right: 12px;
                  }
                  .car-title, .trade-in{
                    height: 100%;
                    color:$text-color;
                    font-size: 10px;
                    font-family: $font-family;
                    font-weight: $font-weight-regular;
                    @include item-center;
                  }
                }
                .tool-body-option-color{
                  background-color: #F5F6FA;
                }
                .trade-in-total{
                  .total, .label-total{
                    padding-right: 12px;
                    color: $text-color;
                    font-size: 10px;
                    float: right;
                    font-family: $font-family;
                    font-weight: $font-weight-regular;
                  }
                  .total{
                    color: $button-color-green;
                  }
                }
              }
              .loader-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background: white;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
              }
            }
            .panel-sale-balance{
              margin-bottom: 10px;
              .title-balance{
                position: relative;
              }
              .not-balance{
                @include item-center;
                width: 100%;
                height: calc(100% - 30px);
                .container-balance{
                  width: 100%;
                  .content-image-balance{
                    width: 100%;
                    text-align: center;
                    .balance-image{
                      width: 90px;
                    }
                  }
                  .content-label{
                    padding-top: 10px;
                    text-align: center;
                    .not-balance-text{
                      color: $text-color;
                      font-size: 15px;
                      font-family: $font-family;
                      font-weight: $font-weight-bold;
                    }
                  }
                }
              }
              .balance-body{
                position: relative;
                height: calc(100% - 30px);
                .balance-body-accessories{
                  .tool-body-option, .tool-body-option-color{
                    display: block;
                    width: 100%;
                    padding-left: 10px;
                    height: 20px;
                    align-items: center;
                    .accessory-title{
                      margin-bottom: 0;
                      float: left;
                    }
                    .total-accessory{
                      display: none;
                      float: right;
                      padding-right: 12px;
                    }
                    .accessory-title, .total-accessory{
                      height: 100%;
                      color:$text-color;
                      font-size: 10px;
                      font-family: $font-family;
                      font-weight: $font-weight-regular;
                      @include item-center;
                    }
                  }
                  .tool-body-option-color{
                    background-color: #F5F6FA;
                  }
                }
                .balance-total{
                  position: absolute;
                  bottom: 8px;
                  right: 0;
                  .total, .label-total{
                    padding-right: 12px;
                    color: $text-color;
                    font-size: 10px;
                    float: right;
                    font-family: $font-family;
                    font-weight: $font-weight-regular;
                  }
                  .total{
                    color: $button-color-green;
                  }
                }
              }
            }
          }
          .panel-financing{
            height: calc(50% - 5px);
            position: relative;
            float: left;
            .title-suggestion{
              box-shadow: 0 0 10px #0000002B;
              position: absolute;
              right: 8px;
              top: 23px;
              border: 1px solid #d7dae2;
              border-radius: 5px;
              opacity: 1;
              background-color: white;
              z-index: 2200;
              height: 25px;
              align-items: center;
              padding: 5px 10px;
              display: none;
              width: max-content;
              .icon-error{
                padding-right: 5px;
                >div{
                  @include item-center;
                  svg{
                    height: 14px;
                    width: 14px;
                    fill: #ffca83;
                  }
                }
              }
              span{
                color: #8F979D;
                font-weight: $font-weight-semi-bold;
                font-size: 12px;
                margin: 0;
              }
            }
            .title-payment{
              position: relative;
              .right-element{
                .container-icon{
                  .icon-add-svg{
                    cursor: pointer;
                    fill: $button-color;
                  }
                }
                .container-icon-disabled{
                  .title-suggestion{
                    display: none;
                  }
                  &:hover{
                    .title-suggestion{
                      display: flex;
                    }
                  }
                  .icon-add-trade_in{
                    &:hover{
                      cursor: auto;
                    }
                  }
                  .icon-add-svg-disabled{
                    fill: #eeeeee;
                    cursor: auto;
                  }
                }
              }
            }
            .popover-general-message{
              font-size: 10px;
              .popover-general-message-sub{
                padding: 0;
                .change-submenu-item, .item-selected{
                  padding: 2px 4px;
                }
              }
            }
            .title-payment{
              .icon-check{
                svg{
                  height: 100%;
                  fill: #8F979B;
                }
              }
            }
            .body-payment{
              width: 100%;
              padding-bottom: 10px;
              display: flex;
              height: calc(100% - 30px);
              .container-not-purchaser{
                width: 100%;
                @include item-center;
                .container-icon-text{
                  text-align: center;
                  padding: 0 10%;
                  .icon-getting-finance{
                    svg{
                      width: 80px;
                      height: auto;
                    }
                  }
                  span{
                    color: $text-color;
                    font-size: 15px;
                    font-weight: $font-weight-bold;
                  }
                }
              }
              .container-financing{
                width: 100%;
                .container-finances{
                  height: 100%;
                  .header-finances, .row-finance, .row-finance-color{
                    display: flex;
                    align-items: center;
                    font-size: 10px;
                    height: 20px;
                    color: $text-color;
                    //border-right: 1px solid #D7DAE2;
                    //border-left: 1px solid #D7DAE2;
                    span{
                      width: 40%;
                      padding-left: 12px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      vertical-align: middle;
                      font-size: 10px;
                      display: inline;
                      font-weight: $font-weight-regular;
                      font-family: $font-family;
                    }
                    .title-1{
                      width: 40%;
                    }
                    .title-2{
                      width: 30%;
                    }
                    .title-3{
                      padding-right: 5px;
                      width: 30%;
                      .select{
                        &:hover{
                          cursor: pointer;
                        }
                        width: 100%;
                        border-radius: 4px;
                        border: 1px solid #D7DAE2;
                        display: flex;
                        justify-content: center;
                        height: 18px;
                        align-items: center;
                        position: relative;
                        background: #FFF;
                        .container-label{
                          width: 75%;
                          float: left;
                          padding-left: 5px;
                          position: absolute;
                          left: 0;
                          display: flex;
                          align-items: center;
                          .label-select{
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            vertical-align: middle;
                            font-size: 10px;
                            display: inline-block;
                            max-width: 100%;
                            font-weight: 100;
                            margin-bottom: 0;
                            width: 100%;
                            padding-left: 0;
                          }
                        }
                        .icon-arrow-content{
                          position: absolute;
                          right: 0;
                          top: 0;
                          .icon-arrow-expand{
                            height: 5px;
                          }
                        }
                      }
                    }
                    .title-1-content{
                      width: 40%;
                      display: inherit;
                      .title-1{
                        width: auto;
                      }
                      .title-number-cosigner{
                        color: #2194F1;
                        padding-left: 3px;
                        width: auto;
                        text-overflow: inherit;
                        white-space: inherit;
                        overflow: inherit;
                        vertical-align: initial;
                      }
                    }
                  }
                  .header-finances{
                    background: #D7DAE2;
                    color: #FFF;
                  }
                  .body-purchaser-finances{
                    max-height: calc(100% - 20px) !important;
                    overflow: auto;
                  }
                  .row-finance{
                    background: #FFF;
                    &:hover{
                      cursor: pointer;
                    }
                  }
                  .row-finance-color{
                    background: #F5F6FA;
                    &:hover{
                      cursor: pointer;
                    }
                  }
                  .applicant-name{
                    padding-left: 0 !important;
                    //width: calc(40% - 10px);
                  }
                }
                .content-warning{
                  padding: 10px;
                }
              }
            }
            .loader-container{
              height: calc(100% - 30px);
            }
          }
        }
        .panel-sale-left-bottom{
          width: 100%;
          height: calc(33% - 5px);
          display: flex;
          float: left;
          .content-panel{
            width: 27%;
            padding-right: 10px;
          }
          .content-panel-check-list{
            width: 46%;
          }
          .panel-sale-files, .panel-sale-check-list, .panel-sale-tools{
            border-radius: 4px;
            border: 1px solid #D7DAE2;
            width: 100%;
            height: 100%;
            .title-files{
              // Commented next line because we don't want to shwo Reload Files Page
              // cursor: pointer;
              .right-element {
                .container-icon {
                  .icon-left-arrow-svg {
                    cursor: pointer;
                    fill: #808495;
                    width: 9px;
                    height: 11px;
                    opacity: 1;
                  }
                }
              }
            }
            .title-files, .title-check-list, .title-tools{
              border-bottom: 1px solid #D7DAE2;
              display: flex;
              align-items: center;
              padding: 0 10px;
              height: 30px;
              label{
                font-size: 13px;
                margin-bottom: 0;
                font-weight: $font-weight-regular;
                font-family: $font-family;
              }
              .icon-files, .icon-check-list, .icon-tools{
                float: left;
                padding-right: 5px;
                div{
                  height: 100%;
                  display: flex;
                }
                .icon-files-svg, .icon-check-list-svg, .icon-tools-svg{
                  width: 15px;
                  height: 24px;
                  fill: #8F979D;
                  .cls-1{
                    fill: #D7DAE2;
                  }
                }
              }
            }

          }
          .panel-sale-tools{
            .tools-body{
              height: calc(100% - 30px);
              overflow: auto;
            }
            .tool-body-option, .tool-body-option-color{
              width: 100%;
              padding-left: 10px;
              height: 20px;
              display: flex;
              align-items: center;
              label{
                color:$text-color;
                margin-bottom: 0;
                font-size: 10px;
                font-family: $font-family;
                font-weight: $font-weight-regular;
              }
              .selectable-label{
                cursor: pointer;
              }
            }
            .tool-body-option-color{
              background-color: #F5F6FA;
            }
          }
          .panel-sale-check-list{
            width: 100%;
            margin-right: 0;
            height: 100%;
            position: relative;
            .check-list-body{
              max-height: calc(100% - 30px) !important;
              overflow: auto;
              padding-bottom: 0;
              width: 100%;
              float: left;
              padding-top: 5px;
              position: relative;
              .content-check{
                display: flex;
                align-items: center;
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                height: auto;
                margin-top: 5px;
                .checkbox{
                  width: 20px !important;
                  float: left;
                  margin-top: 0;
                  margin-bottom: 0;
                }
                .container-label{
                  display: flex;
                  align-items: center;
                  .check-description{
                    font-size: 13px;
                    color: $text-color;
                    margin-bottom: 0;
                    float: left;
                  }
                  .check-required{
                    float: left;
                    color: $button-color-red;
                    margin: 0;
                  }
                }
              }
              .container-required-items{
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-end;
                padding-bottom: 5px;
                .required-items{
                  color: $button-color-red;
                  font-size: 11px;
                  font-weight: $font-weight-light;
                  padding-right: 20px;
                  margin-bottom: 0;
                }
              }
            }
            .loader-container{
              height: calc(100% - 30px);
            }
            .check-list-body-empty{
              @include item-center;
              height: 100%;
              padding-top: 0;
            }
          }
          .panel-sale-files{
            position: relative;
            .loader-container{
              height: calc(100% - 30px);
            }
            .container-files{
              height: calc(100% - 30px);
              overflow: auto;
            }
            .files-body{
              position: relative;
              min-height: calc(100% - 20px);
              .container-label-files{
                width: calc(100% - 40px);
                display: flex;
                align-items: center;
                font-size: 10px;
                color: #787878;
              }
              .container-icon-sale-not-hovered{
                width: 30px;
                height: 100%;
                display: flex;
                justify-content: center;
                .icon-check{
                  width: 10px;
                  height: 100%;
                  fill: #4AD991;
                }
              }
              .container-icon-sale-hovered{
                width: 30px;
                height: 100%;
                display: flex;
                justify-content: center;
                visibility: hidden;
                .icon-download-file, .icon-delete-file{
                  width: 20px;
                  height: 100%;
                  margin-left: 5px;
                  .icon-download, .icon-delete{
                    width: 10px;
                    height: auto;
                    margin-right: 2px;
                    fill: #8F979D;
                  }
                  .icon-delete:hover{
                    fill: red;
                  }
                  &:hover{
                    cursor: pointer;
                  }
                }
              }
              .container-drop-zone{
                width: 20px;
                height: 100%;
                cursor: pointer;
                display: flex;
                justify-content: center;
                >div:focus{
                  outline: none;
                }
                input{
                  width: 20px;
                }
                .cloud-image{
                  height: 10px;
                  width: 10px;
                }
              }

              .file-body-option, .file-body-option-color{
                width: 100%;
                padding-left: 10px;
                height: 20px;
                display: flex;
                align-items: center;
                label{
                  color:$text-color;
                  margin-bottom: 0;
                  font-size: 10px;
                  margin-right: 2px;
                  font-weight: $font-weight-regular;
                }
                .check-no-optional{
                  float: left;
                  color: $button-color-red;
                  margin-bottom: 0;
                  font-weight: 600;
                }
                .icon-upload{
                  float: right;
                  padding-left: 2px;
                  .icon-upload-svg{
                    width: 10px;
                    .a{
                      stroke: #8F979D;
                    }
                  }
                }
              }
              .file-body-option-color{
                background-color: #F5F6FA;
              }
            }
            .container-no-optional-items{
              display: flex;
              justify-content: flex-end;
              height: 20px;
              .no-optional-items{
                color: $button-color-red;
                font-size: 11px;
                font-weight: $font-weight-light;
                padding-right: 20px;
              }
            }
            .files-body-empty{
              @include item-center;
              min-height: 100%;
            }
          }
        }
      }
      .panel-sale-right{
        width: 100%;
        min-height: 100%;
        height: 100%;
        float: right;
        padding: 0 0 0 10px;
        position: relative;
        .panel-sale-notes{
          float: left;
          width: 100%;
          padding-top: 10px;
          .title-note{
            color: $text-color;
            font-size: 13px;
            margin-bottom: 10px;
            width: 100%;
            font-weight: $font-weight-regular;
            font-family: $font-family;
          }
          .content-note{
            border-radius: 4px;
            padding: 5px;
            height: 75px;
            width: 100%;
            border: 1px solid #D7DAE2;
            background-color: #EDFFEC;
            position: relative;
            .title-new-note{
              color: $text-color;
              font-size: 12px;
              width: 100%;
              margin-bottom: 0;
              border: 0;
              background-color: transparent;
              border-bottom: 1px solid #D7DAE2;
              outline: none;
              font-family: $font-family;
              font-weight: $font-weight-regular;
            }
            .description-new-note{
              color: $text-color;
              width: 100%;
              font-size: 9px;
              border: none;
              background-color: transparent;
              outline: none;
              resize: none;
              margin-left: 0;
              font-family: $font-family;
              font-weight: $font-weight-regular;
            }
          }
        }
        .sale-note-box{
          background-color: #ECFBFF;
          border-radius: 4px;
          padding: 10px;
          margin-bottom: 10px;
          position: relative;
          .title, .title-edit{
            float: left;
            width: 100%;
            margin: 0;
            color: $text-color;
            font-size: 13px;
            font-weight: $font-weight-regular;
          }
          .date{
            color: $text-color;
            font-size: 8px;
            display: block;
            font-weight: $font-weight-regular;
          }
          .content{
            font-size: 10px;
            display: inline-block;
            color: $text-color;
            padding-top: 3px;
            font-weight: $font-weight-light;
          }
          .icon-edit-content{
            position: absolute;
            top: 0;
            right: 25px;
          }
          .icon-delete-content{
            position: absolute;
            top: 0;
            right: 10px;
            color: #8F979D;
          }
          .icon-edit, .icon-delete{
            fill: #8F979D;
            width: 10px;
            height: 10px;
            &:hover{
              cursor: pointer;
            }
          }
          .icon-delete{
            width: 8px;
            height: 8px;
          }

          .title-edit{
            border: 0;
            background-color: transparent;
            border-bottom: 1px solid #D7DAE2;
            outline: none;
          }
          .description-edit-note{
            color: $text-color;
            width: 100%;
            font-size: 9px;
            border: none;
            background-color: transparent;
            outline: none;
            resize: none;
            margin-left: 0;
          }
        }
        .sale-note-box-content{
          max-height: 420px;
          overflow: auto;
        }
        .content-note-icons{
          position: absolute;
          bottom: 5px;
          right: 20px;
          .icon-note{
            border-radius: 50%;
            border: 0.7px solid #D7DAE2;
            width: 10px;
            height: 10px;
            float: right;
            margin-right: 5px;
            &:hover{
              cursor: pointer;
            }
          }
        }
        .title-note{
          color: $text-color;
          font-size: 13px;
          margin-bottom: 10px;
          width: 100%;
          font-weight: $font-weight-regular;
          font-family: $font-family;
        }
      }
    }

    .panel-sale {
      @include panel-sale;
    }
    .panel-sale-finance{
      @include panel-sale;
      width: 100%;
      .message-bar{
        background-color: #4AD991;
      }
      .message-bar.message-new-finance{
        background-color: #f7a641;
      }
      .div-container-sub{
        width: 100%;
      }
      .container-header-view-app{
        height: 50px;
        .header-view-app-left{
          .container-change-status{
            .large-select{
              &:hover{
                text-decoration: none;
              }
            }
          }
        }
        .header-view-app-right{
          .container-change-salesman{
            .large-select{
              &:hover{
                text-decoration: none;
              }
            }
          }
        }
        .button-cancel{
          margin-left: 10px;
          border: none;
          background: transparent;
          color: $button-color-red;
        }
      }
      .popover-box-message{
        .popover-general-message{
          .popover-general-message-sub{
            padding: 0;
          }
        }
      }
    }
  }

  .popover-general-message-sub-cosigner{
    padding: 10px 15px;
  }

  .icon-save-content{
    position: absolute;
    right: 7px;
    bottom: 2px;
    background-color: transparent;
    border: none;
    padding: 0;
    &:hover{
      //--cursor: pointer;
    }
  }
  .icon-save{
    width: 10px;
    height: 10px;
    fill: #8F979D;
  }
  .loader-container{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.modal-new-sale{
  @include item-center;
  display: flex !important;
  background: #A4AFB74A 0% 0% no-repeat padding-box;
  .modal-dialog{
    width: 50%;
  }

  .modal-content{
    width: 100%;
    float: left;
    border-radius: 5px;
    box-shadow: 0 0 30px #00000029;
    border: none;
  }

  .modal-body{
    min-height: 300px;
    float: left;
    width: 100%;
    padding: 0;
    @include item-center;
  }

  .panel-container{
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 5px;
    padding: 40px 50px;

    .icon-close-content{
      height: 32px;
      position: absolute;
      top: -15px;
      right: -15px;
      .cls-1 {
        fill: $button-color-red;
      }
    }

    .icon-close{
      width: 32px;
      height: 32px;
      fill: #ff565e;
    }

    .icon-close:hover{
      cursor: pointer;
    }

    .icon-cash{
      position: absolute;
      bottom: -5px;
      right: 0;
    }
    .icon-cash-svg{
      height: 144px;
      width: 161px;
    }

    .panel-container-body{
      float: left;
      width: 100%;
      height: 100%;
      .container-title{
        @include item-center;
        width: 100%;
        margin-bottom: 30px;
        .title{
          font-family: $font-family;
          color: $text-color;
          font-size: 21px;
          font-weight: 600;
        }
      }
      .container-step{
        display: flex;
        width: 100%;
        margin-bottom: 60px;
        justify-content: center;
        .panel-left, .panel-right, .panel-center{
          width: 33.3%;
          float: left;
          padding: 0 10px;
          .container-title-step{
            display: flex;
            margin-bottom: 10px;
            .title-step, .number{
              color: $text-color;
              font-size: 13px;
            }
            .number{
              color: #2194F1;
              margin-right: 5px;
              font-family: $font-family;
              font-weight: 600;
            }
            .title-step{
              font-family: $font-family;
              font-weight: $font-weight-regular;
            }
          }
        }
        .panel-left{
          .container-user{
            padding-left: 15px;
            width: 100%;
            .user-select-assigned{
              @include item-center;
              border: 1px solid #ccc;
              border-radius: 4px;
              height: 32px;
              width: 100%;
              padding: 4px 1px 4px 7px;
              label{
                color: #4D4F5C;
                font-size: 12px;
                font-family: $font-family;
                font-weight: $font-weight-regular;
                width: calc(100% - 52px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 0;
              }
              .user-img{
                width: 21px;
                height: 21px;
                border-radius: 50%;
                border: 1px solid #FFFFFF;
                box-shadow: 1px 1px 4px 2px rgba(164,175,183,1);
                margin-right: 8px;
              }
              .icon-arrow-content{
                float: right;
                padding: 1px 5px;
                .icon-arrow-expand{
                  height: $icon-arrow-height-svg;
                  fill: #A4AFB7;
                }
              }
              &:hover{
                cursor: pointer;
              }
              label:hover{
                cursor: pointer;
              }
              label{
                color: #4D4F5C
              }
            }
          }
        }
      }
      .container-button{
        @include item-center;
        width: 100%;
        .button-save{
          @include item-center;
          background-color: #2194F1;
          border: 1px solid #D7DAE2;
          border-radius: 4px;
          font-size: 13px;
          height: 32px;
          width: 120px;
          color: #ffffff;
          font-family: $font-family;
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}

.label-error{
  color: $button-color-red;
  padding-left: 2px;
}

@mixin placeholderColor($color, $opacity: 1){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color !important;
    opacity: $opacity;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color !important;
    opacity: $opacity;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color !important;
    opacity: $opacity;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color !important;
    opacity: $opacity;
  }
}

input{
  @include placeholderColor( $placeholder-color)
}
.btn-green, .btn-blue{
  height: 32px;
  border-radius: 5px;
  color: #FFF;
  width: 114px;
  border: 1px solid $button-color-green;
  background-color: $button-color-green;
  font-size: 13px;
  font-family: $font-family;
  font-weight: $font-weight-regular;
}

.btn-blue{
  width: 124px;
  border: 1px solid $button-color-blue;
  background-color: $button-color-blue;
}

.row-flex-date{
  flex: 1 1 0;
}

.has-error{
  border-color: $button-color-red !important;
}

.error-class{
  font-size: 10px;
  color: $button-color-red;
  display: flex;
  width: 100%;
  padding-top: 5px;
}

.flex-row-btn{
  display: flex;
  flex-direction: row-reverse;
  .content-button{
    padding: 10px 10px 10px 0;
  }
}

.flex-row{
  display: flex;
  .dollar-prefix{
    position: absolute;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    color: #2194Fe;
    font-weight: $font-weight-regular;
    font-size: 13px;
  }
  .flex-colR1{
    flex: 1 1 0;
  }
  .item, .top-space{
    margin-top: 10px;
    .container-title{
      span{
        font-size: 13px;
        color: $text-color;
        font-weight: $font-weight-regular;
      }
      .label-error{
        color: $button-color-red;
        padding-left: 2px;
      }
    }
    .input-container{
      .form-control{
        margin-top: 8px;
        height: 32px;
        box-shadow: unset;
        border-color: #D7DAE2;
        font-size: 13px !important;
        font-weight: $font-weight-regular;
      }
    }
    .form-control{
      box-shadow: unset;
      height: 32px;
      font-size: 13px;
      border: 1px solid #D7DAE2;
      div{
        font-size: 13px !important;
        font-weight: $font-weight-regular;
        input {
          border: none;
          margin-top: 3px;
          margin-left: 10px;
        }
      }
      .MuiInput-underline::before{
        border-bottom: none;
      }
      .MuiInput-underline::after{
        border-bottom: none;
      }

    }
    .display-date{
      p{
        margin: 0 0 11px;
        font-size: 13px;
        color: $text-color;
      }
    }
  }
  .top-space{
    .title-select{
      font-size: 13px;
      margin-bottom: 11px;
      font-weight: $font-weight-regular;
      font-family: $font-family;
    }
    .title{
      font-size: 13px;
      color: #787878;
      font-weight: $font-weight-regular;
      font-family: $font-family;
    }
  }
  .input-trade-in{
    margin-top: 8px;
    input{
      height: 32px;
    }
    .container-input-trade-in{
      position: relative;
    }
  }
  .title-select{
    font-size: 14px;
    color: $text-color;
    float: left;
    margin-bottom: 8px;
  }
  .select{
    width: 100%;
    float: left;
    border-radius: 4px;
    border: 1px solid #D7DAE2;
    display: flex;
    justify-content: center;
    height: 32px;
    align-items: center;
    position: relative;
    &:hover{
      cursor: pointer;
    }
    .container-label{
      width: 75%;
      float: left;
      padding-left: 12px;
      position: absolute;
      left: 0;
      .label-select{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        font-weight: $font-weight-regular;
        margin-bottom: 0;
        font-size: 13px;
      }
    }
    .icon-arrow-content{
      position: absolute;
      right: 2px;
      top: 4px;
    }
  }
  .link{
    margin: 10px 0 5px 0;
    font-weight: $font-weight-regular;
    color: $light-blue-color-1;
    &:hover{
      cursor: pointer;
    }
  }
}

@mixin container-title(){
  span{
    font-size: 21px;
    color: $text-color;
  }
}

.container-title {
  @include container-title;
}

.container-top, .container-bottom{
  padding-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D7DAE2;
  .title{
    font-size: 15px;
    color: $text-color;
    font-family: $font-family;
    font-weight: $font-weight-bold;
  }
}

.not-selected{
  color: $placeholder-color;
}

@mixin general-modal($width: 80%, $padding-left: 0, $border-radius: 15px, $box-shadow: 0 2px 10px #00000027, $padding-body: 15px){
  display: flex !important;
  align-items: center;
  justify-content: center;
  .close-modal-icon{
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;
    &:hover{
      cursor: pointer;
    }
  }
  .modal-dialog{
    width: $width;
    padding-left: $padding-left;
    height: auto;
    .modal-content{
      box-shadow: 0 2px 10px #00000027;
      border: none;
      border-radius: $border-radius;
      .modal-body{
        padding: $padding-body;
        .loader-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          background: white;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1500;
        }
      }
    }
  }
}

.modal-trade-in{
  @include general-modal(80%,$padding-body: 15px 20px 15px 30px);
  .popover-search{
    i{
      color: #A4AFB7 !important;
    }
    .form-control{
      height: 32px;
    }
  }
  input{
    box-shadow: unset;
    border: 1px solid #D7DAE2;
    &:focus{
      box-shadow: unset;
      border-color: #D7DAE2;
    }
  }
}

.modal-add-finance{
  @include general-modal(960px, $padding-body: 10px);
  .container-body-purchaser{
    .container-title{
      padding: 0 10px 10px;
      .title{
        font-size: 13px;
        font-weight: $font-weight-regular;
      }

      @mixin application-type(){
        font-size: 13px;
        margin-bottom: 0;
        background-color: #ffffff;
        border: none;
        font-family: $font-family;
        font-weight: $font-weight-regular;
        float: right;
      }
      .application-type{
        @include application-type;
      }
      .application-type-selected{
        @include application-type;
        color: #2194F1;
      }
      .application-type-unselected{
        @include application-type;
        color: #787878;
      }
    }
    @mixin line-separator-container(){
      width: 100%;
      position: absolute;
      right: 0;
      height: 1px;
      border-bottom: 1px solid  #EFEFEF;
    }

    .line-separator-container {
      @include line-separator-container;
    }

    .container-purchasers{
      padding-top: 10px;
      display: block;
      max-height: 340px;
      overflow: auto;
      .line-separator-container{
        @include line-separator-container;
        position: static;
      }
      .droppable-section{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      @mixin container-content-user(){
        display: inline-block;
        width: 33.3%;
        padding: 10px;
        height: 123px;
        .container-image-purchaser{
          border: 1px solid #D7DAE2;
          border-radius: 5px;
          margin: 0;
          padding: 10px;
          height: 103px;
          max-width: 100%;
          overflow: auto;
          .icon-purchaser{
            object-fit: cover;
          }
          .container-info-purchaser{
            .content-name-purchaser, .name-purchaser, .email-purchaser, .phone-purchaser, .content-credit-score{
              height: 18px;
            }
          }
        }
      }

      .container-content-user{
        @include container-content-user;
      }
      .container-content-applicant{
        @include container-content-user;
        height: 160px;
        width: 100.0%;
      }
    }
    .container-footer{
      @include container-title;
      padding: 10px 0 0 10px;
      height: 52px;
      position: relative;
      .btn-continue{
        position: absolute;
        right: 14px;
        height: 32px;
        border-radius: 4px;
        background-color: #4AD991;
        border: 1px solid #D7DAE2;
        color: #ffffff;
        width: 114px;
        font-size: 13px;
        font-weight: $font-weight-regular;
        font-family: $font-family;
      }
    }
  }
}

.modal-edit-sale{
  display: flex !important;
  .modal-content{
    border-radius: 10px;
  }
  .modal-dialog{
    height: 670px;
  }

  .panel-container{
    padding: 0;
  }

  .container-header{
    left: 0;
    float: left;
    width: 100%;
    position: inherit;
    border-radius: 10px 10px 0 0;
    display: flex;
    height: 50px;
    .input-search{
      border: 0;
      outline: none;
      width: 60%;
      padding: 0;
      //margin-left: 20px;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 11px;
      margin: 15px 0 15px 20px;
    }
    .icon-search-content{
      padding-left: 25px;
      padding-top: 13px;
      float: left;
    }
    .icon-search{
      width: 20px;
      height: 20px;
      padding: 0;
      fill: #BCBCCB;
    }

    .container-header-right-options {
      width: 40%;
      margin-right: 30px;
      display: flex;
      justify-content: flex-end;

      .dp-pagination-container {
        margin-right: 20px;
        .btn-back {
          border-radius: 5px 0 0 5px;
        }
        .btn-next {
          border-radius: 0 5px 5px 0;
        }
      }

      .cosigner-check {
        box-shadow: 0 0 10px #00000029;
        border: 1px solid #D7DAE2;
        border-radius: 50%;
        cursor: pointer;
        .icon-check{
          width: 32px;
          height: 32px;
          .cls-1 {
            fill: #4ad991;
          }
        }
      }

      .cosigner-check-disabled{
        cursor: none;
        .icon-check{
          .cls-1 {
            fill: #D7DAE2;
          }
        }
      }
    }
  }

  .container-body{
    float: left;
    width: 100%;
    height: 615px;
    overflow: auto;
    .td-image, .th-photo{
      padding-left: 25px !important;
    }

    .td-image-check, .th-photo-check{
      padding-left: 15px !important;
    }
  }

  .panel-container-check{
    float: left;
    width: 100%;
    position: relative;
    height: 55px;
    border-top: 1px solid #D7DAE2;
    padding: 10px 20px;

    .cosigner-check{
      position: absolute;
      right: 30px;
      bottom: 10px;
      box-shadow: 0 0 10px #00000029;
      border: 1px solid #D7DAE2;
      border-radius: 50%;
      cursor: pointer;
      .icon-check{
        width: 32px;
        height: 32px;
        .cls-1 {
          fill: #4ad991;
        }
      }
    }

    .cosigner-check-disabled{
      cursor: none;
      .icon-check{
        .cls-1 {
          fill: #D7DAE2;
        }
      }
    }

    .dp-pagination-container{
      .pagination-right{
        float: left;
        .btn-back, .btn-center, .btn-next{
          float: left;
        }
      }
    }
  }
}



.container-message-delete-trade-in{
  text-align: center;
  .title{
    display: block;
    color: $icon-color;
    font-size: 15px;
    padding: 0 10% 10px;
  }
  .sub-title{
    display: block;
    padding: 0 10%;
    color: $icon-color;
  }
}

.container-error{
  text-align: center;
  padding: 10px;
  .error{
    color: $button-color-red;
    font-size: 12px;
  }
}

.modal-cancel-sale{
  .modal-dialog{
    width: 50%;
    height: 450px;
  }

  .modal-content{
    width: 100%;
    float: left;
    padding: 0;
    border-radius: 5px;
  }

  .modal-body{
    min-height: 380px;
    float: left;
    padding: 0;
    width: 100%;
    @include item-center;
  }

  .panel-right-cancel-sale{
    height: 100%;
  }

  .label-other-reason{
    font-size: 11px;
    color: $text-color;
    font-family: $font-family;
    font-weight: $font-weight-regular;
  }

  .btn-delete-sale{
    height: 36px;
    border: none;
    color: $button-color-red;
    margin-right: 5px;
    font-family: $font-family;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #ffffff;
    font-weight: $font-weight-regular;
  }

  .btn-save-custom{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .label-delete{
    float: left;
    font-family: $font-family;
    font-size: 10px;
    color: #3C3C3C;
    font-weight: $font-weight-regular;
    margin-bottom: 0;
    margin-left: 0;
  }

  .icon-warning-svg{
    width: 160px;
    height: 160px;
    fill: #daa4a2;
    .b{
      fill: #c4c8ce;
    }
    .a{
      stroke: #d5dae2;
      fill: #d5dae2;
    }
    .d{
      fill: #405b91;
    }
  }
}

.modal-add-car-to-inventory{
  .modal-dialog{
    width: 622px;
    .panel-left-custom{
      width: 230px;
    }
    .container-panel{
      height: 100%;
    }
    .panel-add-car-to-inventory{
      width: 392px !important;
      padding: 97px 73px !important;
    }
  }
}

.check-header{
  margin-left: 25px;
  border: 1.4px solid #3B86FF;
  margin-top: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    color: #3B86FF;
    margin-bottom: 0;
    font-weight: 600;
  }
}

.modal-edit-sale{
  .table{
    margin-bottom: 0;
  }
}

.td-check{
  width: 30px;
}

.select-car-page-container {
  padding: 0;
  display: block;
}

.sale-note-box{
  .icon-save-content:hover {
    cursor: pointer;
  }
}

.car-not-inventory-container {
  @include body-setting;
  .car-not-inventory-body {
    background: white;
    float: left;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #EFEFEF;
    .cnic-box-sale-agreement {
      .cnic-container-buttons {
        position: absolute;
        right: 70px;
        display: flex;
        top: 115px;
        z-index: 1;
        .add-car-to-inventory{
          border: 1px solid #D7DAE2;
          background-color: white;
          border-radius: 4px;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          margin: 0 10px;
          label{
            color: $text-color;
            font-size: 12px;
            margin-bottom: 0;
            cursor: pointer;
          }
          .add-car-img{
            width: 21px;
            height: 21px;
            margin-right: 8px;
          }
          .icon-arrow-content{
            float: right;
            padding: 0 0 0 10px;
            .icon-arrow-expand{
              height: $icon-arrow-height-svg;
              fill: #A4AFB7;
            }
          }
          &:hover{
            cursor: pointer;
          }
          .content-check{
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            height: auto;
            margin-top: 5px;
            .checkbox{
              width: 20px !important;
              float: left;
              margin-top: 0;
              margin-bottom: 0;
            }
            .container-label{
              display: flex;
              align-items: center;
              .check-description{
                font-size: 13px;
                color: $text-color;
                margin-top: 5px;
                float: left;
                font-weight: $font-weight-regular;
              }
              .check-required{
                float: left;
                color: $button-color-red;
                margin: 0;
                font-weight: 600;
              }
            }
          }
        }
        .check-ark{
          margin-right: 10px;
          .icon-check{
            border-radius: 50%;
            height: 40px;
            width: 40px;
            background-color: #4AD991;
            border-color: #4AD991;
            div{
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              svg{
                height: 18px;
              }
            }
          }
          &:hover{
            cursor: pointer;
          }
        }
        .button-save{
          @include buttons-next-done;
          border: 1px solid $button-color-green;
          background-color: $button-color-green;
          &:focus{
            border: 1px solid $button-color-green !important;
          }
        }

        .save{
          margin-right: 10px;
        }
        svg{
          height: 18px;
          fill: white;
        }
      }
      .has-error {
        border-color: #a94442;
        box-shadow: none;
      }
      .container-section {
        background: white;
        float: left;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #EFEFEF;
        .body-section {
          padding-bottom: 0;
          float: left;
          width: 100%;
          label{
            color: #797979;
            font-weight: $font-weight-semi-bold;
          }
          .row-section {
            padding-top: 15px;
            padding-left: 15px;
          }
        }
      }
    }
    //.car-header2{
    //  display: flex;
    //  align-items: center;
    //  padding: 0;
    //  width: 100%;
    //  .toggle-circle2{
    //    background-color: #2194F1;
    //    border: none;
    //    border-radius: 50%;
    //    width: 35px;
    //    height: 35px;
    //    float: left;
    //    position: relative;
    //    cursor: pointer;
    //  }
    //}
    .inputSale{
      padding-right: 10px;
      input {
        color: #797979 !important;
      }
    }
    .column11{
      width: 11%;
      float: left;
    }
    .column40 {
      width: 40%;
    }
    .column11-select{
      float: left;
      width: 11%;
      padding-right: 10px;
    }
    .datepicker-input > div {
      width: auto !important;
      height: auto !important;
      color: #2194F1 !important;

      > div hr{
        border: 0 !important;
      }
    }
    .container-icon-calendar{
      padding-top: 25px;
      .calendar-dob{
        >div{
          height: 30px;
          svg{
            width: 25px;
            height: 30px;
            fill: #A4AFB7;
          }
        }
      }
    }
    .cnic-flex-colRB {
      padding: 0 10px 0 0;
      width: 11%;
      flex: none;
      .item{
        .form-control{
          >div{
            display: initial !important;
            position: initial !important;
            color: #2194F1 !important;
            font-family: "San Francisco", "Segoe UI" !important;
            font-size: 14px ! important;
          }
        }
        .display-date{
          margin: 0 0 5px;
          p{
            margin: 0;
            color: #797979;
            font-weight: $font-weight-semi-bold;
          }
        }
        div{
          .label-error{
            color: red;
            padding-left: 2px;
          }
        }
      }
    }
    .select {
      display: flex;
      position: relative;
      height: 34px;
      border-radius: 5px;
      padding: 5px;
      border: 1px solid #ccc;
      cursor:pointer;
      .container-icon {
        right: 10px;
        position: absolute;
        i{
          color: #797979;
        }
      }
      .container-label {
        label{
          color: #797979 !important;
        }
      }
    }

    .default-popover{
      @include default-popover;
    }

    .popover-for-select{
      @include default-popover;
    }
    .container-option-for-select, .container-option-for-select-checked{
      padding: 0 10px;
      cursor:pointer;
      &:hover{
        background: #F5F6FA;
      }
      .option-label-for-select{
        font-weight: $font-weight-semi-bold;
        cursor:pointer;
      }
    }
    .popover-body-for-select{
      padding: 0;
      margin: 0;
      color: #979797;
      z-index: 100;
      position: relative;

      li{
        list-style: none;
        padding-bottom: 5px;
      }

      li:hover{
        cursor: pointer;
      }

      .options-for-select {
        .content-check{
          div > div{
            label{
              color: $text-color !important;;
            }
          }
        }
      }
    }
    .classInput{
      width: 100%;
      float: left;
      padding: 0 10px 0 0;
      position: relative;
      input{
        color: #797979 !important;
      }
      .help-block{
        padding-bottom: 10px;
      }
    }
  }
}

.sale-reload-files-page-container {
  @include body-setting;

  .sale-reload-files-page-body {
    background: white;
    float: left;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #EFEFEF;
    .sale-reload-files-page-first-row {
      height: 288px;
      width: 100%;
      .sale-file {
        height: 100%;
        width: 24%;
        float: left;
        margin: 0.5%;
        border-radius: 15px;
        border: 1px solid #D7DAE2;

        .container-image-file {
          height: 65%;
          border-radius: 15px 15px 0 0;
          border-bottom: 1px solid #D7DAE2;
          @include item-center;

          .iframe{
            height: 100%;
            width: 100%;
            border-radius: 15px 15px 0 0;
            border: none;
          }

          .image-sale-file{
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 15px 15px 0 0;
            border: none;
            body{
              img{
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
          }

          .dropzone-file{
            &:hover{
              cursor: pointer;
            }
          }

          .icon-sales-file {
            height: 100%;
          }

          .loader-container{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .loader {
              border: 7px solid #f3f3f3; /* Light grey */
              border-top: 7px solid #3498db; /* Blue */
              border-radius: 50%;
              width: 64px;
              height: 64px;
              animation: adjust-hue 1s linear infinite;
            }

            @keyframes adjust-hue {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          }
        }

        .container-type-buttons{
          height: 35%;
          float: left;
          width: 100%;
          border-radius: 0 0 15px 15px;
          padding: 10px;

          .container-type{
            label{
              font-weight: 500;
              font-size: 13px;
              color: $text-color-table;
            }
          }
          .container-buttons{
            .upload,.print,.print-disabled{
              height: $height-box-input;
              border-radius: 5px;
            }

            .upload{
              width: 100%;;
              border: 1px solid $button-color;
              background: #FFFFFF;
              color: $button-color;

              &:focus{
                border: 1px solid $button-color !important;
              }

              .icon-attach-file{
                width: 17px;
                float: left;
                padding-right: 10px;
                transform: rotate(180deg);

                .icon-attach{
                  fill:$button-color;
                }
              }

              .icon-upload-file{
                width: 15px;
                float: left;
                margin-right: 10px;
                display: flex;
                padding-top: 2px;
                div{
                  @include item-center;
                  .icon-upload{
                    height: 14px;
                    fill:#2194F1;
                    path{
                      fill:#2194F1;
                    }
                  }
                }
              }
            }

            .print,.print-disabled{
              width: 25%;
              min-width: 64px;
              color: #FFFFFF;
              background: #D7DAE2;
            }

            .print{
              border: 1px solid #2194F1;
              background: #2194F1;
            }

            .print-disabled{
              background: #D7DAE2;
              border: 1px solid #D7DAE2;
            }

            .dropzone-file{
              width: 30%;
              float: left;
              margin-right: 10px;
              min-width: 86px;
            }
          }
        }
      }
    }
  }
}

.sale-balance-container{
  @include body-setting;
  padding: 80px 30px 0 30px;
  .balance-body {
    background: white;
    float: left;
    width: 100%;
    border-bottom: 1px solid #EFEFEF;
    position: absolute;
    .loader-container{
      width: 100%;
      height: calc(100% - 30px);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .loader {
        border: 7px solid #f3f3f3; /* Light grey */
        border-top: 7px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 64px;
        height: 64px;
        animation: adjust-hue 1s linear infinite;
      }

      @keyframes adjust-hue {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
    .col25, .accessory-amount{
      width: 25%;
      float: left;
      .info-label{
        float: left;
        label{
          color: $text-color;
          font-weight: 400;
          margin-bottom: 0;
          font-size: 13px;
          margin-top: 7px;
        }
      }
      .inputSale{
        float: right;
        position: relative;
        width: 120px;
        .form-control{
          box-shadow: none;
          border-color: #D9D9D9;
          text-align: right;
          width: 120px;
        }
        .dollar-icon{
          position: absolute;
          left: 10px;
          top: 8px;
          color: #2194F1;
        }
        .subtract{
          color: #EAA734;
        }
        .help-block{
          font-size: 9px;
          margin-bottom: 0;
        }
      }
    }
  }
  .balance-price{
    float: left;
    width: 100%;
    padding: 25px 25px 15px 25px;
    border-bottom: 1px solid #D7DAE2;
    h3{
      margin: 0 0 20px 0;
      color: $text-color;
      font-weight: 700;
      font-size: 21px;
    }
  }
  .balance-row{
    float: left;
    width: 100%;
    margin-bottom: 10px;
    .input-disabled input{
      background-color: #F5F6FA;
    }
    .accessory-desc{
      float: left;
      width: calc(25% - 120px);
      .classInput{
        padding-right: 10px;
      }
      .form-control{
        box-shadow: none;
        border-color: #D9D9D9;
      }
    }
    .accessory-amount{
      width: 120px;
    }
    .icon-delete-content{
      float: left;
      margin-top: 7px;
      margin-left: 15px;
      .icon-delete{
        width: 16px;
        fill: #D7DAE2;
        &:hover{
          cursor: pointer;
        }
      }
    }
    .btn-add-accessory{
      background-color: transparent;
      border: none;
      color: #2194F1;
      padding: 0;
      font-size: 13px;
    }
    .balance-select{
      width: auto;
    //  position: relative;
      .select{
        float: left;
        width: calc(25% - 180px);
    //    border: 1px solid #D9D9D9;
    //    border-radius: 5px;
    //    height: 34px;
    //    align-items: center;
    //    display: flex;
    //    padding-left: 10px;
    //    position: relative;
    //    &:hover{
    //      cursor: pointer;
    //      label{
    //        cursor: pointer;
    //      }
    //    }
      }
    //  .container-label label{
    //    margin: 0;
    //    font-weight: 400;
    //    font-size: 13px;
    //  }
    //  .container-icon{
    //    position: absolute;
    //    right: 10px;
    //    i{
    //      color: #A4AFB7;
    //    }
    //  }
    //  .has-error{
    //    margin-bottom: 10px;
    //  }
    //  .help-block{
    //    position: absolute;
    //    top: 36px;
    //    font-size: 9px;
    //  }
    //  .select-disabled{
    //    cursor: not-allowed !important;
    //    background-color: #f5f6fa;
    //    label{
    //      cursor: not-allowed !important;
    //    }
    //  }
    }
    //.popover-for-select{
    //  overflow: auto;
    //  max-height: 110px;
    //  box-shadow: 0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12);
    //  border-radius: 2px;
    //  padding: 5px 0;
    //}
    //.popover-body-for-select{
    //  padding: 0;
    //  margin: 0;
    //  color: #979797;
    //  z-index: 100;
    //  position: relative;
    //  .option-label-for-select{
    //    font-size: 13px;
    //    font-weight: 400;
    //  }
    //  .container-option-for-select, .container-option-for-select-checked{
    //    padding: 0 10px;
    //    &:hover{
    //      cursor: pointer;
    //      label{
    //        cursor: pointer;
    //      }
    //    }
    //  }
    //}
    .sale-accessory-quantity{
      float: left;
      height: 34px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-add-accessory{
        font-size: 18px;
        padding: 0 10px;
      }
      .btn-add-quantity{
        padding: 0 0 0 8px;
      }
      span{
        color: $text-color;
      }
    }
    .number-quantity{
      margin-left: 10px;
    }
  }
}

.container-balance-summary{
  float: right;
  position: fixed;
  right: 80px;
  width: 340px;
  bottom: 40px;
  z-index: 100;
  background-color: white;
  box-shadow: 0 0 20px #A7A7A717;
  border: 1px solid #F5F6FA;
  border-radius: 5px;
  padding: 20px;
  .info-label{
    float: left;
    width: calc(100% - 120px);
    height: 34px;
    display: flex;
    align-items: center;
    label{
      font-size: 15px;
      color: $text-color;
      margin: 0;
    }
  }
  .input-disabled{
    float: left;
    width: 120px;
    position: relative;
  }
  .dollar-icon{
    position: absolute;
    left: 10px;
    top: 8px;
    color: #2194F1;
  }
  .form-control{
    text-align: right;
    box-shadow: none;
  }
  .balance-row-end{
    border-bottom: 1px solid #D7DAE2;
    padding-bottom: 10px;
  }
  .label-add-price{
    font-size: 11px;
    color: #2194F1;
    margin-right: 20px;
  }
  .label-subtract{
    font-size: 11px;
    color: #EAA734;
  }
  .subtract{
    color: #EAA734;
  }
  .icon-question-content{
    float: left;
    margin-left: 3px;
    margin-top: 5px;
  }
  .tax-label {
    position: absolute;
    top: 204px;
    left: 95px ;
    width: 80px;
    height: 30px;
    color: $button-color;
    font-weight: normal;
    font-size: 13px;
  }
.input-container {
  position: absolute;
  top: 196px;
  left: 122px ;
  width: 70px;
  border-radius: 5px;
  border-color: $input-border-color;
  border-style: solid;
  border-width: 1px;
  background-color: $input-background-color ;
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
  .tax-edit {
    width: 65px;
    padding: 0;
    margin-right: 2px;
    outline: none;
    border:none;
  }
  .tax-edit:focus{
      outline: none;
  }
  .form-control {
    border:none;
  }
  span{
    color: $text-color;
  }
}
  .exempt-checkbox {
    position: absolute;
    top: 216px;
    left: 20px ;
    height: 34px;
    display: flex;
    align-items: center;
    input {
      margin-bottom: 0;
      margin-top: 0;
      padding-right: 20px;
      outline: none;
    }
    .exempt-checkbox-label {
      display: block;
      font-size: 10px;
      font-weight: normal;
      margin-left: 5px;
      margin-bottom: 0;
      margin-top: 0;
      color: #787878;
    }
  }
}

.modal-start{
  display: flex !important;
  .modal-content{
    border-radius: 10px;
  }
  .modal-dialog{
    height: 570px;
  }

  .panel-container{
    padding: 0;
  }

  .popover-trade-in{
    height: 80px;
    padding: 10px 20px;
    label{
      font-size: 12px;
      font-weight: $font-weight-regular;
    }
  }

  .panel-container-body{
    padding: 15px;

    .container-panel{
      float: left;
      height: 500px;
      width: 100%;
      .panel-top, .panel-bottom{
        width: 100%;
        height: 50%;
        display: flex;
        padding-bottom: 10px;
        .panel-car, .panel-finance{
          width: 50%;
          height: 100%;
          border: 1px solid #D7DAE2;
          border-radius: 4px;
          .title-general{
            border-bottom: 1px solid #D7DAE2;
            display: flex;
            align-items: center;
            padding: 0 10px;
            height: 30px;
            label{
              font-size: 13px;
              margin-bottom: 0;
              font-weight: $font-weight-regular;
              font-family: $font-family;
            }
            .icon-check, .icon-trade-ins, .icon-finance{
              float: left;
              padding-right: 5px;;
              div{
                height: 100%;
                display: flex;
              }
              .icon-check-svg, .icon-trade-ins-svg, .icon-finance-svg{
                width: 15px;
                fill: #8F979D;
              }
              .icon-trade-ins-svg{
                height: 24px;
                fill: #8F979D
              }
              .icon-finance-svg{
                height: 24px;
              }
            }
            .right-element{
              width: 15px;
              margin-left: 10px;
              .container-icon{
                .icon-error{
                  div{
                    display: flex;
                  }
                }
                .svg-icon-error{
                  fill: #ffca83;
                  border: 1px solid #fff4e5;
                  border-radius: 50px;
                  width: 15px;
                  height: 15px;
                }
                &:hover{
                  cursor: pointer;
                }
              }
            }
          }
        }

        .panel-car{
          margin-right: 5px;
        }

        .panel-finance{
          margin-left: 5px;
        }

        .panel-finance{
          background-color: #ffffff;
        }

        .panel-car{
          .car-body{
            height: calc(100% - 30px);
            width: 100%;
            .container-icon-image{
              float: left;
              height: 60px;
              display: flex;
              width: 100%;
              padding: 10px 10px 0 10px;
              margin-bottom: 10px;
              .selected-car-image{
                width: 80px;
                height: 50px;
                border-radius: 4px;
                .image-object-contain{
                  object-fit: cover;
                }
              }
              .selected-car-info{
                float: right;
                text-align: left;
                position: relative;
                p {
                  margin-bottom: 0;
                  margin-left: 10px;
                }
                .make-model-year-car, .vin {
                  color: $text-color;
                  font-family: $font-family;
                  font-size: 13px;
                  font-weight: $font-weight-regular;
                }
                .vin{
                  font-size: 11px;
                }
              }
              label{
                width: 100%;
                color: $text-color;
                font-size: 15px;
                margin: 0;
              }
            }
            .container-balance{
              width: 100%;
              float: left;
              position: relative;
              .balance-body-accessories{
                .tool-body-option, .tool-body-option-color{
                  display: block;
                  width: 100%;
                  padding-left: 10px;
                  height: 22px;
                  align-items: center;
                  .accessory-title{
                    margin-bottom: 0;
                    float: left;
                  }
                  .total-accessory{
                    display: none;
                    float: right;
                    padding-right: 12px;
                  }
                  .accessory-title, .total-accessory{
                    height: 100%;
                    color:$text-color;
                    font-size: 11px;
                    font-family: $font-family;
                    font-weight: $font-weight-regular;
                    @include item-center;
                  }
                  .container-check-value, .container-check-value-input{
                    width: 100px;
                    display: block;
                    height: 100%;
                    padding-right: 12px;
                    float: right;
                    .container-icon{
                      float: left;
                      .icon-edit-check{
                        width: 11px;
                        display: inline-block;
                        vertical-align: middle;
                        .icon-edit-check-svg{
                          fill: $button-color-blue;
                          path{
                            fill: $button-color-blue !important;
                          }
                          &:hover{
                            cursor: pointer;
                          }
                        }
                      }
                    }
                    .total-check{
                      float: right;
                      height: 100%;
                      color:$text-color;
                      font-size: 11px;
                      font-family: $font-family;
                      font-weight: $font-weight-regular;
                      @include item-center;
                    }
                    .input-check{
                      width: 75px;
                      float: right;
                      padding: 1px 0;
                      .inputSale{
                        float: right;
                        position: relative;
                        width: 75px;
                        .form-control{
                          box-shadow: none;
                          border-color: #D9D9D9;
                          text-align: right;
                          width: 75px;
                          height: 20px;
                          font-size: 11px;
                          padding: 1px 6px 1px 11px;
                        }
                        .dollar-icon{
                          position: absolute;
                          left: 5px;
                          top: 2px;
                          color: $text-color;
                          font-size: 12px;
                        }
                      }
                    }
                  }
                  .container-check-value-input{
                    width: 100px;
                    padding-right: 6px;
                  }
                }
                .tool-body-option-color{
                  background-color: #F5F6FA;
                }
              }
              .balance-total{
                position: absolute;
                right: 0;
                margin-top: 2px;
                .total, .label-total{
                  padding-right: 12px;
                  color: $text-color;
                  font-size: 11px;
                  float: right;
                  font-family: $font-family;
                  font-weight: $font-weight-regular;
                }
                .total{
                  color: $button-color-green;
                }
                .label-total{
                  padding-right: 20px;
                }
              }
            }
          }

        }

        .panel-finance{
          .container-financing{
            width: 100%;
            .container-finances{
              height: 100%;
              .body-purchaser-finances{
                max-height: calc(100% - 20px) !important;
              }
              .header-finances, .row-finance, .row-finance-color{
                display: flex;
                align-items: center;
                font-size: 10px;
                height: 20px;
                color: $text-color;
                span{
                  width: 40%;
                  padding-left: 12px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  vertical-align: middle;
                  font-size: 10px;
                  display: inline;
                  font-weight: $font-weight-regular;
                  font-family: $font-family;
                }
                .title-1{
                  width: 30px;
                }
                .container-titles-header{
                  width: calc(100% - 30px);
                  padding-right: 5px;
                  display: flex;
                  .title-2{
                    width: 40%;
                  }
                  .title-3{
                    padding-right: 5px;
                    width: 35%;
                  }
                  .title-4{
                    width: 25%;
                  }
                }
                .content-check{
                  padding-left: 10px;
                  width: 30px;
                  .checkbox{
                    width: 20px !important;
                    float: left;
                    margin-top: 0;
                    margin-bottom: 0;
                    >div>div{
                      width: 18px;
                    }
                  }
                }
              }
              .row-finance, .row-finance-color{
                height: 25px;
                .container-titles-row{
                  width: calc(100% - 30px);
                  padding-right: 5px;
                  display: flex;
                  .title-2{
                    width: 40%;
                  }
                  .title-3{
                    width: 35%;
                  }
                  .title-4{
                    width: 25%;
                  }
                }
              }
              .header-finances{
                background: #D7DAE2;
                color: #FFF;
              }
              .row-finance-color{
                background-color: #F5F6FA;
              }
            }
            .content-warning{
              padding: 10px;
            }
          }
        }
      }
      .panel-finance{
        .container-financing{
          height: calc(100% - 30px);
          .body-purchaser-finances{
            height: calc(100% - 20px) !important;
            overflow: auto;
          }
        }
      }

      .panel-bottom-left, .panel-bottom-right{
        width: 50%;
        height: 100%;
        padding-bottom: 10px;
        .panel-trade-in, .panel-comments, .panel-SMS, .panel-email{
          width: 100%;
          height: 50%;
          border: 1px solid #D7DAE2;
          border-radius: 4px;
          .title-general, .title-general-messages{
            border-bottom: 1px solid #D7DAE2;
            display: flex;
            align-items: center;
            padding: 0 10px;
            height: 30px;
            label{
              font-size: 13px;
              margin-bottom: 0;
              font-weight: $font-weight-regular;
              font-family: $font-family;
            }
            .icon-check, .icon-trade-ins, .icon-finance{
              float: left;
              padding-right: 5px;;
              div{
                height: 100%;
                display: flex;
              }
              .icon-check-svg, .icon-trade-ins-svg, .icon-email-svg, .icon-sms-svg{
                width: 15px;
                fill: #8F979D;
              }
              .icon-trade-ins-svg{
                height: 24px;
                fill: #8F979D
              }
              .icon-sms-svg{
                width: 15px;
                height: 20px;
                margin-top: 2px;
              }
              .icon-email-svg{
                height: 100%;
              }
            }
            .right-element{
              width: 15px;
              margin-left: 10px;
              .container-icon{
                .icon-error{
                  div{
                    display: flex;
                  }
                }
                .svg-icon-error{
                  fill: #ffca83;
                  border: 1px solid #fff4e5;
                  border-radius: 50px;
                  width: 15px;
                  height: 15px;
                }
                &:hover{
                  cursor: pointer;
                }
              }
            }
          }
          .title-general-messages{
            position: relative;
            .sec-switch{
              float: right;
              position: absolute;
              right: 10px;
              top: 2px;
            }
          }
        }

        .panel-trade-in{
          .trade-ins-body{
            .content-check, .content-check-color{
              display: flex;
              align-items: center;
              width: 100%;
              padding-left: 10px;
              padding-right: 10px;
              height: auto;
              margin-top: 5px;
              background-color: #ffffff;
              .checkbox{
                width: 20px !important;
                float: left;
                margin-top: 0;
                margin-bottom: 0;
              }
              .container-label{
                display: flex;
                align-items: center;
                position: relative;
                width: calc(100% - 20px);
                .check-description{
                  font-size: 11px;
                  color: $text-color;
                  margin-bottom: 0;
                  float: left;
                }
                .check-price{
                  font-size: 11px;
                  color: $text-color;
                  margin-bottom: 0;
                  position: absolute;
                  right: 0;
                }
              }
            }
            .content-check-color{
              background-color: #F5F6FA;
            }
            .content-warning{
              padding: 10px;
            }
          }
        }

        .panel-comments{
          .content-comments{
            width: 100%;
            height: calc(100% - 30px);
            padding: 10px;
            .comments-edit{
              color: $text-color;
              width: 100%;
              font-size: 9px;
              border: none;
              background-color: transparent;
              outline: none;
              resize: none;
              margin-left: 0;
            }
          }
        }

        .panel-trade-in, .panel-SMS{
          margin-bottom: 5px;
        }

        .panel-comments, .panel-email{
          margin-top: 5px;
        }

        .panel-SMS, .panel-email{
          .content-message{
            width: 100%;
            height: 100%;
            padding: 10px;
            .message, .url, .name{
              color: $text-color;
              font-size: 11px;
              margin-bottom: 0;
              font-weight: $font-weight-regular;
            }
            .message{
              width: 100%;
            }
            .url{
              color: #2194F1;
            }
            .name{
              color: black;
            }
          }
        }
      }

      .panel-bottom-left{
        margin-right: 5px;
      }

      .panel-bottom-right{
        margin-left: 5px;
      }

    }

    .container-button-done{
      display: flex;
      position: relative;
      width: 100%;
      .btn-done{
        position: absolute;
        right: 0;
        height: 32px;
        border-radius: 4px;
        background-color: #4AD991;
        border: 1px solid #D7DAE2;
        color: #ffffff;
        width: 114px;
        font-size: 13px;
        font-weight: $font-weight-regular;
        font-family: $font-family;
      }
    }
  }
}

.input-disabled input {
  background-color: #F5F6FA !important;
  &:hover{
    cursor: not-allowed;
  }
}

.select-disabled, .input-date-disabled{
  background-color: #F5F6FA !important;
  &:hover{
    cursor: not-allowed !important;
  }
}

.row-disabled{
  &:hover{
    cursor: not-allowed !important;
    .select{
      cursor: not-allowed !important;
    }
  }
}

.popover-body-sale-finished{
  padding: 10px;
  .sale-history-title, .value{
    float: left;
    width: 100%;
    font-size: 9px;
    color: $text-color;
    font-weight: $font-weight-regular;
    margin: 0;
  }
  .sale-history-title{
    padding-top: 5px;
  }
  .value{
    font-size: 13px;
    padding-bottom: 5px;
  }
}

#canceledCompleted{
  #completedCanceledReason{
    border-bottom: 1px solid #D7DAE2;
  }
}

.row-readonly{
  &:hover{
    cursor: default !important;
    .select{
      cursor: default !important;
    }
  }
}

.modal-generate, .modal-title-agreement, .modal-buyer-guide{
  display: flex !important;
  .modal-content{
    border-radius: 10px;
  }
  .modal-dialog{
    height: 460px;
    width: 920px !important;
  }

  .panel-container{
    padding: 0;
  }

  .popover-trade-in{
    height: 80px;
    padding: 10px 20px;
    label{
      font-size: 12px;
      font-weight: $font-weight-regular;
    }
  }

  .panel-container-body{
    padding: 20px 10px 30px 10px;

    .title-modal, .sub-title-modal, .sub-title-insurance{
      width: 100%;
      float: left;
      padding: 0 15px;
      label{
        font-size: 21px;
        color: #787878;
        font-weight: $font-weight-bold;
        margin: 0;
      }
    }
    .sub-title-modal{
      margin-top: 10px;
      label{
        font-size: 15px;
      }
    }
    .sub-title-insurance{
      label{
        font-size: 15px;
      }
      .container-text{
        float: left;
        width: 100%;
        margin-top: 5px;
        .container-values{
          font-size: 13px;
          font-weight: $font-weight-regular;
          display: flex;
          float: left;
        }
        .container-inputs{
          margin: 0 5px;
          position: relative;
          float: left;
          .form-control{
            width: 170px;
            height: 19px;
            margin: 0;
            font-size: 13px;
            padding: 6px;
          }
          .policy{
            width: 102px;
          }
        }
      }
    }

    .container-other-terms{
      width: 100%;
      float: left;
      .panel-title{
        width: 100%;
        margin-top: 15px;
        display: flex;
        .other-terms-title{
          float: left;
          label{
            font-size: 15px;
            color: #787878;
            font-weight: $font-weight-bold;
            margin: 0;
          }
        }
        .other-terms-check{
          display: flex;
          float: left;
          margin-left: 5px;
          .content-check{
            display: flex;
            margin-top: 0;
            width: inherit;
            margin-left: 15px;
            .checkbox{
              margin-top: 0;
              width: 25px !important;
            }
          }
          .container-label{
            p{
              margin: 2px 0 0 0;
              font-size: 13px;
            }
          }
        }
      }
      .panel-other-term-body{
        width: 100%;
        display: flex;
        .other-terms-edit{
          color: $text-color;
          width: 100%;
          font-size: 13px;
          border: 1px solid #D7DAE2;
          background-color: transparent;
          outline: none;
          resize: none;
          margin-left: 0;
          padding: 5px 10px;
        }
      }
    }

    .container-button-done{
      display: flex;
      position: relative;
      width: 100%;
      float: left;
      .btn-done{
        position: absolute;
        right: 0;
        height: 32px;
        border-radius: 4px;
        background-color: #4AD991;
        border: 1px solid #D7DAE2;
        color: #ffffff;
        width: 200px;
        font-size: 13px;
        font-weight: $font-weight-regular;
        font-family: $font-family;
        margin: 20px 20px 0 0;
      }
    }
    .panel-container-body-sections{
      height: 330px;
      overflow: auto;
      position: relative;
      float: left;
      form{
        .panel-body {
          padding: 5px 15px;
        }
      }
    }
  }
}

.modal-title-agreement {
  .modal-dialog {
    height: 520px;
    width: 1020px !important;
  }

  .panel-container-body{
    .panel-container-body-sections{
      height: 390px;
    }
  }
}

.panel-container-input{
  padding: 5px 15px;
}

.classInputSaleInfo{
  padding: 0 10px 10px 0;
  border-color: #D7DAE2;
  position: relative;
  input {
    border-radius: 4px;
    height: 32px;
    color: #787878;
    font-size: 13px;
  }
  .form-control{
    div{
      #dateId{
        margin-top: -15px;
      }
      div{
        hr{
          border-bottom: none !important;
        }
      }
    }
  }
}

.classSaleHeaderInput {
  margin-bottom: 10px;
  padding-right: 5px;
  .form-control{
    border-color: #D7DAE2;
    font-size: 13px;
    color: #979797;
  }
}

.saleInfoLabel, .saleInfoLabelArea{
  position: relative;
  label {
    font-size: 9px;
    color: $text-color;;
    font-weight: 300;
    padding-left: 10px;
  }
  div{
    .form-control{
      border: 1px solid #ccc;
      div{
        #dateId{
          font-size: 14px;
          margin-top: 3px;
          margin-left: 10px;
        }
      }
      .MuiInput-underline::before{
        border-bottom: none;
      }
      .MuiInput-underline::after{
        border-bottom: none;
      }
    }
  }
}

.colS35{
  width: 35%;
  float: left;
}

.colS17{
  width: 17.5%;
  float: left;
}

.colS15{
  width: 15%;
  float: left;
}

.btn-select-option {
  width: 100%;
  height: 32px;
  float: left;
  color: #979797;
  background-color: #fff;
  border: 1px #dddee0 solid;
  border-radius: 4px;
  text-align:left;

  span {
    font-size: 13px;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 25px);
  }

  i {
    float: right;
  }
  &:hover{
    color: #979797 !important;
    cursor: pointer;
  }
}
.popover-box-modal{
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
  top: 34px;
}

.file-empty-title{
  width: 100%;
  text-align: center;
  color: #8F979D;
  font-size: 15px;
  font-weight: $font-weight-bold;
}

.file-empty-link{
  width: 100%;
  float: left;
  text-align: center;
  color: $text-color;
  font-size: 13px;
  font-weight: $font-weight-regular;
  a{
    color: #2194F1;
    &:hover{
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.modal-buyer-guide{
  .modal-dialog {
    height: 580px;
    width: 1060px !important;
  }

  .panel-container-body .panel-container-body-sections{
    width: 100%;
    height: 450px;
  }

  .col33, .col16{
    padding-right: 10px;
    .classSaleHeaderInput {
      padding-right: 0;
      textarea{
        height: 120px;
      }
    }
  }

  .saleInfoLabel{
    padding-bottom: 5px;
    width: 100%;
    float: left;
    input{
      height: 32px;
    }
  }

  .content-check{
    .checkbox{
      width: auto !important;
      float: left;
      margin: 0;
    }
    .container-label {
      margin-top: 3px;
    }
    .check-description{
      margin-bottom: 0;
    }
  }

  .carInfoLabel label {
    font-size: 10px;
    color: $text-color;
  }

  .label-multi-select{
    font-size: 15px;
    color: $text-color;
    font-weight: 600;
  }
}

.modal-retail-installment-contract{
  .modal-dialog{
    height: 300px;
  }
  .panel-container-body{
    .panel-container-body-sections{
      height: 162px;
      .help-block{
        color: $button-color-red;
        font-size: 9px;
      }
      .has-error{
        input{
          border-color: $button-color-red;
        }
      }
      .carInfoLabel{
        label{
          font-size: 9px;
          color: #787878;
          font-weight: 300;
          padding-left: 10px;
        }
        .label-error{
          font-size: smaller;
        }
      }
      .saleInfoLabel{
        padding: 0 10px 10px 0;
        position: relative;
        .form-control{
          //padding: 0 12px 0 25px !important;
          input{
            margin-left: 0 !important;
          }
        }
        .percent{
          font-size: 14px;
          position: absolute;
          color: #787878;
          right: 18px;
          top: 8px;
        }
        .fa-dollar{
          position: absolute;
          color: #787878;
          left: 12px;
          top: 12px;
          font-size: 11px;
        }
        input{
          padding: 6px 18px;
        }
        .label-error{
          font-size: smaller;
        }
      }
      .input-disabled{
        input{
          padding: 6px 18px !important;
        }
      }
    }
    .subtitle-modal{
      float: left;
      padding: 0 15px;
      label{
        font-weight: 300;
        font-size: 12px;
        color: #787878;
      }
    }
    .container-button-done{
      .btn-done{
        width: 250px;
        margin: 0 20px 0 0;
      }
    }
  }
}

.customer-list-empty{
  @include item-center;
  height: 360px;
  flex-direction: column;
  label{
    font-size: 21px;
    color: $text-color;
    margin: 0;
    padding: 10px 0 5px 0;
    font-weight: $font-weight-semi-bold;
  }
  span{
    font-size: 13px;
    color: $text-color;
  }
  a{
    color: #2194F1;
    &:hover{
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.table-header, .table-body{
  float: left;
  width: 100%;
}

.table-header{
  background-color: #EFEFEF;
  tr{
    width: inherit;
    float: left;
  }
}

.icon-customer-empty{
  width: 88.87px;
  height: 67.9px;
  g > g > .a{
    stroke: none;
    fill: #d7dae3;
  }
}

.modal-empty{
  .modal-dialog{
    width: 520px;
    height: 360px;
  }
}

.table-customer-modal{
  .tr-customer{
    float: left;
    width: 100%;
  }
  .td-image{
    width: 100px;
  }
  .td-user{
    width: 250px;
  }
  .td-email{
    width: 300px;
  }
  .td-phone{
    width: 200px;
  }
}

.label-view-finance{
  color: $text-color;
  font-size: 13px;
  font-weight: $font-weight-regular;
}

@media screen and(max-width: 1400px){
  .modal-new-sale{
    .modal-dialog{
      width: 70%;
      .panel-container-body{
        .container-step{
          .panel-left, .panel-right{
            padding: 0 10px;
          }
          .panel-center{
            padding: 0 10px 0 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .modal-edit-sale .modal-dialog {
    height: 500px;
  }

  .modal-edit-sale .container-body {
    height: 440px;
  }
}

@media screen and (max-width: 1180px) {
  .sale-content .container-center .panel-sale .panel-sale-left .panel-sale-left-bottom .panel-sale-check-list .check-list-body .content-check .container-label .check-description{
    font-size: 12px;
  }

  .sale-content .container-center .panel-sale .panel-sale-left .panel-sale-left-top .panel-financing .body-payment .payment-finance{
    padding: 2px 2px;
    .icon-getting-finance{
      padding: 3px 3px 0 0;
    }
    label{
      font-size: 10px;
    }
  }
}

@media (max-width: 1110px) {
  .sale-content .container-center .panel-sale .panel-sale-left .panel-sale-left-bottom .panel-sale-check-list .check-list-body .content-check .container-label .check-description{
    font-size: 11px;
  }
}

@media screen and (max-width: 1080px){
  .sale-content .container-center .panel-sale .panel-sale-left .panel-sale-left-top .panel-sale-car-tools .panel-sale-car .body-car .container-option-car{
    .car-inventory, .car-not-inventory{
      label{
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: $tablet-view-pixel){
  .sale-content-edit{
    .container-center{
      padding: 70px 20px 0 20px;
    }
  }

  .container-header {
    .input-search {
      width: 50%;
    }

    .container-header-right-options {
      width: 50% !important;
    }
  }

  .table-customer {
    .th-check{
      width: 30px;
    }
    .th-photo {
      width: 100px;
    }
    .th-user {
      width: 250px;
    }
    .th-email {
      width: 300px;
    }
    .th-phone {
      width: 200px;
    }
  }
}

@media screen and (max-width: $mobile-view-pixel){
  .sale-content-edit{
    .container-center{
      height: 100%;
      padding: 60px 10px 0 10px;
    }
  }
  .modal-add-car-to-inventory{
    .modal-dialog{
      width: 90%;
      .panel-left-custom{
        width: 100% !important;
      }
      .container-panel{
        height: initial;
        @include item-center;
        .panel-add-car-to-inventory{
          padding: 51px 0 !important;
          width: auto !important;
        }
      }
    }
  }
}

@media screen and (max-width: 480px){
  .modal-add-car-to-inventory{
    .modal-dialog{
      .container-panel{
        padding: 0 20px;
        .panel-add-car-to-inventory{
          padding: 41px 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 400px){
  .modal-add-car-to-inventory{
    .modal-dialog{
      .container-panel{
        .panel-add-car-to-inventory{
          padding: 32px 0 !important;
        }
      }
    }
  }
}
