@import "../../../../common/styles/constants";

@mixin shadow-body(){
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.plans-container-center{
  display: flex;
  padding: 60px 20px 0 25px;
  .panel-plans{
    min-height: 100%;
    background: white;
    border-radius: 7px;
    @include shadow-body;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: 11px 20px;
    margin-right: 10px;
    font-size: 15px;
    .header-plan-section{
      display: flex;
      align-items: center;
      .header-text{
        font-size: 21px;
        font-weight: bold;
        height: 28px;
      }
      .edit-icon{
        margin-left: auto;
        height: 14px;
        width: 14px;
        fill: #A4AFB7;
        cursor: pointer;
      }
    }
    .price-section{
      color: #4AD991;
      margin-bottom: 8px;
    }
    .features-title-section{
      font-weight: bold;
      margin-bottom: 5px;
    }
    .feature-item{
      margin-bottom: 5px;
    }
    .plan-name-input{
      .info-label{
        .title{
          font-size: 9px;
          color: #787878;
          margin-bottom: 4px;
          font-weight: normal;
        }
      }
      .classInput{
        .form-control{
          border: 1px solid #EFEFEF;
        }
      }
    }
    .monthly-cost-input{
      width: 49%;
      margin-right: 5px;
      .info-label{
        .title{
          font-size: 9px;
          color: #787878;
          margin-bottom: 4px;
          font-weight: normal;
        }
      }
      .classInput{
        .form-control{
          border: 1px solid #EFEFEF;
          height: 34px
        }
      }
    }
    .annual-cost-input{
      width: 49%;
      .info-label{
        .title{
          font-size: 9px;
          color: #787878;
          margin-bottom: 4px;
          font-weight: normal;
        }
      }
      .classInput{
        .form-control{
          border: 1px solid #EFEFEF;
        }
      }
    }
    .team-members-input, .listings-input{
      margin-left: auto;
      .classInput{
        .form-control{
          border: 1px solid #EFEFEF;
          height: 24px;
          width: 48px;
          font-size: 13px;
        }
      }
    }
    .edit-mode{
      display: flex;
      flex-direction: column;
      .detailer-header-plan-section{
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
      }
      .included-features, .available-features{
        display: flex;
        flex-direction: column;
        margin-bottom: 11px;
        font-size: 15px;
        .header-section-title{
          font-weight: bold;
        }
        .header-section-sub-title{
          font-size: 9px;
          color: #787878;
          margin-bottom: 14px;
        }
        .specific-feature-section{
          display: flex;
          align-items: center;
          .icon-arrow-container{
            display: flex;
            align-self: center;
            flex-direction: column;
            height: 35px;
            width: 10px;
            margin-right: 5px;
            .icon-arrow{
              height: 10px;
              .icon-arrow-svg{
                height: $icon-arrow-height-svg;
                fill: #D7DAE2;
                width: 8px;
              }
            }
          }
          .specific-feature-title{
            display: flex;
            height: 35px;
            align-items: center;
            color: #171A22;
          }
        }
        .feature-item-container{
          display: flex;
          align-items: center;
          .icon-arrow-container{
            display: flex;
            align-self: center;
            flex-direction: column;
            height: 35px;
            width: 10px;
            margin-right: 5px;
            .icon-arrow{
              height: 10px;
              .icon-arrow-svg{
                height: $icon-arrow-height-svg;
                fill: #D7DAE2;
                width: 8px;
              }
            }
          }
          .feature-item-text{
            display: flex;
            height: 35px;
            align-items: center;
            color: #171A22;
          }
        }
      }
      .footer-section{
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-bottom: 11px;
        .cancel-button{
          margin-right: 21px;
          cursor: pointer;
        }
        .save-button{
          background-color: #4AD991;
          font-size: 13px;
          color: white;
          width: 67px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}