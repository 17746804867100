.review-module{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;


  .title-review{
    font-size: 20px;
    margin-bottom: 4px;
  }

  .container-message{
    margin-bottom: 6px;
    display: flex;
    align-items: flex-start;
    font-size: 13px;

    .icon-class{
      width: 24px;
      fill: rgb(255, 202, 131);
      margin-right: 4px;
      border: 1px solid #FFF4E5;
      border-radius: 50%;
      svg{
        width: 15px;
        display: flex;
        height: auto;
      }
    }

    .second-message{
      color: #A4AFB7;
      font-size: 10px;
    }
  }

  .class-input{
    textarea{
      resize: none;
    }
  }

  .container-buttons{
    display: flex;
    padding-left: 14px;
    .btn-send-now{
      color: #2194F1;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon-send{
        height: 15px;
        width: 15px;
        fill: #2194F1;
        margin-right: 3px;
        transform: rotate(-35deg);
      }
    }
  }

  .info-label{
    display: flex;
    justify-content: space-between;
    padding: 5px 12px;

    .font-max{
      justify-content: space-between;
    }
  }

  .btn-save{
    margin-left: auto;
    height: 32px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4AD991;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
  }

  .btn-save-disabled{
    margin-left: auto;
    height: 32px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D7DAE2;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
  }

  .btn-send{
    cursor: pointer;
  }

  .container-social-reviews{
    padding: 0 0 40px 10px;
    .text-indications{
      font-size: 10px;
      color: #A4AFB7;
    }
  }

  .title-review, .container-message-info{
    padding: 0 12px;
  }

}
