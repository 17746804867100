@import "src/common/styles/utils";
.box-demo{
  font-family: 'Montserrat';
  background: #FFF;
  padding: 0 60px 40px 60px;
  .container-icon-cancel{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: end;
    svg{
      height: 100%;
      width: 100%;
    }
  }

  .logo-godealergo{
    width: 200px;
    margin: 0 auto 30px;
  }
}

.marketing-form{
  h3{
    font-size: 46px;
    font-weight: 700;
    text-align: center;
  }
  >span{
    text-align: center;
    display: block;
    font-size: 15px;
    font-weight: 300;
    color: #4D4F5C;
  }
  .container-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    .line-left, .line-right{
      width: calc(50% - 120px);
      border-top: 1px solid;
      color: #D7DAE276;
    }
    span{
      color: #292757;
      font-weight: 700;
      font-size: 10px;
    }
  }

  .container-inputs{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    label{
      color: #292757;
    }
    .label-make{
      font-weight: 700;
      font-size: 10px;
    }
    >div{
      width: 48%;
      padding-right: 0;
      padding-bottom: 15px;
      input, .select{
        height: 40px;
        border-radius: 1px;
        box-shadow: none;
        font-family: 'Montserrat' !important;
        color: #4D4F5C;
      }
    }
    .container-label{
      .label-make{
        font-size: 14px;
        font-weight: 400;
        color: #4D4F5C;
      }
    }
    .w100{
      width: 100%;
    }
    .container-button-send{
      width: 100%;
      @include item-center;
    }
    .market-btn{
      border-radius: 1px;
      height: 40px;
      font-weight: 300;
      font-size: 15px;
      background-color: #057FFA;
      margin: 16px 0 0 0;
      @include item-center;
      width: auto;
      padding: 14px 22px;
    }
    .last-input{
      padding-bottom: 10px;
    }
    .required-text{
      font-size: 11px;
    }
    .dp-select-dropdown{
      .help-block{
        position: unset;
        font-size: 11px;
      }
    }
  }
}

.loader-demo{
  opacity: 0.6 !important;
  background-color: #000 !important;
}
