.dp-multiple-select-dropdown{
  position: relative;
  padding-bottom: 10px;
  .display-value{
    margin: 0;
    font-size: 10px;
    color: #787878;
    font-weight: 500;
  }
  .select{
    border: 1px solid #D7DAE2;
    border-radius: 4px;
    height: 34px;
    align-items: center;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

    &:hover{
      cursor: pointer;
    }

    svg{
      height: 8px;
      width: auto;
      fill: #A4AFB7;
    }

    .container-label{
      flex: 1;
      .select-text{
        margin: 0;
        display: flex;
        font-size: 13px;
        color: #535353;
        font-weight: 500;
        cursor: pointer;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .container-icon{
      transition: 0.3s;
    }
  }

  .popover-for-select{
    border: 1px solid #D7DAE2;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 10px;
  }
}