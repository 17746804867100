.reviews-and-referrals {

    background: #1D2130;
    color: white;

  .section-wrapper {
    .small-blue-font {
      padding-top: 25px;
    }
  }

  .section-wrapper img {
    max-width: 45vw;
  }

  .asterisk{
    color: #3B86FF;
  }

  @media only screen and (max-width: 768px) {
    .section-wrapper img {
      max-width: 80vw;
    }
  }
}
