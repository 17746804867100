.recommendation-component-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 88%;
  padding: 15px 0;
  border-top: 1px solid #D7DAE2;
  margin-top: 15px;

  .information-recommendation{
    display: flex;
    flex-direction: column;

    .description{
      font-size: 13px;
      color: #454545;
    }
    .sub-description{
      padding-right: 2px;
    }
    .sub-description, .detail{
      font-size: 11px;
      color: #787878;
    }
  }
  .button-recommendation {
    .recommendation-button {
      background: #2194F1;
      height: 36px;
      cursor: pointer;
      transition: all 1s ease-out;

      &:hover {
        background: #005294;
      }

      .loading-content{
        width: 92px !important;

        justify-content: center;
        font-size: 13px;
      }
    }

  }
}
