.container-rating-bar{
  display: flex;
  padding: 3.5px 0px;

  .number-row{
    display: flex;
    align-items: center;
    padding-right: 4.56px;

    .number{
      font-size: 13px;
      color: #D7DAE2;
      margin: 0;
    }
  }

  .icon-star-container{
    display: flex;
    align-items: center;
    padding-right: 12.75px;

    .icon-star-progress{
      display: flex;
      height: 11px;
      width: 11px;

      >div{
        display: flex;
        width: 100%;

        svg{
          height: 100%;
        }
      }
    }
  }

  .progress-bar-container{
    display: flex;
    align-items: center;
    padding-right: 23.14px;

    .progress-bar-container-main{
      fill: #D7DAE2;
    }
  }

  .number-progress-container{
    display: flex;
    align-items: center;

    .number-progress{
      font-size: 13px;
      color: #D7DAE2;
      font-weight: 400;
      margin: 0;
    }
  }
}