@import "src/common/styles/utils";

.modal-content{
  box-shadow: 0 2px 10px #00000027;
}

.container-reviews{
  display: flex;
  padding-left: 50px;
  flex: 1;
  width: 100%;
  transition: all 0.3s ease;
  .column-reviews{
    display: flex;
    flex-direction: column;
    min-width: 430px;
    width: 33%;
    height: 100%;
  }
  .container-reviews-body{
    display: flex;
    background-color: #f3f4f8;
    padding: 7.5px;
    overflow: auto;
    width: 100%;
  }
}

.completed-send-mail-modal-container{
  @include item-center;
  display: flex !important;

  .modal-dialog {
    width: 474px;
    height: auto;

    .modal-body {
      .body-container {
        display: flex;
        justify-content: center;
        align-content: center;
        .gif-container{
          display: flex;
          width: 380px;
          height: 380px;
        }
      }
    }
  }
}

.send-mail-modal-container{
  @include item-center;
  display: flex !important;
  background-color: #A4AFB74A;
  .modal-dialog{
    width: 772px;
    height: auto;
    .modal-content{
      border: none;
      border-radius: 10px;
    }
    .modal-body{
      padding: 0px;
      .body-container{
        display: flex;
        flex-direction: column;
        padding: 21px 28px;
        .requests-container{
          display: flex;
          margin-bottom: 17px;
          .panel-sms-container{
            margin-right: 5px;
          }
          .panel-email-container{
            margin-left: 5px;
          }
          .panel-sms-container, .panel-email-container{
            width: 50%;
            height: 100%;
            .panel-sms, .panel-email{
              width: 100%;
              height: auto;
              border: 1px solid #D7DAE2;
              border-radius: 4px;
              .title-general-messages{
                border-bottom: 1px solid #D7DAE2;
                display: flex;
                align-items: center;
                padding: 0 10px;
                height: 33px;
                position: relative;
                label{
                  font-size: 13px;
                  font-weight: initial;
                  margin: 0;
                }
              }
              .icon-check{
                float: left;
                padding-right: 5px;
                fill: #CACACA;
                height: 24px;
                display: flex;
                align-items: center;
                >div{
                  display: flex;
                }
              }
              .icon-sms-svg{
                width: 15px;
                height: 20px;
                margin-top: 0;
              }
              .icon-email-svg{
                width: 15px;
                fill: #8F979D;
                height: 100%;
              }
              .sec-switch{
                float: right;
                position: absolute;
                right: 10px;
                top: calc(50% - 13px);
              }
            }
          }
        }

        .customer-list-container{
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          background-color: transparent;
          .horizontal-list-container{
            background-color: #FFFFFF;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            font-size: 13px;
            padding: 2px;
            border-radius: 4px;
            border: 1px solid #D7DAE2;
            margin-bottom: 6px;
            min-height: 33px;

            .custom-input-text{
              border: none;
              outline: none;
            }

            .pill-container {
              background-color: #E8F4FE;
              color: #575757;
              min-height: 26px;
              width: auto;
              padding: 0 0 0 10px;
              margin-top: 1px;
              @include item-center;
              .pill-label{
                display: flex;
                justify-content: space-around;
              }
              .pill-delete-icon{
                @include item-center;
                cursor: pointer;
                padding: 0 10px;
                font-size: 16px;
              }
            }
          }

          .vertical-list-content{
            display: flex;
            width: 100%;
            z-index: 101;
            color: black;
            margin-bottom: 16px;
            flex-direction: column;
            font-size: 13px;
            position: absolute;
            left: 0;
            top: 100%;
            background-color: white;
            border: 1px solid #D7DAE2;
            box-shadow: 0px 0px 10px #D7DAE2;
            border-radius: 4px;
            padding: 2px 12px;
            height: 90px;
            overflow: auto;

            .customer-item{
              color: black;
              min-height: 18px;
              margin-top: 4px;
              margin-bottom: 4px;
              cursor: pointer;
              .additional-text{
                color: #787878;
              }
            }
          }
        }

        .container-buttons{
          display: flex;
          .btn-add-new-customer{
            color: #2194F1;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 13px;
            .icon-add-new-customer{
              height: 15px;
              width: 15px;
              fill: #2194F1;
              margin-right: 3px;
            }
          }
          .btn-send{
            @include item-center;
            margin-left: auto;
            height: 32px;
            width: 67px;
            background-color: #4AD991;
            color: #FFFFFF;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;
          }
          .btn-disabled-send{
            @include item-center;
            margin-left: auto;
            height: 32px;
            width: 67px;
            background-color: #D7DAE2;
            color: #FFFFFF;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;
          }
          .btn-cancel-dialog{
            @include item-center;
            margin-left: auto;
            color: #A4AFB7;
            font-size: 13px;
            width: 37px;
            min-height: 18px;
            margin-right: 23px;
            cursor: pointer;
          }
          .btn-add-customer{
            @include item-center;
            height: 32px;
            width: 118px;
            background-color: #4AD991;
            color: #FFFFFF;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;
          }
        }
      }

      .second-dialog-body-container{
        display: flex;
        flex-direction: column;
        padding: 21px 0px;
        .add-customer-title{
          minHeight: 28px;
          font-size: 21px;
          margin-bottom: 9px;
          display: flex;
          align-items: center;
          padding: 0 28px;
          .add-customer-back-button{
            height: 13px;
            width: 13px;
            fill: #2194F1;
            margin-right: 10px;
            transform: rotate(-180deg);
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .left-icon-svg{
            height: 13px;
            width: 13px;
          }
        }

        .warning-message{
          display: flex;
          align-items: center;
          height: 37px;
          background-color: #F3A200;
          color: white;
          padding: 0 28px;
          font-size: 13px;
        }

        .error-message{
          display: flex;
          align-items: center;
          height: 37px;
          background-color: red;
          color: white;
          padding: 0 28px;
          font-size: 13px;
        }

        .add-customer-container-controls{
          margin-top: 10px;
          margin-bottom: 30px;
        }

        .row-container-controls{
          display: flex;
          margin-bottom: 10px;
          padding: 0 28px;
          .control-add-new-customer{
            width: 233px;
            margin-right: 10px;
            .info-label{
              label{
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                color: #787878;
                font-size: 10px;
                font-weight: initial;
              }
            }
            .classInput{
              .form-control{
                font-size: 13px;
                color: #535353;
                font-weight: initial;
              }
            }
          }
          .control-enhanced-add-new-customer{
            width: 155px;
            margin-right: 10px;
            .info-label{
              label{
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                color: #787878;
                font-size: 10px;
                font-weight: initial;
              }
            }
            .classInput{
              .form-control{
                font-size: 13px;
                color: #535353;
                font-weight: initial;
              }
            }
          }
        }

        .coincidences-container{
          .coincidences-title{
            padding: 0 28px;
            font-size: 21px;
          }
          .coincidences-list{
            display: flex;
            flex-direction: column;
            .coincidence-row{
              display: flex;
              align-items: center;
              flex-direction: row;
              height: 48px;
              padding: 0 28px;
              font-size: 15px;
              &:hover{
                background-color: #efefef;
              }
              .full-name{
                width: 30%;
              }
              .email{
                width: 35%;
              }
              .phone-number{
                width: 20%;
              }
              .option{
                font-size: 10px;
                margin-left: auto;
                &:hover{
                  color: #2194F1;
                  cursor: pointer;
                }
              }
            }

          }
        }

        .container-buttons{
          display: flex;
          margin-top: 21px;
          padding: 0 28px;
          .btn-add-new-customer{
            color: #2194F1;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 13px;
            .icon-add-new-customer{
              height: 15px;
              width: 15px;
              fill: #2194F1;
              margin-right: 3px;
            }
          }
          .btn-send{
            @include item-center;
            margin-left: auto;
            height: 32px;
            width: 67px;
            background-color: #4AD991;
            color: #FFFFFF;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;
          }
          .btn-disabled-send{
            @include item-center;
            margin-left: auto;
            height: 32px;
            width: 67px;
            background-color: #D7DAE2;
            color: #FFFFFF;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;
          }
          .btn-cancel-dialog{
            @include item-center;
            margin-left: auto;
            color: #A4AFB7;
            font-size: 13px;
            width: 37px;
            min-height: 18px;
            margin-right: 23px;
            cursor: pointer;
          }
          .btn-add-customer{
            @include item-center;
            height: 32px;
            width: 130px;
            background-color: #4AD991;
            color: #FFFFFF;
            border-radius: 4px;
            cursor: pointer;
            font-size: 13px;
          }
        }
      }
    }
  }
  .close-modal-icon{
    width: 32px;
    position: absolute;
    top: -15px;
    right: -13px;
    cursor: pointer;
  }
}