.section-item-content{
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;

  &:hover{
    background-color: #F5F6FA;
  }

  .left-part{
    display: flex;
    align-items: center;

    .left-svg-image-container{
      >div{
        display: flex;
      }
    }
    svg{
      width: auto;
      height: 13px;
      fill: #D7DAE2;
    }

    .title-item-text{
      padding-left: 4.8px;
      font-size: 13px;
      font-weight: 300;
    }
  }
  .active{
    svg{
      width: auto;
      height: 13px;
      fill: #4AD991;
    }
  }

  .right-part{
    display: flex;
    align-items: center;

    .right-svg-image-container{
      >div{
        display: flex;
      }
    }
    svg{
      width: auto;
      height: 13px;
      fill: #D7DAE2;
    }
  }
}