
.container-checkbox{
  display: flex;
  align-items: center;
  .checkbox{
    margin: 0;
    flex: 1;
    svg{
      .a{
        fill: transparent;
      }
    }
  }
  .circle{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f35958;
  }
}