@import 'utils';
//--------------------part public------------------

$height-photo: 36px;
$font-family: 'Open Sans', sans-serif;
$font-weight-bold: bold;
$font-weight-regular: 400;
$font-weight-light: 200;
$green-color: #4AD991;
$button-color-red : #FF6565;

.item-center{
 @include item-center;
}

.panel-customer{
  border-radius: 10px;
  float: left;
}

.modal-delete{
  .modal-dialog{
    .modal-content{
      box-shadow: 0 0 30px #00000029;
      .modal-body{
        .panel-left{
          border-radius: 10px 0 0 10px;
        }
        .panel-right{
          box-shadow: none;
          border-radius: 0 10px 10px 10px;
          .label-sure{
            label{
              padding: 0 10px;
            }
          }
          .container-button-delete{
            padding-top: 30px;
          }
          .container-fee-delete-button{
            padding-top: 10px;
            padding-left: 50px;
          }
          .icon-close-content{
            top: -16px;
            right: -16px;
          }
        }
      }
    }
  }
}

.table-customer, .table-rol{
  width: 100%;
  padding: 0;
  thead {
    tr {
      background-color: #f5f6fa;
      color: #a6a8b4;
      font-weight: $font-weight-bold;
      font-size: 14px;
      th {
        border-bottom: 0;
        padding: 15px 0;
      }
    }
  }
  tbody {
    tr {
      color: #b8bdc2;
      font-size: 14px;
      font-weight: $font-weight-regular;
      td {
        border-top: 0;
        padding: 10px 0;
      }
    }
  }

  .loader-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .loader {
      border: 7px solid #f3f3f3; /* Light grey */
      border-top: 7px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 64px;
      height: 64px;
      animation: adjust-hue 1s linear infinite;
    }

    @keyframes adjust-hue {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  .tr-customer{
    display: table-row;
    position: relative;
    .image-user{
      width: $height-photo;
      height: $height-photo;
      border-radius: 50%;
      object-fit: cover;
    }

    .td-phone{
      padding-left: 10px;
    }

    td, .td-phone{
      vertical-align: middle;
      font-size: 13px;
      color: #787878;
    }

    .customer-actions{
      position: absolute;
      right: 100px;
      margin-top: 15px;
    }

    .img-view, .img-delete{
      width: 15px;
      height: 16px;
    }

    .img-view {
      fill: $light-blue-color-1
    }

    .img-delete{
      margin-left: 13px;
      fill: $button-color-red;
    }
  }

  .td-image{
    padding-left: 40px;
  }

  @mixin td-actions(){
    float: right;
    padding-right: 50px;
    height: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  .td-actions{
    @include td-actions;
  }
  .td-actions-superuser{
    @include td-actions;
    padding-right: 35px
  }

  .td-email{
    text-decoration: underline;
    color: #2194F1 !important;
  }

  .th-photo{
    width: 100px;
    padding-left: 40px;
  }

  .th-user{
    width: 250px;
  }

  .th-email{
    width: 300px;
  }

  .th-phone{
    width: 200px;
    padding-left: 10px;
  }

  .th-photo, .th-user, .th-email, .th-phone, .th-location{
    color: #787878;
    font-weight: 600;
  }
}

.table-customer{
  .table{
    margin-bottom: 0;
  }
}

.customer-actions {
  display: none;
}

.tr-customer:hover {
  cursor: pointer;
  box-shadow: 0 0 10px #E7E7E7;
  .title-photo-users-list{
    position: relative;
    background: white;
  }
  .customer-actions {
    display: flex;

    span{
      cursor: pointer;
      margin-left: 6px;
    }
  }
  .Applicants_date{
    display: none;
  }
  td {
    .options {
      display: flex;
    }
  }
}

.tr-customer-info {
  position: relative;
  &:hover {
    .customer-actions {
      position: absolute;
      right: 45px;
      margin-top: 0;
      display: block;

      .icon-delete{
        padding-top: 7px;
        position: relative;
        z-index: 10;
        .icon-delete-svg{
          fill: $button-color-red;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.btn-general-message-dp-customer {
  float: left;
  color: #b3b3b3;
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
  text-align:left;
  padding: 5px 3px;
  height: 31px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  width: 160px;

  span {
    font-size: 12px;
    color: #6d6e72;
    font-weight: $font-weight-regular;
    margin-left:5px;
  }

  i {
    color: #787878;
    float:right;
    padding-right:2px;
    padding-top:2px;
  }
}

.header-customer-info{
  width: 100%;
  height: 70px;
  align-items: center;
  float: left;
  border-bottom: 1px solid #EFEFEF;
  position: relative;

  .customer-image{
    height: 45px;
    width: 45px;
    border-radius: 50%;
    padding: 1px;
    box-shadow: 0 0 10px #666;
    object-fit: cover;
    cursor:pointer;
  }

  .img-customer{
    cursor: pointer;
    @include item-center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #2194F1;
    padding: 0;
    float: right;
    bottom: 0;
    position: relative;
    right: 14px;
    top: 25px;
    >div{
      @include item-center;
      svg{
        height: 10px;
        width: 10px;
        fill: white;
      }
    }
  }

  .customer-name{
    padding: 0 0 0 15px;
    margin: 0;
    font-weight: $font-weight-bold;
    font-size: 21px;
    color: #787878;
  }

  .header-left{
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;

    .container-customer-image{
      padding-left: 50px;
    }
  }

  .container-edit-button-save-header{
    float: right;
    display: none;
    padding: 18px 50px 18px 0;
    .btn-save-header{
      float: right;
      width: 75px;
      margin-right: 0;
      height: 34px;
      color: #ffffff;
      border-radius: 5px;
      border-color: transparent;
    }
  }
}

@mixin customer-info(){
  background: white;
  float: left;
  width: 100%;
  padding: 20px 50px 20px 50px;
  border-bottom: 1px solid #EFEFEF;
  display: block;
  .container-title{
    display: flex;
    align-items: center;
    width: 100%;
    .label-title-customer{
      padding: 0 0 0 15px;
      margin: 0;
      font-weight: $font-weight-bold;
      color: #787878;
      font-family: $font-family;
    }
  }
  .car-header{
    display: flex;
    align-items: center;
    padding: 0 15px 0 15px;
  }

  .body-customer-info{
    padding: 15px 0;
    label{
      color:#787878 !important;
      font-size: 13px;
    }
    .flex-row{
      padding-top: 10px;

      .flex-colR1, .flex-colRB{
        padding-right: 10px;
        width: 13%;
        flex: none;
        .item{
          .form-control{
            >div{
              display: initial !important;
              position: initial !important;
            }
          }
          .display-date{
            margin: 0 0 10px;
          }
          div{
            p{
              float: left
            }
            .label-error{
              color: red;
              padding-left: 2px;
            }
          }
        }
      }

      .flex-colR20{
        padding-right: 10px;
        width: 20%;
        flex: none;
      }

      .container-icon-calendar{
        padding-top: 40px;
        .calendar-dob{
          >div{
            height: 30px;
            svg{
              width: 25px;
              height: 30px;
              fill: #A4AFB7;
            }
          }
        }
      }
    }

    .finance-input > div {
      width: auto !important;
      height: auto !important;

      > div hr{
        border: 0 !important;
      }
    }
    .col25{
      padding: 0 10px 10px 0;
      label{
        font-size: 13px;
        color: #787878;
        font-weight: $font-weight-regular;
        padding-left: 10px;
      }
      .form-control{
        border: 1px solid #ccc;
        .MuiInput-underline::before{
          border-bottom: none;
        }
        .MuiInput-underline::after{
          border-bottom: none;
        }
        #dateId{
          font-size: 14px;
          margin-top: 3px;
          margin-left: 10px;
        }
        div{
          div{
            hr{
              border-bottom: none !important;
            }
          }
        }
      }
    }

  }

  .inputs-row-2{
    width: 100%;
    display: flex;
    .flex-colR2{
      padding-right: 10px;
      width: 26%;
      flex:none;
    }

    .flex-colR3{
      padding-right: 10px;
      width: 13%;
      flex: none;
      .item{
        .form-control{
          >div{
            display: initial !important;
            position: initial !important;
          }
        }
        .display-date{
          margin: 0 0 10px;
        }
        div{
          p{
            float: left
          }
          .label-error{
            color: red;
            padding-left: 2px;
          }
        }
      }
    }
  }

  .container-edit-button-save{
    padding-top: 0;
    width: 48%;
    float: right;
    .btn-save{
      float: right;
      width: 75px;
      margin-right: 0;
      height: 34px;
      color: #ffffff;
      border-radius: 5px;
      border-color: transparent;
      font-weight: $font-weight-regular;
      font-family: $font-family;
    }
  }

  input{
    color: #2194F1 !important;
  }
}

.customer-info, .customer-finance-applications, .customer-cars{
  @include customer-info;
}

.customer-cars{
  padding: 20px 40px 20px 40px !important;
  .container-title{
    padding-left: 10px !important;
  }
  .container-cars{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .container-car{
      border-radius: 5px;
      width: 24%;
      margin: 0.5%;
      border: 1px solid #EFEFEF;
      background-color: white;
      .head-customer-car {
        height: 55px;;
        background-color: white;
        padding: 10px;
        border-radius: 5px 5px 0 0;

        label {
          color: #787878;
          margin-bottom: 0;
        }

        .content-left {
          width: 78%;
          float: left;

          label {
            font-weight: 100;
            width: 100%;
          }

          .make-model-year {
            font-family: $font-family;
            font-size: 18px;
          }

          .sale-in-process {
            font-family: $font-family;
            color: #F3A200;
            font-size: 13px;
            top: -5px;
            position: relative;
          }

          .soled {
            font-family: $font-family;
            color: black;
            font-size: 13px;
          }
        }

        .content-right {
          width: 22%;
          float: right;

          label {
            font-family: $font-family;
            font-size: 18px;
            float: right;
            color: #4ad991;
            font-weight: $font-weight-light;
          }

          .zero-price {
            color: black;
          }
        }
      }

      .container-car-image {
        border-radius: 0 0 5px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #D7DAE2;
        cursor: pointer;

        img {
          width: 100%;
          border-radius: 0 0 5px 5px;
          height: 100%;
          object-fit: cover;

          &:hover {
            cursor: pointer;
          }
        }

        .icon-car-image{
          width: 100%;
          cursor: pointer;
          .icon-car-image-svg{
            width: 50%;
            height: 50%;
            .a{
              fill: #9da4a4;
              stroke: none;
            }
          }
        }
      }
    }

    .container-new-sale{
      background-color: #f5f6fa;
      @include item-center;
      button{
        height: 42px;
        border-radius: 5px;
        background-color: #2194f1;
        border: none;
      }
    }

  }

  .container-message-info {
    padding: 10px;
    @include col100;
    .message-info{
      width: auto;
      float: left;
    }

    .btn-new-sale{
      float: left;
      padding: 0 15px;
      button{
        height: 42px;
        border-radius: 5px;
        background-color: #2194f1;
        border: none;
        font-family: $font-family;
        font-weight: $font-weight-regular;
      }
    }
  }
}

.customer-finance-applications{
  padding: 20px 0 20px 0 !important;
  .rowUser{
    cursor:pointer !important;
  }
  .container-title{
    padding-left: 50px;
  }
  .title-forms-list{
    .title-finance{
      padding-left: 100px;
      width: 30%;
    }
    .title-date{
      width: 20%;
    }
    .title-status{
      width: 20%;
    }
    .title-assigned{
      width: 30%;
    }
  }

  .body-finance{
    padding-left: 100px;
  }

  .container-message-info {
    padding: 0 50px;
  }

}

.customer-info-new{
  @include customer-info;

  .title{
    font-size: 22px;
    color: #787878;
    font-weight: 500;
  }
  .body-customer-info{
    float: left;
    /* display: flex; */
    width: calc(100% - 90px);
    padding: 0 0 0 30px;
    position: relative;
    .flex-row{
      .flex-colR1, .flex-colRP{
        width: 16%;
        padding-right: 10px;
      }
      .flex-colRB{
        display: none;
      }
    }
    .inputs-row-2{
      .flex-colR2{
        width: 32%;
      }
      .flex-colR3, .flex-colRB{
        width: 16%;
        display: block;
      }
      .flex-colRB{
        padding-top: 3px;
      }
    }

    #birthDay{
      width: auto !important;
      height: auto !important;
    }

    .finance-input > div {
      width: auto !important;
      height: auto !important;

      > div hr{
        border: 0 !important;
      }
    }

    .label-value{
      margin: 0 0 3px 0;
      float: left;
    }

    .label-error{
      color: red;
      padding-left: 2px;
    }
  }

  .image-customer-info{
    float: left;
    padding-top: 10px;
    .img-customer{
      background-color: #2194f1;
      border-radius: 50%;
      width: 90px;
      height: 90px;
      @include item-center;
      padding-right: 5px;
      svg{
        width: 40px;
        height: 40px;
        fill: #ffffff;
        &:hover{
          cursor: pointer;
        }
      }
    }

    .img-customer-selected{
      background-color: transparent;
      //--padding: 0;
    }
  }

}

.customer-modal-container{
  .modal-dialog{
    .modal-content{
      .img-delete{
        width: 22px;
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}

.modal-delete{
  .modal-dialog{
    .modal-content{
      .panel-right {
        .container-button-delete{
          text-align: center;
        }
      }
    }
  }
}

.customer-content{
  .container-header{
    display: flex;
    height: 56px;
    .container-list-btn{
      display: inline-flex;
    }
    .customer-button{
      padding: 10px 0 10px 15px;
    }
    .customer-title{
      padding-left: 10px;
    }
  }
}

.popover-box-modal-state{
  border: 1px solid #D7DAE2;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
  top: 34px;
  .popover-general-dp{
    max-height: 190px;
    .popover-general-dp-sub{
      max-height: 180px;
    }
  }
}

// Media queries
@media screen and (max-width: 1230px){
  .customer-info{
    .body-customer-info{
      .flex-row{
        display: flex;
        float: left;
        flex-wrap: wrap;
        width: 100%;

        .flex-colR1{
          width: 20%;
          float: left;
        }

        .flex-colR20, .flex-colRB{
          padding-top: 10px;
        }

        .flex-colR20{
          width: 25%;
        }

        .container-icon-calendar{
          padding-top: 40px;
        }

        .flex-colR2{
          width: 30%;
        }
      }
    }

    .inputs-row-2{
      .flex-colR3{
        width: 20%;
        float: left;
      }
    }
  }

  .customer-cars{

  }
}

@media screen and (max-width: 1200px){
  .customer-cars .container-cars .container-car .head-customer-car{
    .content-left{
      .make-model-year{
        font-size: 12px;
      }
      .sale-in-process{
        font-size: 10px;
      }
    }
    .content-right{
      label{
        font-size: 12px;
      }
      .soled{
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 1160px){
  .customer-info-new{
    .body-customer-info{
      .flex-row{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        float: left;
        .flex-colR1{
          width: 25%;
        }
        .flex-colRB{
          display: block;
          width: 16%;
        }
        .flex-colRP, .flex-colR20{
          padding-top: 10px;
        }
        .flex-colR20, .flex-colRP{
          width: 25%;
        }
        .flex-colRB{
          padding-top: 13px;
        }
      }
      .inputs-row-2{
        .flex-colRB{
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1140px){
  .customer-info .body-customer-info .flex-row .flex-colRB{
    width: 14%;
  }
}

@media screen and (max-width: 1080px){
  .customer-info-new {
    .body-customer-info {
      .flex-row{
        .flex-colRB{
          width: 20%;
        }
      }
    }
  }
}

@media screen and (max-width: 1060px){
  .customer-cars .container-cars .container-car .head-customer-car{
    padding: 10px 5px;
  }

  .customer-info .body-customer-info .flex-row .flex-colRB{
    width: 15%;
  }
}

@media screen and (max-width: 1024px){
  .container-component {
    .customer-content{
      .container-center{
        padding: 76px 20px 0 20px;
      }
    }
  }
  .table-customer {
    .tr-customer{
      td, .td-phone {
        font-size: 13px;
      }

      .image-user{
        width: 30px;
        height: 30px;
      }
    }

    .td-email{
      font-size: 13px;
    }

    .th-photo{
      width: 12%;
      padding-left: 30px;
    }

    .th-email, .th-user{
      width: 25%;
    }

    .th-phone{
      width: 18%;
    }

    .td-image{
      padding-left: 30px;
    }
  }

  .panel-customer {
    .row-customer-container{
      .btn-container-dp-user{
        padding: 14px 0 14px 30px;
      }
    }
  }

  .header-customer-info{
    .container-edit-button-save-header{
      display: block;
    }
  }

  .customer-info{
    .container-edit-button-save{
      display: none;
    }
  }

  .customer-cars .container-cars .container-car {
    width: 32%;
    .head-customer-car{
      padding: 10px;
    }
  }

  .customer-finance-applications .title-forms-list{
    .title-finance{
      width: 40%;
    }
    .title-date, .title-status{
      width: 15%;
    }
  }
}

@media screen and (max-width: 1010px) {
  .customer-info .body-customer-info .flex-row .flex-colRB{
    width: 16%;
  }
}

@media screen and (max-width: 980px) {
  .customer-info .body-customer-info .flex-row .flex-colRB{
    width: 18%;
  }
}

@media screen and (max-width: 960px) {
  .customer-info .body-customer-info .flex-row .flex-colR20{
    width: 30%;
  }
}

@media screen and (max-width: 940px) {
  .customer-info-new {
    .body-customer-info {
      .flex-row{
        .flex-colRB{
          width: 23%;
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .customer-info{
    padding: 20px 10px ;
    .body-customer-info .flex-row .flex-colRB{
      width: 20%;
    }
  }

  .customer-finance-applications{
    .container-title{
      padding-left: 10px;
    }
    .title-forms-list .title-finance{
      padding-left: 20px;
    }
    .body-finance{
      padding-left: 20px;
    }
  }
  .customer-cars{
    padding: 20px 0 !important;
  }

  .header-customer-info .header-left .container-customer-image{
    padding-left: 10px;
  }

  .header-customer-info .container-edit-button-save-header{
    padding-right: 10px;
  }

  .customer-info-new {
    padding: 20px;
  }
}

@media screen and (max-width: 860px){
  .table-customer {
    .th-photo{
      width: 15%;
    }
  }
}

@media screen and (max-width: 820px){
  .customer-info-new {
    padding: 20px 10px;
    .body-customer-info{
      padding: 0 0 0 10px;
    }
  }
}

@media screen and (max-width: 800px){
  .table-customer {
    .tr-customer{
      td, .td-phone {
        font-size: 12px;
      }
    }

    .th-photo, .th-user, .th-email, .th-phone{
      font-size: 13px;
    }
  }

  .customer-info .body-customer-info .flex-row .flex-colR1{
    padding-right: 3px;
  }
}

@media screen and (max-width: 768px) {

  .container-component {
    .customer-content{
      .container-center{
        padding: 66px 10px 0 10px;
      }
      .container-header{
        height: 40px;
        padding-bottom: 0;
        .arrow-back{
          padding-left: 20px;
          padding-top: 6px;
        }
        .customer-title{
          padding-bottom: 0 !important;
          font-size: 3vw;
          .arrow-right{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 186px;
          }
        }
        .container-button-save{
          right: 20px;
        }
        .customer-pagination{
          display: none;
        }
      }
    }
  }

  .modal-delete{
    .modal-dialog{
      height: 400px;
      .modal-content{
        .modal-body{
          .panel-left{
            border-radius: 10px 10px 0 0;
            height: 200px;
            min-height: unset;
          }
          .panel-right{
            height: 200px;
            min-height: unset;
            padding: 50px 0;
            border-radius: 0 0 10px 10px;
            .container-button-delete{
              padding-top: 30px;
            }
            .icon-close-content{
              display: block;
              top: -216px;
            }
          }
        }
      }
    }
  }

  .table-customer {
    min-height: 540px;
    .tr-customer {
      .td-phone {
        display: none;
      }

      td {
        font-size: 15px;
        padding: 4px 0;
      }
      td:first-child{
        padding-left: 20px;
      }

      .img-view, .img-delete {
        width: 20px;
      }
    }

    .th-email, .th-phone, .td-email {
      display: none;
    }

    .th-photo, .td-image {
      padding-left: 20px;
    }

    .th-photo, .th-user {
      font-size: 15px;
    }

    .th-user {
      width: 40%;
    }

    .th-photo {
      width: 20%;
    }

    .td-actions{
      height: 48px;
    }
  }

  .panel-customer {
    .row-customer-container {
      .btn-container-dp-user {
        padding: 14px 0 14px 40px;
      }
    }
    .container-pagination{
      @include item-center;
      width: calc(100% - 20px);
      float: left;
      bottom: 15px;
      .pagination-left{
        display: none;
      }
      .pagination-right{
        padding-left: 0;
      }
    }
  }

  .customer-info {
    .body-customer-info .flex-row {
      padding-top: 0;

      .flex-colR1, .flex-colR20 {
        width: 50%;
        padding-left: 10px;
        padding-top: 10px;
      }

      .flex-colR20 {
        padding: 10px 3px 0 10px;
      }

      .flex-colRB {
        padding: 10px 10px 0 10px;
        width: 45%;
      }

      .flex-colR2 {
        width: 50%;
        padding-left: 10px;
      }
    }

    .body-customer-info{
      form{
        .col25{
          width: 50%;
          min-height: 72px;
        }
      }
    }

    .inputs-row-2 {
      padding-top: 10px;
      flex-wrap: wrap;

      .flex-colR3 {
        width: 50%;
        padding-right: 3px;
      }
    }
  }

  .customer-info-new{
    .title-container{
      padding: 15px 0 0 0;
      display: inline-flex;
    }
    .body-customer-info{
      form{
        .col25{
          width: 50%;
          min-height: 72px;
        }
      }
    }
  }

  .customer-cars {
    border-radius: 10px;
    .container-title{
      padding-bottom: 10px;
    }
    .container-cars{
      padding: 10px 20px;
      .container-car{
        width: 48%;
        //margin-bottom: 10px;
        margin: 1%;
      }
    }

  }

  #titleAssigned {
    display: none;
  }

  #bodyAssignedUser {
    display: none;
  }

  .customer-finance-applications .title-forms-list .title-date, .customer-finance-applications .title-forms-list .title-status {
    width: 30%;
  }

  .header-customer-info {
    .customer-name{
      font-size: 17px;
    }
    .container-edit-button-save-header {
      position: fixed;
      top: 45px;
      right: 5px;
      z-index: 100;
      padding-top: 0;
      .btn-save-header{
        height: 30px;
        width: 60px;
      }
    }
  }

  .setting-container{
    .container-title{
      .toggle-circle{
        width: 25px;
        height: 25px;
      }
      h5{
        font-size: 15px;
      }
    }
  }

  .body-setting-customer, .customer-content .container-center {
    padding: 70px 20px 0 20px;
  }

  .customer-info-new{
    .title{
      font-size: 18px;
      padding-left: 25px;
    }
    .image-customer-info{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .img-customer{
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }
    }
    .body-customer-info{
      padding: 0 0 0 10px;
      width: 100%;
      .flex-row{
        .flex-colR1{
          width: 50%;
          padding-top: 10px;
        }
        .flex-colRP, .flex-colR20{
          width: 50%;
        }
        .flex-colRB{
          display: none;
        }

        .inputs-row-2{
          flex-wrap: wrap;
          .flex-colRB{
            display: block;
            width: 50%;
            padding-top: 0;
          }
          .flex-colR2{
            width: 50%;
            padding-top: 0;
          }
          .flex-colR3{
            width: 50%;
            padding-top: 10px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 700px) {
  .customer-cars .container-message-info .btn-new-sale{
    padding: 10px 0;
    width: 100%;
  }
}

@media screen and (max-width: 600px){
  .table-customer {
    .th-photo, .td-image{
      padding-left: 30px;
    }
  }

  .panel-customer {
    .row-customer-container{
      .btn-container-dp-user{
        padding: 14px 0 14px 30px;
      }
    }
  }

  .header-customer-info .container-edit-button-save-header{
    right: 10px;
  }

  .body-setting-customer, .customer-content .container-center{
    padding: 65px 10px 0 10px;
  }

  .container-component{
    .customer-content{
      .container-header{
        .arrow-back{
          padding-left: 10px;
        }
        .container-button-save{
          right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .message-info label{
    font-size: 12px;
  }
}

@media screen and (max-width: 540px){
  .btn-general-message-dp-customer{
    width: 100px;
  }
}

@media screen and (max-width: 500px) {
  .table-customer{
    .th-user {
      width: 50%;
    }

    .th-photo, .td-image{
      padding-left: 20px;
    }
  }

  .panel-customer {
    .row-customer-container{
      .btn-container-dp-user{
        padding: 14px 0 14px 20px;
      }

      .customer-pagination{
        padding: 14px 20px 14px 0;
      }
    }
  }

  .form-control{
    font-size: 12px;
  }

  .customer-finance-applications .title-forms-list{
    .title-finance {
      width: 50%;
      font-size: 13px;
    }
    .title-forms-list .title-status{
      width: 25%;
      font-size: 13px;
    }
  }

  .tbody-user .rowUser td{
    font-size: 12px;
  }

  .customer-cars .container-cars .container-car{
    width: 99%;
  }
  .customer-info-new .image-customer-info .img-customer{
    width: 120px;
    height: 120px;
  }

  .customer-content{
    .body-setting-customer{
      .setting-container{
        .customer-info{
          .body-customer-info{
            form{
              .panel-body{
                .col-sec-sub{
                  .col100{
                    .col25{
                      padding-right: 0;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
        .customer-info-new{
          .body-customer-info{
            form{
              .col25{
                width: 100%;
                min-height: 72px;
              }
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 480px) {
  .customer-content {
    .container-center {
      padding: 90px 20px 0 20px;
    }
  }

  .table-customer{
    .tr-customer{
      .customer-actions{
        right: 40px;
      }

      td{
        font-size: 12px;
      }
    }

    .th-photo, .th-user{
      font-size: 13px;
    }
  }

  .header-customer-info{
    .header-left{
      padding: 15px 0;
    }
    .customer-image{
      width: 30px;
      height: 30px;
    }
    .img-customer{
      width: 15px;
      height: 15px;
    }
  }

  .arrow-back{
    svg{
      width: 14px;
    }
  }
  .customer-content .container-header .customer-title {
    font-size: 3vw;
  }
  .customer-title{
    padding-bottom: 0;
  }
}

@media screen and (max-width: 400px) {
  .btn-general-message-dp-customer{
    width: 80px;
  }

  .customer-pagination .dp-pagination-container .btn-page-group{
    padding: 5px 5px;
  }

  .customer-pagination .dp-pagination-container .pagination-right{
    padding-left: 5px;
  }

  .customer-pagination .dp-pagination-container .btn-back, .customer-pagination .dp-pagination-container .btn-next,  .customer-pagination .dp-pagination-container .btn-center{
    padding: 7px 5px;
  }
}

@media screen and (max-width: 360px) {
  .table-customer{
    .th-photo{
      width: 25%;
    }
  }
}

@media screen and (max-width: 340px) {
  .customer-content {
    .container-center {
      padding: 90px 10px 0 10px;
    }
  }

  .panel-customer {
    .row-customer-container{
      .btn-container-dp-user{
        padding: 14px 0 14px 10px;
      }

      .customer-pagination{
        padding: 14px 10px 14px 0;
      }
    }
  }

  .table-customer{
    .th-photo, .td-image{
      padding-left: 10px;
    }
  }
}