
.container-message-info{
  margin-bottom: 6px;
  display: flex;
  align-items: flex-start;
  font-size: 13px;

  .icon-class{
    fill: #ffca83;
    margin-right: 4px;
    border: 1px solid #FFF4E5;
    border-radius: 50%;

    svg{
      width: 15px;
      display: flex;
      height: auto;
    }
  }

  .second-message{
    color: #A4AFB7;
    font-size: 10px;
  }

}