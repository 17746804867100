@import "src/common/styles/utils";
.modal-content{
  box-shadow: 0px 2px 10px #00000027;
}

.section-box{
  position: relative;
  background-color:#F5F6FA;
  border-radius: 4px;

  .section-right{
    background-color: #FFF;
    border-radius: 4px;

    .container-floating{
      position: absolute;
      top: 40px;
      left: 30px;

      .container-title-coming-soon{
        .title-coming-soon{
          font-size: 20px;
          font-weight: 700;
        }
      }

      .container-icon-sub-title{
        .container-sub-title{
          .sub-title-coming-soon{
            font-size: 13px;
            font-weight: 300;
          }
        }
      }

      .container-link-text{
        .title-link-text{
          color: #2194F1;
          cursor: pointer;
        }
      }
    }

    .image-container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-top: 70px;

      .no-reviews-yet{
        display: flex;
        justify-content: center;

        img{
          width: 50%;
        }
      }

      .container-gif-description{
        display: flex;
        align-items: center;
        flex-direction:column;

        .title-gif-description{
          color: black;
          font-size: 20px;
          font-weight: 700;
        }

        .text-gif-description{
          color: black;
          font-size: 13px;
          font-weight: 300;
          text-align: center;
        }

        .buttons-container{
          display: flex;
          padding-top: 10px;

          .button-icon-first{
            margin-right: 10px;
            display: flex;
            justify-content: space-between;

          }
        }
      }
    }
  }
}

.modal-reviews-container{
  @include item-center;
  display: flex !important;
  background: #A4AFB74A 0% 0% no-repeat padding-box;
  .modal-dialog {
    width: 550px;
    height: auto;

    .modal-content{
      border: none;
    }

    .modal-body {
      padding: 0;
      .body-container-reviews {
        display: flex;
        flex-direction: column;
        height: 100%;

        .first-row-reviews{
          display: flex;
          justify-content: space-between;
          align-content: center;

          .first-column-reviews{
            width: 52%;
            display: flex;
            flex-direction: column;
            padding: 15px 20px;

            .title{
              font-size: 18px;
              text-align: left;
              font-weight: bold;
              color: #454545;
              padding-bottom: 15px;
            }

            .description{
              font-size: 13px;
              font-weight: 300;
              color: #454545;
              text-align: left;
            }

            ol{
              padding-left: 20px;
              margin-bottom: 0;
              padding-bottom: 20px;
              li{
                padding: 10px 0;
                font-size: 13px;
                font-weight: 300;
                color: #454545;
                text-align: left;
              }
            }

            .button-icon{
              span{
                font-size: 13px;
              }
            }
          }
          .second-column-reviews{
            display: flex;
            width: 40%;
            align-items: center;
            justify-content: center;
            padding-right: 20px;

            img{
              width: 100%;
              height: auto;
            }
          }
        }
        .second-row-reviews{
          display: flex;
          background-color: #F3A200;
          border-radius: 5px;
          margin: 5px 5px;

          .alert-icon{
            padding: 10px 10px;
            align-items: center;
            justify-content: center;
            display: flex;
            width: auto;

            svg{
              fill: #ffca83;
              margin-right: 4px;
              border: 4px solid #FFF4E5;
              border-radius: 50%;
            }
          }
          .alert-description{
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #FFFFFF;
            font-weight: 300;
            width: 75%;
          }
        }
      }
    }
  }
}
.close-modal-icon{
  width: 32px;
  position: absolute;
  top: -15px;
  right: -13px;
  cursor: pointer;
}