@import "../../../common/styles/constants";

.navigation-header {
  float: left;
  width: 70%;
  display: flex;
  background-color: #fff;
  height: 56px;
  .header-wrapper{
    display: flex;
    width: 100%;
    .header-title{
      font: normal normal bold 21px/30px Open Sans;
      float: left;
      top: 13px;
      color: #787878;
      padding: 14px 0 0 32px;
      flex-grow: 0.2;
    }
   .tabs-container{
      flex-grow: 2;
      display: flex;
      .links{
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: 60px;
        text-align: left;
        display: flex;
        height: 60px;
        .link{
          cursor: pointer;
          font-family: "Open Sans", sans-serif;
          font-size: 15px;
          display: inline;
          margin-right: 30px;
          font-weight: 400;
        }
        .name-tab{
          color: #A4AFB7;
        }
        .selected-tab{
          color: #2194F1;
          border-bottom: 3px solid #2194F1;
        }
      }
    }
  }
}

.menu-user{
  margin-top: 5px;
}
.title-dashboard {
   width: 100%;
   float: left;
   display: flex;
   align-items: center;
   background-color: #fff;
   height: 100%;
   padding-left: 30px;

   h2 {
     float: left;
     font-size: 21px;
     color: #787878;
     font-weight: $font-weight-bold;
     width: 95px;
     margin: 0;
     font-family: $font-family;
   }

   .title-text-info {
     width: auto;
   }
 }
