@import "../../../../src/common/styles/constants";


.dp-page-flagsEdit {
  .dp-table-container {
    table {
      tbody {
        tr {
          td {
            .icon-delete{
              .icon-delete-svg{
                fill: $icon-arrow-color;
                width: 16px;
                height: 16px;

                &:hover {
                  fill: $button-color-red;
                }
              }
            }
          }
        }
      }
    }
  }

  .add-dealer-container{
    padding: 0px 22px 22px 22px;
    display: flex;

    .control-add-new-dealerId {
      margin-right: 15px;
      label {
        font-size: 13px;
        color: #787878;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
      }
    }

    .btn-add-dealer-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .btn-add-dealer {
        height: 36px;
        width: 100px;
        border-radius: 5px;
        background-color: #2194F1;
        border: 1px solid #D7DAE2;
        color: #ffffff;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 13px;

        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}