@import "src/common/styles/constants";

.container-message-info {
  padding: 0 50px;

  .message-info {
    border-radius: 10px;
    width: 100%;
    display: flex;
    padding: 10px;

    label {
      margin-left: 10px;
      color: #787878;
      margin-bottom: 0;
      font-weight: $font-weight-bold;
    }

    .icon-info {
      width: 20px;
      height: 20px;
    }
  }

  .warning {
    border: 1px solid #ffe37e;
  }
}