@import "src/common/styles/utils";

.modal-backdrop.in{
  opacity: 0;
}
.container-modal{
  display: flex !important;
  @include item-center;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  visibility: unset;
  opacity: unset;
  .modal-dialog{
    width: 36%;
    height: auto;

    .modal-content{
      border-radius: 0;
      border: 1px solid transparent;
      .modal-body{
        padding: 0;
        @include item-center;
      }
    }
  }
}

.container-body{
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Montserrat !important;
  padding: 40px 45px;
  .container-logo{
    margin-top: 10px;
    text-align: center;
  }
  .title{
    text-align: center;
    font-size: 44px;
    font-weight: bold;
    line-height: 1;
    padding-top: 30px;
    padding-bottom: 40px;
    color: #292757;
  }
  .form{
    height: 100%;
    width: 100%;
    .form-first-row{
      display: flex;
      width: 100%;
      justify-content: space-between;
      .form-first-row-element, .form-first-row-second-element{
        display: flex;
        width: 49%;
        flex-direction: column;
      }
    }
    .title-input-get-in{
      font-weight: 600;
      line-height: 1;
      color: #292757;
      margin-bottom: 5px;
      font-size: 12px;
    }
    .input-message{
      margin-top: 10px;
    }
    input{
      background: #FFFFFF 0 0 no-repeat padding-box;
      border: 0.2px solid #D9D9D9;
      border-radius: 1px;
      font-family: Montserrat !important;
    }
    .form-control:focus{
      box-shadow: none;
      border-color: #66afe9;
    }
    .btn-send-content{
      text-align: center;
      margin-top: 15px;
      .btn-send{
        cursor: pointer;
        color: #FFFFFF;
        border: none;
        border-radius: 1px;
        width: 100%;
        background-color: #057FFA;
        font-weight: 600;
        padding: 12px;
        font-size: 16px;
        white-space: normal;
        word-break: break-word;
        height: auto;
        text-decoration: none;
      }
    }
    .info-row{
      text-align: center;
      margin-top: 25px;
      .phone, .email{
        color: #4D4F5C;
      }
    }
  }
}

.content-center{
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}