.reviews {
  .intro-banner {

    .section-image {
      width: 500px;
      margin-top: 0;
    }

    @media only screen and (max-width: 1200px) {
      .section-image {
        width: 440px;
      }
    }

    @media only screen and (max-width: 1024px) {
      .section-image {
        width: 45vw;
      }
    }

    @media only screen and (max-width: 768px) {
      .section-image {
        width: 70vw;
      }
    }

    @media only screen and (max-width: 620px) {
      .section-image {
        width: 80vw;
      }
    }

    @media only screen and (max-width: 520px) {
      .section-image {
        width: 90vw;
      }
    }

    @media only screen and (max-width: 460px) {
      .section-image {
        width: 95vw;
      }
    }

    @media only screen and (max-width: 400px) {
      .section-image {
        width: 100vw;
      }
    }

  }

  .reviews-track{
    position: relative;
    .floating-section{
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 0;
    }
    .section-content{
      z-index: 1;
    }
  }
}

