.smart-integrations {
  .regular-font{
    font-size: 16px !important;
  }
  .integration-icons {
    display: flex;
    padding-top: 100px;
    align-items: center;

    .google-icon {
      height: 74px;
      padding: 0 15px 0 15px;
    }

    .facebook-icon {
      height: 98px;
      padding: 0 15px 0 15px;
    }

    .instagram-icon {
      height: 74px;
      padding: 0 15px 0 15px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .integration-icons {
      .google-icon {
        height: 53px;
        padding: 0 10px 0 10px;
      }

      .facebook-icon {
        height: 78px;
        padding: 0 10px 0 10px;
      }

      .instagram-icon {
        height: 53px;
        padding: 0 10px 0 10px;
      }
    }
  }

  @media only screen and (max-width: 768px) {

    .integration-icons {
      flex-direction: column;
      padding-top: 30px;

      .google-icon {
        height: unset;
        width: 200px;
        padding: 20px 0 20px 0;
      }

      .facebook-icon {
        height: unset;
        width: 350px;
        padding: 20px 0 20px 0;
      }

      .instagram-icon {
        height: unset;
        width: 200px;
        padding: 20px 0 20px 0;
      }
    }
  }
}
