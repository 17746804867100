@import "src/common/styles/utils";
.container-select-toggle{
  .navigation-box{
    width: calc(100% - 63px);
    float: left;
  }
  .sec-switch{
    height: 32px;
    float: right;
    @include item-center;
    label{
      margin-bottom: 0;
    }
  }
}