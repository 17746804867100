@import "utils";

$main-font-color: #4D4F5C;
$main-blue-color-btn: #2194F1;
$marketing-background-color: #F5F6FA;
$blue-subtitle: #0C2E68;
$input-border-color: #D9D9D9;
$font-title-part: #3B86FF;
$box-shadow:#D7DAE276;
$form-label-color: #787878;
$second-font-color: #43425D;
$font-weight-bold: 700;
$background-color: #F5F6FA;

html {
  scroll-behavior: smooth;
}

.marketing-form, .payment-method-form {
  position: relative;
  label {
    font-size: 10px;
    color: $form-label-color;
  }

  input {
    width: 100%;
    border-radius: 5px;
    height: 36px;
    border: 1px solid $input-border-color;
    color: $main-font-color !important;
    padding: 0 10px;
  }

  button {
    border: none;
    float: right;
    margin-top: 16px;
  }

  .floating-message{
    position: absolute;
    top: 16px;
    color: #4ad991;
  }
}

.item-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-bar {
  height: 50px;
  width: 100%;
  position: sticky;
  top: -1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  clear: both;
  background-color: $marketing-background-color;
  #toggle-button{
    display: none;
  }
}

.is-sticky {
  background-color: #fff;
}

.navigation-items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;

  li {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;

    a{
      color: $main-font-color;
      text-decoration: none;
      width: 100%;
      box-sizing: border-box;
      display: block;
    }
  }
}

.market-btn {
  color: #fff;
  background-color: $main-blue-color-btn;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
  width: 148px;
  box-shadow: 0 3px 22px $box-shadow;
  border: none;
}

.market-btn-previous {
  color: $main-blue-color-btn;
  background-color: #fff;
  padding: 8px 30px;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 4px cadetblue;
}

.market-btn-pay {
  color: #fff;
  background-color: #4ad991;
  padding: 8px 30px;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 4px cadetblue;
}

.logo {
  width: 150px;
}


//Start****ToolSection****//
.container-first-section{
  padding: 70px 0 0 0;
  background-color: $background-color;
  .container-title{
    text-align: center;
    padding: 0 40px;
    font-weight: $font-weight-bold;
    span{
      font-size: 45px;
      color: #43425D;
    }
  }
  .container-icons{
    @include item-center;
    padding: 50px 0;
    margin-bottom: 40px;
    .container-icon-title{
      width: 170px;
    }
    .container-icon{
      padding: 20px 10px;
      .icon-arrow-content{
        height: 96px;
        position: relative;
        width: 150px;
        @include item-center;
        div{
          display: flex;
          position: absolute;
          bottom: 0;
        }
      }
      .title-icon{
        width: 100%;
        float: left;
        text-align: center;
        font-size: 18px;
        color: #43425D;
        padding-top: 10px;
      }
    }
    .container-separator{
      padding: 0 20px;
      @include item-center;
      .icon-expand-more{
        transform: rotate(90deg);
        height: 100%;
        svg{
          width: 14px;
          fill: #0C2E68;
        }
      }
    }
  }
  .container-background{
    width: 100%;
    height: 250px;
    .container-image{
      width: 100%;
      height: 100%;
      .image-screen{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .container-symbol-dealer{
    .icon-symbol-content{
      z-index: 1;
      position: absolute;
      top: 600px;
      left: -170px;
      width: 380px;
      svg{
        height: 100%;
        width: 100%;
      }
    }
  }
}

.label-form {
  font-size: 10px;
  color: #787878;
}

.input-form {
  width: 100%;
  border-radius: 5px;
  height: 36px;
  border: 1px solid $input-border-color;
  color: $main-font-color !important;
  padding: 0 10px;
  outline: none;
}

/****** PAYMENT *****/

.container-payment{
  width: 100%;
  display: flex;
  padding: 35px 100px 35px 100px;
  height: calc(100vh - 80px);
  background-color: #F5F6FA;
  position: relative;
  overflow-y: hidden;
  justify-content: center;
  .container-wizard{
    width: calc(100% - 230px);
    margin-right: 15px;
    background: #FFFFFF;
    border-radius: 10px;
    height: 530px;
    float: left;
    .create-account, .about-dealer, .payment-method, .summary, .welcome{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      position: relative;
      .left-container{
        width: 45%;
        // padding: 110px;
        background: #3B86FF;
        border-radius: 10px;
        text-align: center;
        height: 100%;
        position: relative;
        float: left;
        .container-img{
          display: flex;
          justify-content: center;
          vertical-align: middle;
          height: 100%;
          img{
            width: 10vw;
            margin: auto;
            display: block;
          }
        }
      }
      .right-container{
        width: 55%;
        padding: 20px 40px 60px 40px;
        position: relative;
        height: 100%;
        .title{
          color: #3B86FF;
          font-size: 25px;
          font-weight: 700;
        }
        .row-input, .row-input-one-column{
          display: inline-flex;
          width: 100%;
          margin-bottom: 15px;
          div:first-child {
            margin-right: 10px;
          }
        }
        .row-input-one-column{
          div:first-child {
            width: inherit;
            margin-right: 0;
          }
        }
        .container-btn-next, .container-btn-back{
          position: absolute;
          bottom: 30px;
          right: 40px;
        }
        .container-btn-back{
          left: 40px;
          right: 0;
        }
      }
    }
    .payment-method, .about-dealer {
      .container-img-card{
        padding: 4px;
        border: 1px solid #D9D9D9;
        width: 130px;
        margin-bottom: 15px;
        border-radius: 5px;
        justify-content: center;
        vertical-align: middle;
        height: 100%;
        img{
          width: 100%;
        }
      }
      .row-input-grid{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1.4fr 1fr 1.2fr ;
        background-color: #fff;
        color: #444;
        margin-bottom: 15px;
      }
      .container-img{
        img{
          width: 70% !important;
        }
      }
    }
    .about-dealer{
      .container-img{
        img{
          width: 60% !important;
        }
      }
      .row-input-grid{
        grid-template-columns: 2.36fr 1fr 1fr ;
      }
    }
    .summary{
      .container-img{
        img{
          width: 15vw !important;
        }
      }
      .right-container{
        padding: 25px 25px 40px 25px;
      }
      .row-grid-two{
        display: grid;
        grid-gap: 7px;
        grid-template-columns: 0.9fr 3fr ;
        border: 1px solid #D9D9D9;
        margin-bottom: 12px;
        .left-info{
          border-right: 1px solid #D9D9D9;
          text-align: center;
          position: relative;
          display: flex;
          flex-direction: column;
          padding: 10px 15px 12px 20px;
          .membership{
            font-size: 20px;
            font-weight: 700;
            margin: 0 10px 0 0;
            text-align: center;
          }
          a{
            float: left;
            font-size: 11px;
            cursor: pointer;
          }
          label{
            font-size: 25px;
            margin: 0;
          }
          img{
            width: 1.8vw;
          }
          .edit{
            bottom: 8px;
            cursor: pointer;
            color: #3B86FF;
            margin-top: 10px;
          }
        }
        .dealer-info, .payment-info {
          img{
            margin: 0 auto;
            width: 3vw;
          }
        }
        .right-info{
          padding: 10px 5px 5px 5px;
          font-size: 12px;
          span{
            display: flex;
          }
          .flex-info{
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            margin-bottom: 5px;
            .users, .cars{
              flex-grow: 0.55;
            }
            .assistance, .tasks{
              white-space: nowrap;
            }
          }
          .bolder{
            font-size: 12px;
            font-weight: 700;
            color: #4D4F5C;
            margin-right: 2px;
          }
          .title{
            font-size: 14px;
            font-weight: 700;
            margin: 3px 0;
            color: #4D4F5C;
          }
        }
      }
    }
    .welcome{
      .left-container {
        width: 40%;
        .container-img img {
          width: 45%;
        }
      }
      .right-container{
        width: 60%;
        padding: 35px 30px 50px 40px;
      }
      .title{
        margin: 0;
      }
      .text{
        color: #666666;
        font-size: 14px;
        margin-top: 20px;
        span{
          color: #3FA9F5;
        }
      }
    }
  }
  .success-payment{
    padding: 30px 110px;
    justify-content: center;
  }
  .summary-membership{
    width: 230px;
    padding: 18px 20px;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: inline-table;
    .title, .price-membership, .total-membership{
      border-bottom: 1px solid #e1dbdb;
      padding-bottom: 10px;
      margin-bottom: 15px;
      font-size: 16px;
      .name{
        font-weight: bolder;
        font-size: 22px;
      }
      .saving{
        color: #4AD991;
        font-size: 14px;
      }
    }

    .price-membership, .total-membership{
      color: #4D4F5C;
      font-size: 13px;
      margin-bottom: 8px;
      .flex-price {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        span {
          white-space: nowrap;
        }
        .calculate-price, .taxes-label, .total-label {
          flex-grow: 1;
        }
      }
    }

    .total-membership{
      padding-bottom: 15px;
    }

    .benefit-membership{
      color: #4D4F5C;
      font-size: 14px;
      span{
        font-weight: bolder;
        font-size: 15px;
      }
      p{
        margin: 0;
      }
      .upgrade{
        font-size: 13px;
        cursor: pointer;
        color: #3B86FF;
      }
      .container-btn-pay{
        position: absolute;
        margin-left: -50px;
        left: 50%;
        bottom: 30px;
      }
    }
  }
}

/*************/

/*****  UNSUBSCRIBE  ********/

.header-unsubscribe {
  background-color: #FFFFFF;
  .logo{
    width: 200px !important;
  }
}
.container-unsubscribe {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  background-color: $marketing-background-color;
  .element{
    margin: auto;
    text-align: center;
    padding-bottom: 100px;
    p{
      color: #8F979D;
      margin: 3px;
    }
    .thank-you{
      color: #2194F1;
      margin: 10px 0;
      font-size: 1.4em;
      font-weight: 700;
    }
    .container-img{
      padding-bottom: 20px;
    }
    .container-btn-next{
      margin-top: 20px;
      .market-btn{
        padding: 8px 18px;
      }
      a:hover, a:focus, a:active {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
}

/*************/

.footer-contact-us {
  color: $main-font-color;
  background-color: #fff;
  padding: 25px 20px 30px;
  display: flex;
  flex-direction: column;

  .logo {
    float: left;
    width: 75px;
    height: 75px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    section {
      font-size: 15px;

      h4 {
        font-size: 18px;
        color: $blue-subtitle;
      }

      ul{
        list-style: none;
        padding: 0;

        a {
          color: $main-font-color;
          cursor: pointer;
        }
      }
    }

    #footer-contact {
      min-width: 230px;
    }

    #footer-features {
      min-width: 150px;
    }

    #footer-follow {
      min-width: 100px;
    }

    #footer-email {
      .response-message{
        color: #2194F1;
        font-weight: 700;
      }
    }
  }
}

#about-us {
  padding: 50px 21px;
  background-color: #fff;
  position: relative;

  .content {
    display: flex;
    flex-wrap: wrap;
    color: $main-font-color;

    .description {
      margin-bottom: 24px;
      font-size: 15px;

      h1 {
        font-size: 30px;
        font-weight: bold;

        span {
          color: $font-title-part;
        }
      }

      p{
        text-align: justify;
      }
    }

    form {
      background-color: #fff;
      box-shadow: 0 3px 22px $box-shadow;
      padding: 25px;
      width: 100%;
      border-radius: 10px;

      div{
        margin-bottom: 15px;
      }

      button {
        margin-top: 9px;
      }

      h3 {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.prices-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #F5F6FA;
  color: #43425D;
  padding: 45px 20px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: lighter;
  }

  h1, h2 {
    margin:5px 0;
  }

  .market-btn{
    align-self: center;
    margin: 25px auto 35px;
  }

  .option-check {
    font-size: 15px;
    font-weight: lighter;
    display: inline-flex;
    justify-content: center;
    align-self: center;
    margin-left: 35px;

    .sec-switch {
      margin: 0 15px;

      .switch {
        width: 47px;
        height: 23px;
      }

      .slider {
        background-color: #2193f1;
      }

      input:checked + .slider::before {
        transform: translateX(-24px);
      }

      .slider::before {
        height: 22px;
        width: 22px;
      }

      .slider.round.off::before {
        border: 2px #2193f1 solid;
      }
    }

    .yearly-bill-option {
      width: 40%;
      text-align: left;
    }

    .check-save-option {
      color: #4AD991;
    }
  }

  .membership-box {
    background-color: #fff;
    padding: 23px 25px 28px;
    border-radius: 10px;
    text-align: left;
    margin-top: 32px;

    .membership-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .title {
        font-size: 25px;
        font-weight: bold;
      }

      .saving {
        font-size: 15px;
        font-weight: bold;
        color: #4AD991;
      }
    }

    hr {
      margin: 10px 0;
    }

    .content {
      .price {
        display: flex;

        .dollar-icon {
          font-size: 27px;
          align-self: flex-start;
        }

        .price-amount {
          font-size: 73px;
          line-height: 67px;
        }

        .period {
          font-size: 22px;
          align-self: end;
        }
      }
      i {
        font-size: 11px;
      }
    }

    .footer {
      p {
        font-size: 15px;
        margin: 0;
      }

      .market-btn {
        margin-top: 25px;
        margin-bottom: 0;
      }
    }
  }
}

.side-drawer{
  display: none;
  width: 68% !important;
}

.nav_overlay{
  display: none;
}

@media (min-width: 1400px){

  .footer-contact-us {
    padding: 25px 120px 104px;
  }

  #about-us {
    padding: 5% 278px;

    .content {

      .description{
        align-self: center;
        margin-top: 0;

        h1 {
          font-size: 55px;
        }
      }

      .description, form {
        max-width: 536px;
        width: 30%;
      }
    }
  }

  .prices-wrapper {
    padding: 100px 120px;

    .option-check {
      margin-bottom: 60px;
    }

    .membership-container {
      justify-content: center;

      .membership-box {
        padding: 40px 40px;
        min-width: 400px;
        margin-top: 0;
        margin-right: 39px;

        .membership-header .saving {
          font-size: 25px;
        }

        .content .price {
          .dollar-icon, .period {
            font-size: 35px;
          }

          .price-amount {
            font-size: 94px;
            line-height: 84px;
          }
        }
      }
      .membership-box:nth-last-child(1){
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 1279px) {
  .prices-wrapper {

    .membership-container {
      height: 480px;
      align-items: flex-start;

      .membership-box {
        min-width: 340px;

        hr {
          margin: 15px 0;
        }
      }

      .master-membership-box, .pro-membership-box, .basic-membership-box {
        display: none;
      }

      #master:hover {
        .master-membership-box {
          display: block;
        }
      }

      #pro:hover {
        .pro-membership-box {
          display: block;
        }
      }

      #basic:hover {
        .basic-membership-box {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 1279px) and (max-width: 1399px){

  .footer-contact-us {
    padding: 25px 120px 30px;

    #footer-email {
      margin-left: 50px;
    }
  }

  .prices-wrapper {
    padding: 100px 120px;

    .membership-container {
      justify-content: space-between;
      .membership-box {
        padding: 40px 40px;
        min-width: 330px;

        .membership-header .saving {
          font-size: 25px;
        }

        .content .price {
          .dollar-icon, .period {
            font-size: 35px;
          }

          .price-amount {
            font-size: 94px;
            line-height: 84px;
          }
        }
      }
    }
  }
}
//End****ToolSection****//

//ComponentGeneral//

.container-symbol-dealer{
  display: block;
}

.container-subsection{
  display: flex;
  width: 100%;
  padding: 40px 40px 0 40px;;
  flex-flow: column;
  .container-image{
    height: auto;
    width: 100%;
    img{
      width: 100%;
    }
  }
  .container-texts{
    //height: 300px;
    padding: 20px 0 30px 0;
    .container-titles{
      padding-bottom: 10px;
      font-size: 50px;
      font-weight: $font-weight-bold;
    }
    .container-subtitle{
      span{
        font-size: 16px;
        font-weight: $font-weight-bold;
        color: $main-font-color;
      }
    }
    .container-body{
      padding-top: 20px;
      .text-simple{
        color: $main-font-color;
        font-size: 16px;
      }
      .container-text-list{
        display: flex;
        flex-direction: column;
        .container-text{
          display: flex;
          padding: 10px 0;
          .icon-previous-text{
            color: #707070;
          }
          .text{
            padding-left: 10px;
            color: $main-font-color;
            font-size: 16px;
          }
        }
      }
    }
  }
}

#leadSection{
  background-color: #FFF;

  #networkIntegration{
    padding: 90px 0 45px 0;
  }
}

#saleSection{
  .container-sale-sequence{
    display: flex;
    .container-sequence-element{
      height: auto;
      width: 33.3%;
      padding-right: 10px;
      .box-sequence-element{
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background-color: #0C2E68;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        .number-sequence{
          font-size: 35px !important;
        }
        .text-sequence{
          font-size: 1.5vw;
        }
        span{
          color: #FFF;
          font-size: 14px;
          font-weight: 600;
        }
        &:hover{
          background-color: #FFF;
          box-shadow: 0 3px 13px #00000029;
          .text-sequence{
            color: #0C2E68;
          }
          .number-sequence{
            color: #3B86FF;
          }
        }
      }
    }
  }
}
//ComponentGeneral//

//Start-SecondSection//
.second-section{
  padding: 40px 60px 0 80px;
  display: flex;
  position: relative;
  background-color: $background-color;
  .container-subsection-2{
    width: 50%;
    float: left;
    padding: 40px 40px 0 40px;
  }
}
//End-SecondSection//

#mobileTabletResponsive{
  height: 100%;
}

.left-side-menu{
  position: fixed;
  bottom: calc(50% - 85px);
  padding: 0 40px;
  left: 0;
  z-index: 2;
  display: block;
  .top-arrow, .bottom-arrow{
    .icon-expand-content{
      @include item-center;
      svg{
        width: 15px;
        fill: #E1DBDB;
      }
    }
  }
  .circle-element{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #E1DBDB;
    margin: 10px;
  }
}


@media (min-width: 1190px){
  .tool-bar .logo {
    width: 261px;
  }
}

@media (min-width: 1150px) and (max-width: 1399px){
  #about-us {
    padding: 100px 169px 100px 150px;
  }
}

@media (min-width: 1024px) and (max-width: 1149px) {
  #about-us {
    padding: 128px 38px 128px 33px;
  }
}


@media (min-width: 1024px) and (max-width: 1278px) {

  .footer-contact-us {
    padding: 25px 40px 30px;
  }

  .prices-wrapper {
    padding: 100px 40px;

    .option-check {
      margin-bottom: 20px;
    }

    .membership-container {
      justify-content: center;

      .membership-box {
        padding: 23px;
        min-width: 305px;
        margin-right: 16px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .footer-contact-us {
    .content {
      justify-content: space-between;

      #footer-email {
        flex-grow: 1;
        max-width: 600px;
        margin-left: 30px;
      }
    }
  }

  #about-us {

    .content {
      justify-content: space-around;

      .description{
        margin-top: 38px;
      }

      .description, form{
        width: 40%;
      }
    }
  }

  .prices-wrapper {
    .membership-container {
      display: flex;
      .membership-box {
        .membership-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .saving {
            font-size: 20px;
          }
        }

        .content {
          .price {
            .dollar-icon {
              font-size: 28px;
              align-self: flex-start;
            }

            .price-amount {
              font-size: 77px;
              line-height: 67px;
            }

            .period {
              font-size: 28px;
            }
          }
        }

        .footer {
          display: flex;
          flex-direction: column;
        }

        .membership-btn-wrapper .market-btn {
          margin: 25px 0 15px;
        }
      }
    }
  }
}

@media (min-width: 890px) {
  .tool-bar {
    padding: 0 100px;
  }

  .prices-wrapper {
    .membership-box {
      .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          font-size: 18px;
          b {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 700px) {
  #about-us .content{

    .description {
      font-size: 18px;

      h1 {
        font-size: 45px;
      }
    }

    form h3 {
      font-size: 25px;
    }
  }
}

@media (min-width: 700px) and (max-width: 1020px){
  .footer-contact-us{
    padding: 25px 120px 30px;
  }

  #about-us {
    padding: 98px 118px;
  }
}

@media (min-width: 500px) and (max-width: 699px){
  .footer-contact-us{
    padding: 25px 40px 30px;
  }

  #about-us {
    padding: 50px 40px;
  }
}

@media (min-width: 500px) and (max-width: 1023px) {
  .prices-wrapper {
    padding: 118px;
  }
}

@media (min-width: 500px){
  .tool-bar {
    height: 80px;
    .logo {
      float: left;
    }

    nav{
      float:right;
    }
  }

  .navigation-items {
    flex-flow: row;

    li{
      margin: 0;
      display: flex;
      height: 100%;
      width: auto;
      align-items: center;

      a{
        text-decoration: none;
        height: 100%;
        margin: 16px 15px;
        border-bottom: 4px solid transparent;
        font-size: 18px;

        .nav-icon {
          float: left;
          width: 12px;
          margin: 16px 10px;
        }
      }

      .market-btn {
        color: #fff;
        font-size: 13px;
        margin-left: 50px;
        width: 148px;
      }
    }
  }

  .footer-contact-us #footer-features {
    margin-left: 25px;
  }

  .prices-wrapper {

    h1 {
      font-size: 35px;
    }
    h2 {
      font-size: 28px;
    }

    h1, h2 {
      margin:5px 0;
    }

    .option-check {
      font-size: 18px;
      margin-left: 90px;

      .yearly-bill-option {
        width: auto;
      }


      .check-save-option {
        font-size: 15px;
        color: #4AD991;
      }
    }

    .membership-box {
      padding: 38px;
      margin-top: 37px;

      .membership-header {
        .title {
          font-size: 35px;
        }

        .saving {
          font-size: 25px;
        }
      }

      .content {
        .price {
          .dollar-icon {
            font-size: 35px;
          }

          .price-amount {
            font-size: 94px;
            line-height: 87px;
          }

          .period {
            font-size: 35px;
          }
        }
        i {
          font-size: 15px;
        }
      }

      .footer {
        p {
          font-size: 18px;
          b {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 499px) {
  .desktop-only {
    display: none;
  }
}

@media screen and (min-width: 1600px){
  .container-payment{
    padding: 50px 230px 50px 230px;
    .container-wizard {
      width: calc(100% - 300px);
      .create-account, .about-dealer, .payment-method, .summary, .welcome{
        .left-container {
          width: 450px;
          .container-img{
            img{
              width: 8vw;
            }
          }
        }
        .right-container{
          width: calc(100% - 450px);
          .row-grid-two{
            .dealer-info, .payment-info {
              img{
                margin: 0 auto;
                width: 3vw;
              }
            }
            .account-info{
              img{
                margin: 0 auto;
                width: 1.4vw;
              }
            }
          }
        }
      }
      .welcome{
        .left-container {
          .container-img {
            img{
              width: 10vw;
            }
          }
        }
        .right-container{
          padding: 40px 50px 50px 50px;
        }
      }
    }
  }
  .success-payment{
    padding: 60px 260px;
  }
}

@media screen and (max-width: 1500px){
  .container-subsection{
    .container-texts{
      .container-titles{
        font-size: 40px;
      }
    }
  }

  #saleSection{
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            min-width: 158px;
            .box-sequence-element{
              .text-sequence{
                font-size: 21px;
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 1300px){
  .container-first-section{
    .container-icons{
      .container-separator{
        padding: 40px 10px 0 10px;
      }
      .container-icon-title{
        width: 120px;
        height: 136px;
        .container-icon{
          svg{
            width: 80px;
            height: auto;
          }
          .icon-arrow-content{
            width: 100px;
          }
        }
      }
    }
  }
  .left-side-menu{
    .circle-element{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #E1DBDB;
      margin: 8px;
    }
  }

  #saleSection{
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            min-width: 146px;
            .box-sequence-element{
              .text-sequence{
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .container-subsection{
    padding: 40px 0 0 40px;
    .container-texts{
      .container-titles{
        font-size: 35px;
      }
    }
  }
  #saleSection{
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            min-width: 140px;
            .box-sequence-element{
              .text-sequence{
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px){
  #saleSection{
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            min-width: 134px;
            .box-sequence-element{
              .text-sequence{
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px){

  .container-payment {
    padding: 35px 25px 35px 25px;
    .container-wizard {
      .summary{
        .row-grid-two .left-info{
          padding: 10px 10px 15px 10px;
        }
      }
    }
  }

  .container-symbol-dealer{
    display: none;
  }

  .container-first-section{
    .container-title{
      span{
        font-size: 40px;
      }
    }
    .container-icons{
      margin-bottom: 80px;
      .container-icon-title{
        width: 120px;
        height: 136px;
        position: relative;
        .container-icon{
          padding: 20px
        }
        .container-inventory-icon, .container-sale-icon{
          position: absolute;
          bottom: -113px;
        }
        .icon-arrow-content{
          width: 80px;
        }
        .container-rows{
          @include item-center;
          padding-top: 50px;
          .icon-arrow-marketing{
            width: 30px;
            >div{
              width: 100%;
              svg{
                width: 100%;
              }
            }
          }
          .icon-rotate{
            margin-left: 20px;
            svg{
              transform: rotate(-73deg);
            }
          }
        }
      }
    }
  }

  .second-section{
    flex-direction: column;
    .container-subsection-2{
      width: 100%;
    }
  }

  .container-subsection{
    padding: 40px 0 0 0;
    .container-texts{
      .container-titles{
        font-size: 5vw;
      }
    }
  }

  .left-side-menu{
    display: none;
  }

  #saleSection{
    flex-direction: column-reverse;
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            min-width: unset;
            .box-sequence-element{
              .text-sequence{
                font-size: 2.6vw;
              }
            }
          }
        }
      }
    }
  }

  #leadSection{
    flex-direction: column-reverse;
  }

}

@media screen and (max-width: 768px){
  .container-first-section{
    .container-title{
      span{
        font-size: 35px;
      }
    }
    .container-title{
      span{
        font-size: 35px;
      }
    }
    .container-icons{
      padding: 0;
      margin-bottom: 130px;
      .container-icon-title{
        width: 100px;
        .container-rows{
          padding-top: 80px;
        }
        .container-icon{
          width: 100%;
          .icon-arrow-content{
            width: 60px;
            svg{
              width: 60px;
            }
          }
          .title-icon{
            font-size: 14px;
          }
        }
      }
    }
  }

  .container-subsection{
    padding: 0;
    .container-texts{
      .container-titles{
        font-size: 6vw;
      }
      .container-subtitle, .container-body{
        span{
          font-size: 14px !important;
        }
      }
      .container-body{
        .container-text{
          span{
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .second-section{
    padding: 40px 40px 0 40px;
    .container-subsection-2{
      padding: 0;
    }
  }

  #saleSection{
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            .box-sequence-element{
              .text-sequence{
                font-size: 3vw !important;
              }
            }
          }
        }
      }
    }
  }

  .tool-bar{
    z-index: 90;
    .toggle-button{
      cursor: pointer;
    }
    .nav_overlay{
      display: block;
      position: fixed;
      inset: 0;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      backdrop-filter: blur(12px);
      z-index: 9;
      visibility: hidden;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 650px){
  .desktop-only {
    display: none;
  }
  #toggle-button {
    display: block !important;
    cursor: pointer;
  }
  .side-drawer{
    display: block;
  }
}

@media screen and (max-width: 500px){
  .container-first-section{
    .container-title{
      padding: 0 20px;
      span{
        font-size: 25px;
      }
    }
    .container-icons{
      margin-bottom: 80px;
      .container-icon-title{
        width: 70px;
        .container-rows{
          padding-top: 52px;
          .icon-arrow-marketing{
            width: 15px;
          }
        }
        .container-icon{
          padding: 10px;
          .icon-arrow-content{
            width: 50px;
            svg{
              width: 50px;
            }
          }
          .title-icon{
            font-size: 12px;
          }
        }
        .container-inventory-icon, .container-sale-icon{
          bottom: -50px;
        }
      }
    }
  }

  .second-section{
    padding: 40px 20px 0 20px;
  }

  #saleSection{
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            .box-sequence-element{
              .text-sequence{
                font-size: 3.5vw !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px){
  .container-subsection{
    .container-texts{
      .container-titles{
        font-size: 6vw;
      }
      .container-subtitle, .container-body{
        span{
          font-size: 12px;
        }
      }
      .container-body{
        .container-text{
          span{
            font-size: 12px !important;
          }
        }
      }

    }
  }

  #saleSection{
    .container-texts{
      .container-subtitle{
        .container-sale-sequence{
          .container-sequence-element{
            .box-sequence-element{
              padding: 10px;
              .text-sequence{
                font-size: 4vw !important;
              }
            }
          }
        }
      }
    }
  }

}

.privacy-policy-wrapper {
  margin: 20px;
  table, th, td {
    border: 1px solid black;
  }
}

//todo edit or delete provisional styles for authorize form
.test-payment-form {
  margin: 20px 70px;
  width: 300px;
  @include border-box;
  background: white;
  padding: 20px;
  float: left;

  input {
    width: 100%;
    @include border-box;
    margin-bottom: 15px;
  }

  .message {
    margin: 20px 0;
  }
}

#paymentForm{
  padding: 50px;
  button{
    width: 100px;
  }
}
.backgroundLoader{
  //background-color: white !important;
}

.response-message{
  margin: 20px;
}

.successful{
  color: green;
}

.error{
  color: #ff6565
}