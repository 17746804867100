@import "utils";
$border-color: #D7DAE2;

/**** TEAM SETTINGS ****/
.container-members{
  padding-top: 12px;
  padding-bottom: 5px;
}

.button-send-invitation{
  padding: 7px 10px !important;
  font-size: 13px;
  margin-top: 5px;
  border: none !important;
  &:before{
    display: none;
  }
  .icon-class{
    width: 12px !important;
  }
  span{
    padding-left: 10px;
  }
}

.container-team-item, .container-tag-item{
  display: flex;
  padding-bottom: 5px;
  .input-email-team-user{
    width: calc(60% - 22px);
    margin-right: 10px;
    .info-label{
      .title{
        font-size: 10px;
        color: #787878;
        font-weight: 400;
        padding-left: 10px;
      }
    }
    .form-control{
      font-size: 13px;
    }
  }
  .icon-trash{
    width: 34px;
    @include item-center;
    cursor: pointer;
    >div{
      display: flex;
    }
    svg{
      width: 15px;
      fill: #A4AFB7
    }
    &:hover{
      svg{
        fill: #FF6565
      }
    }
  }
}

.container-tag-list{
  padding-top: 10px;
}
.container-tag-item{
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  .input-email-team-user{
    width: calc(100% - 104px);
  }
  .item-display-value{
    font-size: 13px;
    width: calc(100% - 104px);
    margin-right: 10px;
    border: 1px solid #D7DAE2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: #535353;
  }
  .container-pop-up{
    width: auto;
  }
}

.add-tag-button{
  padding: 7px !important;
  background-color: #2194F1;
  border: none !important;
  color: #FFF;
  font-size: 13px;
  margin-top: 10px;
}

.container-pop-up{
  display: flex;
  width: calc(40% - 22px);
}

.container-team-member{
  display: flex;
  border: 1px solid $border-color;
  border-radius: 5px;
  width: calc(60% - 22px);
  padding: 5px;
  align-items: center;
  margin-right: 10px;
  .border-image{
    width: 22px;
    height: 22px;
    box-shadow: 0 0 10px #A4AFB7;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .member-name{
    padding-left: 10px;
    color: #535353;
    font-size: 13px;
  }
}

.select-role{
  display: flex;
  align-items: end;
  width: 100%;

  .select{
    width: 100%;
  }
}

/**** SETTINGS ****/
.general-container-settings{
  transition: all 0.3s ease;
  position: relative; /**** ****/
  height: 100%;
  background-color: #f5f6fa;
  .container-review-settings{
    width: 100%;
    height: 100%;
    padding: 17px;
    //display: flex;
    .first-row{
      height: 100%; /**** ****/
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .basic-information-container{
      background-color: #FFF;
      border-radius: 7px;
      width: 33%;
      padding: 17px;
      display: flex;
      flex-direction: column;

      .setting-box-title{
        color: #535353;
        font-weight: 700;
        font-size: 21px;
        padding-bottom: 5px;
      }

      .container-message-info{
        padding-top: 5px;
      }
    }
  }
}

/**** SIDE BAR ****/
.sidebar-review-menu-setting{
  transition: all 0.3s ease;
  position: fixed;
  bottom: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  color: #93a4c2;
  &:hover{
    color: #FFF;
    cursor: pointer;
  }
  svg{
    width: 20px;
    height: auto;
    fill: #ffffff;
  }
  label{
    cursor: pointer;
  }
}

.setting-collapse{
  padding: 13px 15px;
  justify-content: unset;
  width: 200px;

  .label-menu{
    padding-left: 15px;
  }
}
/**** ****/
.wrapper-reviews{
  padding-left: 50px;
  .wrapper-header{
    width: 100%;
  }
}

.reviews-tab-active{
  color: $light-blue-color-1;
}

.review-container{
  height: 100vh;
  .header{
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
    width: 100%;

    .logo{
      width: 140px;
      margin-left: 80px;
    }

    .link-back{
      position: absolute;
      right: 80px;
      color: #2194F1;
      font-size: 13px;
      &:hover{
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.review-body{
  @include item-center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #F5F6FA;
  height: calc(100% - 56px);
  width: 100%;

  .review-title{
    width: 350px;
    text-align: center;

    h1{
      color: #333333;
      font-size: 30px;
      margin-top: 65px;
      margin-bottom: 0;
      font-weight: 700;
    }

    label{
      color: #454545;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 13px;
      font-weight: 300;
    }
  }

  .review-marketing{

    .icon-facebook, .icon-google{
      cursor: pointer;
      > div {
        @include item-center;
      }
      @include item-center;
      width: 300px;
      background-color: white;
      height: 40px;
      border: 1px solid #D7DAE2;
      border-radius: 4px;
    }

    .icon-facebook{
      margin-bottom: 10px;
    }

    .icon-facebook-svg{
      width: 100px;
      height: auto;
      g > path {
        fill: #0078F6;
      }
    }

    .icon-google-svg{
      width: 62px;
      height: 21px;
    }
  }

  .review-footer{
    bottom: 20px;
    position: absolute;
    label{
      color: #454545;
      font-size: 13px;
      font-weight: 300;
    }

    .link-contact-us{
      margin-left: 5px;
      color: #2194F1;
      font-size: 13px;
      &:hover{
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.container-general-review-page{
  padding: 7.5px;
  flex: 1;
  display: flex;
}

.container-review-page{
  border-radius: 7px 0 0 7px;
  flex: 1;
  .tabs{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .tab-content{
     flex: 1;
      border-top: 1px solid #D7DAEB;
    }
  }

  .container-reviews-social-networks{
    display: flex;
    height: 100%;
    .loader-container{
      position: relative;
    }
    .box-reviews{
      flex: 1;
      .container-box-review >div:nth-last-child(1) {
        border: none;
      }
      .container-box-review{
        position: relative;
      }
      .container-review{
        border-bottom: 1px solid #D7DAE2;
      }
    }
  }
}

.container-box-filters{
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  border-radius: 0 7px 7px 0;
  .top-div{
    height: 35.7px;
    border-bottom: 1px solid #D7DAE2;
    box-sizing: content-box;
  }

}

.box-filters{
  flex: 1;
  width: 220px;
  border-left: 1px solid #D7DAE2;
  padding: 10px;

  .dp-multiple-select-dropdown{
    padding-top: 10px;
  }
  .header-filters{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D7DAE2;
    padding-bottom: 4px;
    span{
      font-size: 13px;
      color: #535353;
      font-weight: 600;
    }
    button{
      padding: 0;
      font-size: 10px;
    }
  }
  .title{
    font-size: 10px;
    color: #787878;
    padding-bottom: 5px;
  }
  .container-tags{
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    .btn-all-tags{
      justify-content: start;
      padding: 10px 0;
    }
  }
  .container-platform{
    .google-platform{
      .container-select-range{
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        .dp-select-dropdown{
          width: calc(50% - 5px);
          div:first-child{
            .label-make{
              margin: 0;
              font-size: 10px;
              color: #787878;
              font-weight: 500;
            }
          }
          .select{
            .label-make{
              font-size: 13px !important;
            }
          }
          .popover-for-select{
            .container-option-for-select{
              &:hover{
                background-color: #EFEFEF;
              }
            }
            .container-option-for-select-checked{
              background-color: #3B86FF;
              color: white;
            }
          }
        }
      }
    }
    /*button:focus{
        border: 1px solid !important;
    }*/
    .btn-platform{
      width: auto;
      padding: 6px;
      font-size: 13px;
      background: transparent;
      margin: 2px 0;
      border: 1px solid #D7DAE2 !important;
    }
    .selected-button{
      border: 1px solid #2194F1 !important;
      &:focus{

      }
    }
  }

  .container-date-range{
    .box-date-range{
      display: flex;
      justify-content: space-between;
    }
  }

  .input-date-box{
    border: 1px solid #D7DAE2;
    border-radius: 4px;
    width: calc(50% - 1px);
    .MuiInput-underline{
      &:before{
        border-bottom: none;
        display: none;
      }
      &:after{
        border-bottom: none;
        display: none;
      }
    }
    input{
      font-size: 13px;
      padding: 9px;
    }
    .MuiIconButton-root{
      padding: 5px;
    }
    .MuiInputAdornment-root{
      margin: 0;
    }
    .MuiInputBase-input{
      padding: 9px 5px;
      font-size: 12px;
    }
  }
}

.general-container-loader{
  padding: 7.5px;
  flex: 1 1;
  .loader-container{
    position: relative;
    background: #FFF;
    border-radius: 4px;
  }
}