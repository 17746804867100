@import "src/common/styles/constants";
.row-menu-link{
  width: 100%;
  float: left;
  padding: 0 10px 10px 10px;
  border: 1px solid $box-margin-color;
  border-top: none;
  border-radius: 0 0 5px 5px;
  .tabs{
    .tab-list-item{
      padding: 0 0 5px 0;
      display: flex;
    }
    .tab-content{
      overflow: unset !important;
    }
  }
}

.header-link{
  padding-right: 5px;
  font-weight: 100;
  color: #787878;
  font-size: 11px;
  display: flex;
  align-items: center;
  &:hover{
    cursor: pointer;
    color: $light-blue-color-2;
  }
}

.link-active{
  color: $light-blue-color-2;
}

.body-link {
  margin-top: 8px;
}

.content-delete-icon{
  float: right;
  padding-top: 0;
  .delete-icon{
    height: 14px;
    fill: $border-button-color-pagination;
    &:hover{
      fill: $button-color-red;
      cursor: pointer;
    }
  }
}

.icon-update{
  float: right;
  padding-top: 0;
  .update-icon{
    height: 14px;
    fill: $border-button-color-pagination;
    width: auto;
    margin-right: 7px;
    &:hover{
      fill: $light-blue-color-1;
      cursor: pointer;
    }
  }
}

@media (max-width: 1500px) {
  .tab-list-item{
    .header-link{
      font-size: 0.85rem;
    }
  }
}