@import "../../../../../common/styles/utils";

.modal-generate-invoice {
  display: flex !important;
  @include item-center;

  .modal-dialog {
    min-height: 340px;

    .modal-content {
      box-shadow: 0 0 10px #D7DAE2;

      .modal-body {
        padding: 25px;

        .close-icon {
          width: 32px;
          height: 29px;
          position: absolute;
          top: -14px;
          right: -14px;
          &:hover {
            cursor: default;
          }

          svg{
            &:hover {
              cursor: pointer;
            }
          }

          .close-disable{
            .cls-1 {
              fill: #7f7f7f;
            }
            &:hover {
              cursor: not-allowed;
            }
          }
        }

        .new-invoice-row-container {
          display: flex;
          width: 100%;
          margin-top: 10px;
          justify-content: space-between;

          .input-order-number, .input-order-status {
            width: 49%;

            .classInput {
              .form-control {
                color: #787878;
                background-color: #F5F6FA;
              }
            }
          }

          .new-invoice-row-column-container {
            display: flex;
            flex-direction: column;
            width: 49%;

            .dp-select-dropdown {
              padding-right: 0px;
              width: 100%;
              text-align: left;

              .container-label {
                label {
                  color: #7F7F7F;
                }
              }
            }
          }

          .new-invoice-btn-container {
            display: flex;
            margin-left: auto;

            .button-green {
              background-color: #4AD991;
              border: none;
              color: white;
              text-align: center;
              text-decoration: none;
              font-size: 13px;
              border-radius: 4px;
              width: 100%;
              padding: 7px 10px !important;
              height: 35px;
            }

            .button-green-disabled {
              border: none;
              color: white;
              text-align: center;
              text-decoration: none;
              font-size: 13px;
              border-radius: 4px;
              width: 100%;
              padding: 7px 10px !important;
              height: 35px;
              background-color: #7F7F7F;
            }
          }
        }

        .finance-input {
          border: 1px #ccc solid;
          width: 100%;
          box-shadow: 0 0 0;
          height: 34px;

          .MuiInput-underline::before {
            border-bottom: none;
          }

          .MuiInput-underline::after {
            border-bottom: none;
          }

          .MuiSvgIcon-root {
            fill: #515151;
          }

          #startInvoiceDate, #endInvoiceDate, #dueInvoiceDate {
            font-size: 14px;
            margin-left: 10px;
            color: #7F7F7F !important;
          }
        }
      }
    }
  }
}
