@import "src/common/styles/constants";
@import "src/common/styles/utils";

.container-footer-item{
  float: left;
  width: 100%;
  padding: 10px;
  .container-input-title{
    padding-bottom: 10px;
    .title{
      color: #787878;
      font-weight: normal;
      font-size: 12px;
    }
  }
  .text-item{
    color: $text-color;
    font-size: 10px;
  }
  .body-footer-items{
    float: left;
    width: 100%;
    .container-line{
      .row-value{
        float: left;
        display: flex;
        width: 100%;
        padding-bottom: 10px;
        .section-value{
          float: left;
          width: 70%;
          .info-label{
            .title{
              font-size: 13px;
              font-weight: 300;
              margin: 0;
            }
          }
        }
        .container-select-icon{
          float: left;
          width: 22%;
          margin-left: 2%;
          margin-top: 20px;
          .section-icon{
            width: 100%;
            border: 1px solid #D7DAE2;
            border-radius: 5px;
            height: 34px;
            padding: 7px 9px;
            .icon-menu-content{
              padding: 0;
            }
            .fa-chevron-down{
              padding-left: 8px;
              color: #a4afb7;
            }
          }
        }
        .container-delete{
          width: 8%;
          float: left;
          margin-top: 20px;
          .content-delete-icon{
            float: right;
            padding-top: 8px;
            .delete-icon{
              height: 19px;
            }
          }
        }
      }
      .container-link-value{
        float: left;
        width: 100%;
        border: 1px solid #D7DAE2;
        border-radius: 5px;
        margin-bottom: 6px;
        .link-value-icon{
          float: left;
          width: 12%;
          height: 34px;
          border-right: 1px solid #D7DAE2;
          .icon-menu-content{
            padding: 8px 0 6px 8px;
          }
        }
        .link-value-input{
          border: none;
          float: left;
          width: 88%;
          .form-control{
            border: none;
          }
          .info-label{
            .title{
              font-size: 13px;
              font-weight: 300;
              margin: 0;
            }
          }
        }
      }
      .popover-box-category{
        z-index: 200;
        left: 235px;
        top: 145px;
        background-color: rgb(255, 255, 255);
        transition: all 0.8s ease 0s;
        position: absolute;
        width: 130px;
        margin-bottom: 0px;
        box-shadow: none;
        .footer-icons-container{
          height: 18px;
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          position: relative;
          .icons-row-container{
            padding-top: 4px;
            width: 100%;
            height: 26px;
            align-items: center;
            justify-content: normal;
            display: flex;
            .about-us-icon{
              width: 15px;
              margin-right: 16px;
              &:hover{
                cursor: pointer;
              }
              .icon-about-us{
                width: 15px;
                .icon-about-us-svg{
                  width: 15px;
                  height: 21px;
                  fill: #787878;
                }
              }
            }
          }
        }
      }
    }
  }
  .add-line{
    cursor: pointer;
    color: #3B86FF;
  }
  .box-footer-item{
    border-radius: 5px;
    border: 1px solid $box-margin-color;
    .title{
      padding: 5px 10px;
      border-bottom: 1px solid $box-margin-color;
      color: $text-dark-color;
      font-size: 13px;
      font-weight: 600;
    }
    .sub-item-footer{
      float: left;
      width: calc(100% - 20px );
      padding: 0;
      margin: 0 10px;
      border-bottom: 1px solid $box-margin-color;
      .content-input{
        width: calc(100% - 45px);
        float: left;
        .classInput{
          input{
            border: none;
          }
        }
      }
      span{
        color: $text-dark-color;
        font-size: 13px;
      }
      .container-icons{
        float: right;
        padding-left: 6px;
        height: 34px;
        @include item-center;
        .content-delete-icon, .content-link-icon{
          float: right;
          padding-right: 5px;
          div{
            display: flex;
          }
          .delete-icon, .link-icon{
            height: 14px;
            fill: $border-button-color-pagination;
            &:hover{
              fill: $button-color-red;
              cursor: pointer;
            }
          }
        }
        .content-link-icon{
          width: 20px;
          .link-icon{
            &:hover{
              fill: $light-blue-color-2;
              cursor: pointer;
            }
          }
        }

      }
    }
    .content-plus-icon{
      width: 14px;
      display: inline-block;
      vertical-align: middle;
      margin: 5px 10px;
      svg{
        fill: $light-blue-color-1;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}