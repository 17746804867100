.modal-setup-dealer {
  .modal-dialog {
    width: 1100px;

    .modal-content {
      .modal-body {
        .container-body {
          .left-section {
            padding: 0px;

            .colR40 {
              label {
                margin-bottom: 5px;
              }
            }

            .first-row {
              display: flex;
              align-items: center;
            }

            .second-section {
              background-color: transparent;
              padding: 0;
              display: flex;
              flex-direction: column;
            }

            .container-title {
              padding-top: 10px;

              label.title {
                padding-top: 0px;
                padding-bottom: 15px;
              }
            }
          }

          .right-section {
            .second-section {
              background-color: transparent;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}

.modal-setup-dealer {

  .right-section {
    .second-section {
      padding-right: 3px !important;
      padding-top: 18px !important;
    }
  }

}

.setting-location{
  .modal-dialog{
    width: 1024px !important;
    .modal-body{
      padding: 20px !important;
      .close-modal-icon{
        display: none;
      }
    }
  }
}