@import "src/common/styles/utils";

.button-icon{
  background: #FFF;
  color: #2194F1;
  display: flex;
  transition: width .4s;
  position: relative;
  align-items: center;

  &:before{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: block;
    width: 0;
    height: 100%;
    content: "";
    -webkit-transition: width .4s;
    transition: width .4s;
    background-color: #005294;
  }

  &:hover{
    &:before{
      width: 100%;
    }
  }

  .description-button-container{
    display: flex;
    z-index: 4;
    width: 100%;
    justify-content: space-between;

    .description-no-hover{
      display: flex;
      z-index: 4;

      .icon-class{
        height: 15px;
        width: 15px;
        fill: #FFFFFF;
        margin-right: 6px;
        display: flex;
        z-index: 4;

        >div{
          height: 100%;
          width: 100%;

          svg{
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .description-hover{
      display: flex;
      align-items: center;

      .icon-class-arrow{
        height: 15px;
        width: 15px;
        fill: #FFFFFF;
        margin-left: 20px;
        display: flex;
        z-index: 4;

        >div{
          height: 100%;
          width: 90%;

          svg{
            height: 100%;
            width: 90%;
          }
        }
      }
    }
  }
}