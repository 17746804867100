@import "../common/styles/utils";
.button-text{
  background: transparent;
  position: relative;
  @include item-center;
  .icon-class{
    height: 13px;
    width: 13px;
    margin-right: 3px;
    display: flex;
    z-index: 4;

    >div{
      height: 100%;
      width: 100%;

      svg{
        height: 100%;
        width: 100%;
      }
    }
  }

}