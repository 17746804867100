.container-component{

  .container-first-row{
    display: flex;
    width: 50%; /*** style added for the moment ***/
    height: 50%; /*** style added for the moment ***/

    .welcome-section-root{
      //width: 50%; /*** style commented for the moment ***/
      width: 100%; /*** style added for the moment ***/
      //padding: 17px; /*** style commented for the moment ***/
      padding: 16px 8px 16px 16px;
      min-height: 380px;

      .welcome-section{
        display: flex;
        background-color: #FFFFFF;
        border-radius: 7px;
        justify-content: space-between;
        padding-left: 50px;
        height: 100%;

        .info-welcome{
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .title-description{
            display: flex;
            flex-direction: column;
            line-height: 1;

            .title-description1{
              font-size: 51px;
              color: #535353;
              margin: 0 !important;
              padding: 0 !important;
            }

            .title-description2{
              font-size: 51px;
              color: #2194F1;
              margin: 0 !important;
              padding: 0 !important;
            }
          }

          .button-icon-container{
            .button-watch-demo{
              padding: 6px 13px;
              font-size: 13px;
              font-weight: 300;

              .icon-class{
                margin-right: 5px !important;

                >div{
                  height: 90%;
                  width: 90%;

                  svg{
                    height: 90%;
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        .container-image{
          display: flex;
          align-items: end;
          width: auto;
          min-width: 300px;
          .image-welcome{
            display: flex;
            justify-content: right;
            position: relative;
            z-index: 1;
            height: 90%;
            width: 100%;

            img{
              position: absolute;
              height: 100%;
              width: auto;
              bottom: 0;
              right: 0;
            }
          }
          &:hover{
            .image-welcome{
              height: 100%;
            }
          }
        }

      }
    }

    .steps-referrals-section-root{
      display: none; /*** This will be hidden for the moment ***/
      width:50%;
      padding:17px 17px 17px 0;

      .steps-referrals-section{
        display: flex;
        height:100%;

        .few-steps-section{
          display: flex;
          background-color: #FFFFFF;
          border-radius: 7px;
          flex-direction: column;
          width:50%;
          justify-content: space-between;
          padding: 12px 0px;

          .step-item{
            .title-step-item{
              flex-direction: column;
              padding: 0px 15px;

              .title-step-item-1{
                font-size: 21px;
                color: #535353;
                margin: 0;
              }

              .description{
                display: flex;
                padding: 0px 15px 14px;

                .title-step-item-2{
                  font-size: 21px;
                  color: #535353;
                  margin: 0;
                }

                .title-step-item-3{
                  color: #2194F1;
                  font-size: 21px;
                  padding-left: 3px;
                  margin: 0;
                }
              }
            }
          }

          .container-help-us{
            padding: 0px 15px;

            .help-text-description{
              font-size: 9px;
              font-weight: 300;
              padding-right: 5px;
            }

            .link-help-text-description{
              font-size: 9px;
              font-weight: 300;
              color: #2194F1;
            }
          }
        }

        .referrals-section{
          width:50%;
          padding-left:17px;

          .referrals-request-root{
            height:50%;
            padding-bottom:8.5px;

            .referrals-request{
              display: flex;
              flex-direction: column;
              background-color: #FFFFFF;
              border-radius: 7px;
              height:100%;
              padding: 10px 19px 23px 19px;
              justify-content: space-between;

              .title-referrals-request{
                display: flex;
                justify-content: space-between;

                .title-referral-sent{
                  font-size: 21px;
                  color: #454545;
                  margin: 0;
                }

                .right-svg-request-container{
                  display: flex;
                  align-items: center;

                  >div{
                    display: flex;
                  }
                }
                svg{
                  width: auto;
                  height: 13px;
                  fill: #D7DAE2;
                }
              }


              .referral-content-request{
                display: flex;
                justify-content: space-between;

                .points-request{
                  display: flex;
                  flex-direction: column;

                  .number-request{
                    color: #454545;
                    font-size: 28px;
                    font-weight: 400;
                    margin: 0;
                    line-height: 1;
                  }

                  .arrow-percentage-container{
                    display: flex;
                    align-items: center;

                    .up-arrow-percentage{
                      width: 6px;
                      height: 6px;

                      > div{
                        display: flex;
                        height: 100%;

                        svg{
                          fill: #D7DAE2;
                          height: 100%;
                        }
                      }
                    }

                    .percentage-request{
                      color: #D7DAE2;
                      font-size: 10px;
                      padding-left: 3px;
                      margin: 0;
                    }
                  }
                }

                .image-request{
                  display: flex;
                  justify-content: right;
                  img{
                    width: 60%;
                  }
                }
              }
            }
          }

          .referrals-received-root{
            padding-top: 8.5px;
            height:50%;

            .referrals-received{
              display: flex;
              flex-direction: column;
              background-color: #FFFFFF;
              height: 100%;
              border-radius: 7px;
              padding: 10px 19px 23px 19px;
              justify-content: space-between;

              .title-referrals-reception{
                display: flex;
                justify-content: space-between;

                .title-referral-received{
                  font-size: 21px;
                  color: #454545;
                  margin: 0;
                }

                .right-svg-received-container{
                  display: flex;
                  align-items: center;

                  >div{
                    display: flex;
                  }
                }
                svg{
                  width: auto;
                  height: 13px;
                  fill: #D7DAE2;
                }
              }

              .referral-content-received{
                display: flex;
                justify-content: space-between;

                .points-received{
                  display: flex;
                  flex-direction: column;

                  .number-received{
                    color: #454545;
                    font-size: 28px;
                    font-weight: 400;
                    margin: 0;
                    line-height: 1;
                  }

                  .arrow-percentage-container-1{
                    display: flex;
                    align-items: center;

                    .up-arrow-percentage-1{
                      width: 6px;
                      height: 6px;

                      > div{
                        display: flex;
                        height: 100%;

                        svg{
                          fill: #D7DAE2;
                          height: 100%;
                        }
                      }
                    }

                    .percentage-received{
                      color: #D7DAE2;
                      font-size: 10px;
                      padding-left: 3px;
                      margin: 0;
                    }
                  }
                }

                .image-received{
                  display: flex;
                  justify-content: right;

                  img{
                    width: 60%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .container-second-row{
    display: flex;
    //width: 100%;
    width: 50%; /*** style added for the moment ***/
    height: 50%; /*** style added for the moment ***/

    .reviews-gained-section-root{
      //padding:0 17px;
      padding: 16px 16px 16px 8px; /*** style added for the moment ***/
      //width: 50%;
      width: 100%;

      .reviews-gained-section{
        display: flex;
        background-color: #FFFFFF;
        height: 100%;
        border-radius: 7px;
        padding: 15px 21px;
        justify-content: space-between;

        .reviews-gained-left-side{
          display: flex;
          flex-direction: column;

          .title-gained{
            font-size: 20px;
          }

          img{
            width: 80%;
          }
        }

        .reviews-gained-right-side{
          display: flex;
          flex-direction: column;

          .dropdown-container{
            display: flex;
            justify-content: right;

            .select-current-month{
              min-width: 135px;
              width: auto;
            }
          }

          .description-gained{
            padding: 45px 30px 20px 0px;

            .description-gained-1{
              font-size: 20px;
              color: #535353;
              margin: 0;
            }

            .description-gained-sub{
              .description-gained-2{
                font-size: 20px;
                color: #535353;
                margin: 0;
              }

              .description-gained-3{
                color: #2194F1;
                font-size: 20px;
                padding-left: 3px;
                margin: 0;
              }
            }

            .description-gained-4{
              font-size: 20px;
              color: #535353;
              margin: 0;
            }
          }



          .button-share-now-container{
            .button-share-now{
              padding: 6px 18px;
              font-size: 13px;
              font-weight: 300;

              .icon-class {
                margin-right: 8px !important;
              }
            }
          }
        }
      }
    }

    .reviews-summary-section-root{
      display: none; /*** This will be hidden for the moment ***/
      width:50%;
      padding-right:17px;

      .reviews-summary-section{
        display: flex;
        padding: 35px;
        background-color: #FFFFFF;
        height: 100%;
        border-radius: 7px;
        flex-direction: column;

        .reviews-summary-top{
          display: flex;
          flex-direction: column;

          .reviews-summary-title{
            font-size: 21px;
            color: #535353;
            margin: 0;
          }

          .reviews-summary-stars{
            display: flex;

            .number-reviews{
              .reviews-summary-stars-punctuation {
                font-size: 94px;
                color: #D7DAE2;
              }
            }


            .reviews-summary-stars-container{
              display: flex;
              flex-direction: column;
              padding-left: 17px;
              padding-top: 31px;

              .stars{
                  display: flex;

                .icon-star-review{
                  height: 30px;
                  width: 30px;
                  padding: 0px 3.5px;

                  >div{
                    display: flex;

                    svg{
                      height: 100%;
                      width: 100%;
                    }
                  }
                }
              }

              .no-reviews-yet{
                  font-size: 10px;
                  font-weight: 300;
                  color: #D7DAE2
              }
            }
          }
        }

        .reviews-summary-bottom{
          display: flex;

          .rating-container{
            display: flex;
            flex-direction: column;
            width: 50%;
          }

          .social-network-container{
            display: flex;
            flex-direction: column;
            width: 50%;
          }

        }
      }
    }
  }

}

.container-review-page{
  display: flex; /*** style added for the moment ***/

  .container-first-row{
    .info-welcome{
      .button-icon-container{
        display: none;
      }
    }
  }

  @media only screen and (max-width: 1280px){
    .container-first-row{
      width: unset;
    }
  }


  @media only screen and (max-width: 1100px){
    flex-direction: column;
    .container-first-row{
      .welcome-section-root{
        padding: 16px 16px 8px 16px;
        min-width: 600px;
      }
    }
    .container-second-row{
      width: 100%;
      .reviews-gained-section-root{
        padding: 8px 16px 16px 16px;
      }

    }
  }

  @media only screen and (max-width: 650px){
    .container-first-row{
      .welcome-section-root{
        min-width: unset;
        width: 100%;
        .welcome-section{
          padding-left: 21px;
          .container-image{
            min-width: unset;
            .image-welcome{
              height: 80% !important;
            }
          }
        }
      }
    }
    .container-second-row{
      .description-gained{
        padding-right: 0 !important;
      }
    }
  }

  @media only screen and (max-width: 550px){
    .container-first-row{
      .welcome-section-root{
        .welcome-section{
          .info-welcome{
            .title-description{
              label{
                font-size: 30px !important;
              }
            }
          }
          .container-image{
            min-width: unset;
            .image-welcome{
              height: 78% !important;
            }
          }
        }
      }
    }

    .container-second-row{
      .reviews-gained-left-side{
        img{
          width: 92% !important;
          padding-top: 40px;
        }
      }
    }
  }

  @media only screen and (max-width: 450px){
    .container-first-row{
      .welcome-section-root{
        .welcome-section{
          overflow: hidden;
          .info-welcome{
            .title-description{
              label{
                font-size: 30px !important;
              }
            }
          }
          .container-image{
            min-width: unset;
            .image-welcome{
              height: 74% !important;
              img{
                right: -30px;
              }
            }
          }
        }
      }
    }
    .container-second-row{
      .dropdown-container{
        .label-make{
          font-size: 11px ;
        }
      }
    }
  }

}