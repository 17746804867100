.slitSection.odd{
  flex-direction: row-reverse;
}

.slitSection.background-light-blue{
  background: #F5F6FA;
}

@media only screen and (max-width: 768px) {
  .slitSection.odd{
    flex-direction: column;
  }
}

.slitSection {
  display: flex;
  text-align: initial;
  align-items: center;
  padding: 80px 12px 80px 12px;

  .section-content {
    width: 48%;
    display: flex;
    justify-content: center;

    .section-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .section-divider {
    width: 4%;
  }

  @media only screen and (max-width: 1024px) {
    padding: 60px 35px 60px 35px;
  }


  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 50px 24px 50px 24px;

    .section-content {
      width: unset;
    }
    .section-divider {
      width: unset;
    }
  }

  @media only screen and (max-width: 560px){
    padding-left: 15px !important;
    padding-right: 15px !important;

    .section-content{
      padding-right: 0 !important;
      padding-left: 0 !important;
      .section-wrapper{
        >div{
          text-align: center;
        }
      }
      .regular-font{
        font-size: 16px !important;
      }

      .feature-key-point{
        div:nth-child(2){
          .key-point-title{
            font-size: 17px;
          }
          p{
            font-size: 16px;
          }
        }
      }
    }
  }

}
