@import "constants";
@import "utils";

#tab-settings-menu {
  margin-left: 37px;
  margin-top: 9px;

  ul {
    display: -moz-box;
    border: none !important;

    li {
      margin-top: 9px;

      a {
        bottom: 10px;
        margin-right: 0;
      }
    }
  }

  .tab-setting-title {
    label {
      width: auto;
    }

    .icon-expand-content {
      padding-left: 5px;
      position: initial;

      svg {
        width: 12px;
        height: auto;
        fill: #a6b0b8;
      }
    }
  }

  .tab-setting-title {
    .container-popover {
      .popover-setting {
        width: 110px;
        box-shadow: 0 0 10px #ccc;
        border: none;

        ul {
          li {
            margin: 4px 0;
            width: 100%;
          }
        }
      }
    }
  }
}

#tab-settings-menu {
  ul {
    .tab-general.active {
      border-bottom: 2px #2193f1 solid !important;

      a {
        color: #2193f1;
      }
    }

    .tab-testimonials.active {
      border-bottom: 2px $green-color solid !important;

      a {
        color: $green-color;
      }
    }

    .tab-inventory.active {
      border-bottom: 2px $green-color solid !important;

      a {
        color: $green-color;
      }
    }

    .tab-location.active {
      border-bottom: 2px #F3A200 solid !important;

      a {
        color: #F3A200;
      }

      svg {
        fill: #F3A200;
      }
    }

    .tab-customer.active {
      border-bottom: 2px $button-color-red solid !important;

      a {
        color: $button-color-red;
      }
    }

    .tab-user.active {
      a {
        color: $color-button-all;
      }

      svg {
        fill: $color-button-all;
      }
    }

    .tab-form.active {
      a {
        color: #F3A200;
      }

      svg {
        fill: #F3A200;
      }
    }

    .tab-template.active {
      border-bottom: 2px $green-color solid !important;

      a {
        color: $green-color;
      }
    }

    .tab-marketing.active {
      border-bottom: 2px #A600D6 solid !important;

      a {
        color: #A600D6;
      }
    }

    .tab-sales.active {
      border-bottom: 2px $green-color solid !important;

      a {
        color: $green-color;
      }
    }
  }

}

@mixin tab-general() {
  width: 142px;
  text-align: center !important;
  a {
    color: #a6b0b8;
    border: none !important;
  }
  a:active {
    background-color: white !important;
  }
  a:hover {
    border-bottom-color: transparent !important;
  }
  a:not(.active):hover {
    background-color: transparent;
    text-decoration: none !important;
    border: none !important;
  }
}

.tab-general {
  @include tab-general;
}

.tab-setting-title {
  .container-popover {
    > div > div {
      position: absolute !important;
    }

    .popover-setting {
      top: 26px;
    }

    .popover-setting::before, .popover-setting::after {
      display: none;
    }
  }
}

.tab-setting-title {
  position: relative;
  @include item-center;

  .icon-expand-content {
    position: absolute;
    top: 16px;
    float: left;

    svg {
      width: 20px;
      height: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  label {
    margin: 0;
    width: 100%;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }
  }
}

@media(max-width: 1550px) {
  .container-header {
    #tab-settings-menu {
      .tab-testimonials, .tab-inventory, .tab-location, .tab-customer, .tab-user, .tab-form, .tab-template, .tab-marketing, .tab-sales {
        width: 110px;
      }
    }
  }
}

@media (max-width: 1480px) {
  #tab-settings-menu {
    margin-left: 28px;
  }

  .tab-general {
    width: 142px;
  }

  #tab-settings-menu {
    ul {
      li {
        width: 130px;

        a {
          width: 100%;
        }
      }
    }
  }

  .tab-general {
    width: 150px !important;
  }

  #tab-settings-menu-tab-general {
    width: 100%;
  }
}

@media (max-width: 1310px) {
  #tab-settings-menu {
    margin-left: 10px;
  }

  .tab-general {
    width: 137px;
  }
}

@media screen and (max-width: 1260px) {
  #tab-settings-menu {
    font-size: 11px;
  }

  #tab-settings-menu {
    ul {
      li {
        width: 110px;
      }
    }
  }

  .tab-general {
    width: 120px !important;
  }
}

@media screen and (max-width: 1210px) {
  .container-header {
    #tab-settings-menu {
      .tab-testimonials, .tab-inventory, .tab-location, .tab-customer, .tab-user, .tab-form, .tab-template, .tab-marketing, .tab-sales {
        width: 80px;
      }
    }
  }
}
@media screen and (max-width: 1060px) {
  #tab-settings-menu ul li {
    width: 105px;
  }
}

@media screen and (max-width: 1024px) {
  #tab-settings-menu {
    .tab-general {
      width: 145px !important;
    }

    .tab-user {
      width: 80px;
    }

    ul {
      .active {
        border-bottom: 2px #2193f1 solid !important;

        a {
          //color: #2193f1 !important;
        }
      }

      a {
        font-size: 13px;

        label {
          font-weight: $font-weight-bold !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #tab-settings-menu .tab-general {
    width: 140px !important;
  }
}
