@import "src/common/styles/utils";
.container-subscription{
  width: 88%;
  display: flex;
  position: relative;

  .container-adjust-huener{
    background-color: #FFF;
  }

  .container-button-active{
    flex-grow: 1;
    display: flex;
    justify-content: end;
  }
  .active-subscription-button{
    min-width: unset !important;
    background-color: #2194F1;
    height: 36px;
    .loading-content{
      @include item-center;
      width: 92px !important;
    }
  }
  .subscription-status{
    font-size: 10px;
  }
}