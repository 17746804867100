@import "src/common/styles/utils";

.container-children{
  .icon-steps{
    >div{
      svg{
        .a{
          fill: #f0f0f0;
        }
      }
    }
  }
}