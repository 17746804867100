@import './src/common/styles/utils';
.home-navigation {
  ul {
    list-style-type: none;
  }

  a:hover{
    text-decoration: none;
    cursor: pointer;
  }

  font-family: Montserrat, serif;

  background: #1D2130;

  .home-navigation-container {
    display: flex;
    flex-direction: row;
    padding: 0 15px 0 15px;
    height: 80px;
    align-items: center;

    .logo-container {
      flex: 2;
      display: flex;
      justify-content: center;

      .logo {
        width: 200px;
      }
    }

    .nav-items-container {
      flex: 5;

      .nav-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: auto;
        color: white;
        margin-bottom: 0;
      }

      li:hover {
        cursor: pointer;
      }

      svg {
        fill: #057FFA;
      }

      .nav-item-solutions:hover {
        p {
          font-weight: 700;
        }
      }

    }

    .navbar-right-actions {
      display: flex;
      flex: 2;
      justify-content: center;

      .navbar-action-list{
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex: auto;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .nav-drawer{
        display: none;

        .dp-icon{
          fill: #FFFFFF;
          width: 45px;
          padding-top: 3px !important;
        }
      }

    }

    @media only screen and (max-width: 1024px) {
      .nav-items-container {
        display: none;
      }

      .navbar-right-actions {
        justify-content: flex-end;

        .navbar-action-list {
          display: none;
        }

        .nav-drawer {
          display: unset;
        }

      }

      .logo-container{
        justify-content: flex-start;
      }
    }

  }

}

.dp-icon{
  &:hover{
    cursor: pointer;
  }
}

.gdg-drawer{
  font-family: 'Montserrat';
  .MuiDrawer-paper{
    padding: 0;
  }
  >div:first-child{
    backdrop-filter: blur(12px);
  }

  >div:nth-child(3){
    background-color: #1D2130;
    width: 45%;
    //padding: 150px 40px 50px;
  }

  .logo-container{
    width: auto;
    height: auto;
    @include item-center;
    padding-bottom: 40px;
    img{
      width: 220px;
    }
  }

  .home-nav-drawer{
    color: #FFF;
    flex-grow: 1;
    .nav-items{
      .dp-icon{
        margin-bottom: 7px;
      }
      .menu-item-text{
        font-size: 22px;
        margin-bottom: 12px;
        font-weight: 300;
        &:hover{
          cursor: pointer;
        }
      }
      svg{
        fill: #3B86FF;
      }
      .MuiPaper-root{
        width: 100%;
      }
      >div{
        border-radius: 0;
        box-shadow: none;
        >div{
          background-color: #1D2130;
          color: #FFF;
          >div{
            @include item-center;
            margin: 0;
          }
        }
      }

    }
  }

  .nav-items{
    @include item-center;
    flex-direction: column;
  }

  .gdg-button{
    padding: 1.3rem 2.6rem;
  }

  .navbar-action-list{
    display: flex;
    justify-content: center;
    list-style-type: none;
  }

  .container-icon-close{
    padding: 18px;
    display: flex;
    justify-content: end;
  }

  .cancel-icon{
    width: auto;
    height: auto;
    >div{
      display: flex;
      justify-content: end;
    }
    svg{
      fill: #FFF;
      height: 22px;
      width: auto;
    }
  }

  @media only screen and (max-width: 850px) {
    >div:nth-child(3){
      width: 50%;
    }
  }

  @media only screen and (max-width: 700px) {
    >div:nth-child(3){
      width: 100%;
    }
  }
}

#dp-overlay{
  font-family: 'Montserrat';
}

.dp-overlay-bottom, .MuiAccordionDetails-root{
  border-radius: 2px;
  box-shadow: 0 5px 30px #00000029;
  border: 1px solid #FFF;
  padding: 40px !important;
  background-color: #FFF;
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
      display: flex;
      position: relative;
      margin-bottom: 5px;
      &:hover{
        cursor: pointer;
        .dp-icon{
          opacity: 1;
          position: absolute;
          right: 0;
          flex-grow: 1;
          display: flex;
          >div{
            justify-content: end;
          }
        }
        a{
          left: 10px;
          color: #3B86FF;
        }
      }
      a{
        transition: 0.2s;
        color: #4D4F5C;
        position: relative;
        left: 0;
        padding-right: 35px;
        &:hover{
          text-decoration: none;
        }
      }
      .dp-icon{
        height: 20px;
        transition: 0.2s;
        display: none;
        >div{
          display: flex;
          svg{
            fill: #3B86FF
          }
        }
      }
    }
  }
  .dp-scroll-frame{
    p{
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .MuiAccordionDetails-root{
    border-radius: 0;
    display: flex;
    flex-direction: column;
    .title-menu{
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 15px;
      color: #1D2130;
    }
  }
  .navbar-action-list{
    margin: 0;
    padding: 30px 0;
    li{
      display: flex;
    }
  }
  .MuiPaper-root{
    .logo-container{
      padding: 150px 0 40px;
    }
  }
}
