
.container-alert-message{
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  font-size: 13px;
  background: #F3A200;
  color: #FFF;
  height: 42px;
  border-radius: 5px;
  width: 100%;
  padding: 13px;

  .icon-class{
    fill: #ffca83;
    margin-right: 10px;
    border: 1px solid #FFF4E5;
    border-radius: 50%;
    background: #FFF;
    padding: 0 !important;

    svg{
      width: 15px;
      display: flex;
      height: auto;
    }
  }

  .second-message{
    color: #A4AFB7;
    font-size: 10px;
  }

}