.stars-review-container{
  display: flex;

  .number-stars-container{
    display: flex;
    align-items: center;
    padding-right: 3.77px;
    padding-left: 25px;

    .number-stars{
      font-size: 13px;
      color: #D7DAE2;
      font-weight: 400;
      margin: 0;
    }
  }

  .stars-social-container{
    display: flex;
    align-items: center;

    .icon-star-social{
      display: flex;
      height: 13px;
      width: 13px;
      padding: 0px 1.88px;

      >div{
        display: flex;
        width: 100%;

        svg{
          height: 100%;
        }
      }
    }
  }

  .label-no-reviews-container{
    display: flex;
    align-items: center;
    padding-left: 80px;

    .label-no-reviews{
      font-size: 10px;
      color: #A4AFB7;
      font-weight: 400;
      margin: 0;
      background-color: #D7DAE2;
      border-radius: 4px;
      width: 85px;
      height: 16px;
      justify-content: center;
      display: flex;
    }
  }

}