@import "src/common/styles/constants";

.dp-table-container {
  position: relative;
  table {
    height: 100%;
    width: 100%;

    thead {
      background: $table-thead-color;
    }

    tr {
      color: #a6a8b4;
      font-size: 15px;


      th {
        font-weight: 600;
        height: 50px;
        vertical-align: middle;
        border: 0;

        &:first-child {
          padding-left: 40px;
        }

      }

    }

    tbody {
      .dp-table-actions{
        display: none;
      }

      tr {
        color: #a6a8b4;
        font-size: 13px;
        font-weight: $font-weight-regular;

        td {
          &:first-child {
            padding-left: 40px;
          }
          &:last-child{
            padding-right: 30px;
          }
        }

        &:hover {
          box-shadow: 0 0 10px #EFEFEF;
          cursor: pointer;

          .dp-table-actions{
              display: flex;
              float: right;
          }
        }

        td {
          border: none;
          vertical-align: middle;
        }
      }
    }
  }
}

.dp-avatar-container-image {
  img {
    height: 40px;
    width: 40px;
    vertical-align: middle;
    object-fit: cover;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    box-shadow: 0 0 10px #A4AFB7;
  }
}

.dp-table-logo-image {
  height: 40px;
  width: 60px;

  .logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}