.gdg-button {
  margin-right: 5px;
  margin-left: 5px;
  color: white;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  opacity: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: all 1s ease-out;

  .has-icon-left{
    padding-left: 5px;
  }
  .has-icon-right {
    padding-right: 5px;
  }

  a {
    color: white;
    font-weight: 200;
    &:hover{
      text-decoration: none;
    }
  }

  .dp-icon {
    padding: 0 !important;
    width: 9px;

    svg {
      path {
        fill: white !important;
      }
    }
  }
}

.blue {
  background: #057FFA;
  border-radius: 1px;
}

.blue:hover {
  background: #000000 !important;
  border-radius: 1px;
}

.gray {
  background: #434756;
  border: .5px solid #939393;
}

.gray:hover {
  background: #000000;
  border: 0;
  //border: .5px solid #939393;
}
