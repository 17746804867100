.external-content{
  display: flex;
  width: 223px;
  z-index: 101;
  color: white;
  margin-left: 30px;
  margin-bottom: 16px;
  flex-direction: column;
  align-self: flex-end;
  font-size: 13px;
  position: absolute;
  left: 100%;

  .container-title{
    font-size: 21px;
    margin-bottom: 5px;
  }

  .text-container{
    margin-bottom: 18px;
  }

  .container-buttons{
    display: flex;
    .container-btn{
      margin-right: 10px;
      cursor: pointer;
    }
  }
}