@import 'src/common/styles/utils';
.footer-section-gdg{
  background-color: #F5F6FA;

  .footer-third-column > span, a{
    &:hover{
      color: #3B86FF !important;
    }
  }

  a{
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }

  .content{
    display: flex;
    padding: 75px 0 65px;
    padding-left: 140px;

    .social-media-links{
      display: flex;

      .icon-menu-content{
        display: flex;
        align-items: center;
        padding-right: 26px;

        >div {
          display: flex;
        }
      }
      svg{
        width: auto;
        height: 31px;

      }
    }

    .footer-first-column{
      display: flex;
      flex-direction: column;
      padding-right: 145px;
      max-width: 420px;

      .logo{
        width: 82px;
        height: 69px;
      }

      .social-media-section{
        .description{
          font-size: 16px;
          color: #1D2130;
          text-align: left;
          font-weight: 300;
          padding: 25px 0 44px;
        }
      }
    }

    .footer-second-column{
      display: flex;
      flex-direction: column;
      padding: 50px 145px 0 0;

      .contact-title{
        font-size: 21px;
        color: #1D2130;
        text-align: left;
        font-weight: bold;
      }

      .contact-info{
        >ul{
          padding-left: 0;

          >li{
            font-size: 16px;
            font-weight: 300;

            >a{
              color: #1D2130;
              text-align: left;

              &:focus{
                text-decoration: none;
              }
            }
          }
        }
      }

    }

    .footer-third-column{
      display: flex;
      flex-direction: column;
      padding: 50px 145px 0 0;

      .feature-title{
        font-size: 21px;
        color: #1D2130;
        text-align: left;
        font-weight: bold;
      }

      >span{
        font-size: 16px;
        color: #1D2130;
        text-align: left;
        font-weight: 300;
        cursor: pointer;
      }
    }
  }

  >hr{
    padding-left: 140px;
    padding-right: 140px;
  }
  .end-content{
    display: flex;
    margin-left: 140px;

    .brand-details{
      padding: 44px 0 47px;
      font-size: 16px;
      color: #717171;
      font-weight: 300;
    }

    >div>div{
      display: none;
    }
  }
}

/*** TABLET VIEW ***/
@media (max-width: 1024px) {

  .footer-section-gdg {

    .social-media-links{
      .icon-menu-content{
        padding-left: 20px;
        padding-right: 0;
      }
      svg{
        height: 24px !important;
      }
    }

    .content{
      padding: 35px 73px 41px 39px;
      align-items: flex-start;
      justify-content: space-between;

      .footer-first-column{
        flex-direction: row;
        padding-right: 0;
        width: 290px;

        .logo{
          width: 42px;
          height: 36px;
        }

        .social-media-section{
          .description{
            font-size: 12px !important;
            padding: 0 0 23px 20px;
          }
        }
      }

      .footer-second-column{
        padding: 0 0 0 0;

        .contact-title{
          font-size: 15px;
          margin: 0;
        }

        .contact-info{
          >ul{
            >li{
              font-size: 12px;
            }
          }
        }

      }

      .footer-third-column{
        padding: 0 0 0 0;
        .feature-title{
          font-size: 15px;
          margin: 0;
        }

        >span{
          font-size: 12px;
        }
      }
    }



    >hr{
      padding-left: 24px;
      padding-right: 24px;
      margin: 0;
    }

    .end-content{
      display: flex;
      margin: 0;
      justify-content: center;

      .brand-details{
        padding: 16px 0 20px;
        font-size: 10px;
        color: #717171;
        font-weight: 300;
      }
    }
  }

}

/*** MOBILE VIEW ***/
@media (max-width: 768px){
  .footer-section-gdg{
    .content{
      padding: 35px;
      flex-direction: column;

      .footer-first-column{
        width: 100%;
        max-width: unset;
      }

      .footer-second-column, .footer-third-column{
        padding-left: 56px;
        h4{
          padding-bottom: 10px;
        }
        padding-bottom: 20px;
      }

      .social-media-links{
        width: 100%;
        @include item-center;
        .icon-menu-content{
          padding: 0 12px;
        }
      }
    }

    .end-content{
      flex-direction: column;
      align-items: center;
      padding: 30px;
      >div{
        width: auto;
        .brand-details{
          text-align: center;
          width: 100%;
          padding: 0 0 12px 0;
          margin: 0;
        }
        >div{
          display: flex;
          justify-content: space-between;
        }
      }
      a{
        font-size: 10px;
        color: #717171;
        font-weight: 300;
      }
    }
  }
}