@import "src/common/styles/constants";

.container-navigation-item{
  width: 100%;
  padding: 5px;
  float: left;
  .navigation-box{
    border: 1px solid $box-margin-color;
    border-radius: 5px;
    padding: 5px 10px;
    &:hover{
      cursor: pointer;
    }
    .icon-menu-content{
      float: left;
      padding: 0;
      svg{
        height: 12px;
        fill: $icon-arrow-color;
      }
    }
    .title-container{
      padding: 5px 0 5px 0;
      width: 100%;
      display: inherit;
      .title{
        color: $text-dark-color;
        padding-left: 10px;
      }
      .icon-arrow-content{
        padding: 0;
        width: 13px;
        .icon-arrow{
          height: 8px;
          fill: $icon-arrow-color;
          float: none;
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
  }

}