.sales-process{
  .intro-banner{
    .title-font{
      max-width: 580px;
    }
    .section-image{

    }
  }

  .sale-process-application{
    @media only screen and (min-width: 1024px){
      padding-top: 200px !important;
    }

    @media only screen and (max-width: 1024px) {
      padding-top: 180px !important;
    }

    @media only screen and (max-width: 900px) {
      padding-top: 100px !important;
    }

    @media only screen and (max-width: 800px) {
      padding-top: 60px !important;
    }

    @media only screen and (max-width: 768px) {
      padding-top: 0 !important;
    }
    
  }

  @media only screen and (max-width: 1024px){
    .intro-banner{
      .title-font{
        width: 575px;
      }
    }
  }
}