@import "src/common/styles/utils";

.loader-container {
  width: 100%;
  height: 100%;
  @include item-center;
  position: absolute;
  z-index: 1;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;

  .loader {
    border: 7px solid #f3f3f3; /* Light grey */
    border-top: 7px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 72px;
    height: 72px;
    animation: adjust-hue 1s linear infinite;
  }

  @keyframes adjust-hue {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

//.loader-container{
//  width: 100%;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  height: inherit;
//
//  .loader {
//    border: 7px solid #f3f3f3; /* Light grey */
//    border-top: 7px solid #3498db; /* Blue */
//    border-radius: 50%;
//    width: 72px;
//    height: 72px;
//    animation: adjust-hue 1s linear infinite;
//  }
//
//  @keyframes adjust-hue {
//    0% { transform: rotate(0deg); }
//    100% { transform: rotate(360deg); }
//  }
//}