@import "src/common/styles/constants";

.tab-list {
  background: #F8F8F8;
  //border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 1rem 1rem;
}

.tab-header{
  font-size: 11px;
  font-weight: 600;
  color: #787878;
}

.tab-pointer{
  cursor: pointer;
}

.tab-options{
  margin-top: 5px;
  margin-right: 8px;
  float: right;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  .save-icon , .arrow-back-icon, .arrow-next-icon{
    cursor: pointer;
    padding: 3px 5px !important;
    border: 0.5px solid #D7DAE2;
    background: white;
    border-radius: 4px;
  }
  .save-icon:hover{
    background: $green-color;
    .a{
        fill: #ffffff;
    }
  }

  .arrow-back-icon:hover{
    background: $light-blue-color-2;
    .a{
      fill: #ffffff;
    }
  }

  .arrow-next-icon:hover{
    background: $light-blue-color-2;
    .a{
      fill: #ffffff;
    }
  }

  svg{
    path{
      stroke: none;
      opacity: 1;
    }
  }
}

.tab-list-active {
  background-color: white;
}