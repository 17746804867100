@import "../../../../src/common/styles/constants";

.dp-page-flagsList {
  .dp-page-body{
    .dp-page-body-content{
      .dp-page-body-content-header{
        padding: 14px 20px 14px 20px;
      }
    }
  }

  .dp-table-container {
    table {
      tbody {
        tr {
          td {
            height: 55px;
          }
        }
      }
    }
  }
}