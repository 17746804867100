.overlap-background{
  display: block;
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(33,148,241, 0.5);
}

.review-module-container{
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  background-color: transparent;
  padding: 7.5px;
  flex: 1;
}

.box-container{
  background-color: #F5F6FA;
  height: auto;
  display: flex;
  z-index: 99;
  border-radius: 4px;
  width: 100%;
}

.box-body{
  background-color: #FFFFFF;
  height: auto;
  width: 100%;
  display: flex;
  z-index: 101;
  flex-direction: column;
  font-size: 13px;
  padding: 16px;
  border-radius: 4px;
}