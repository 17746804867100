
.update-location-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: 'Inter', sans-serif !important;

  .container-label{
    .label-make{
      font-size: 16px;
      color: #0B1524;
      font-family: 'Inter', sans-serif !important;
    }
  }

  .form-control{
    border-radius: 10px;
    border: 1px solid #809FB833;
    box-shadow: unset;
    height: 45px;
    font-size: 16px;
    color: #0B1524;
    font-family: 'Inter', sans-serif !important;
  }
  .info-label{
    height: 20px;
    width: 100%;
    position: relative;
    padding: 0 10px;
    .title{
      padding: 0 4px;
      background: #FFF;
      font-size: 10px;
      font-weight: 500;
      color: #0B1524;
      margin: 0;
      position: absolute;
      bottom: -7px;
    }
  }

  .left-box, .right-box{
    width: 50%;
  }

  .left-box{
    .general-title{
      font-size: 22px;
      font-weight: 700;
      margin: 0;
    }
    .location-name{
      font-size: 16px;
    }
  }

  .container-inputs{
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    >div{
      width: 100%;
    }
    .city-input, .dp-select-dropdown, .phone-number-input,
    .zip-code-input, .name-input, .address-input{
      width: calc(50% - 5px);
    }

    .dp-select-dropdown{
      padding-right: 0;
      .container-title{
        height: 20px;
        width: 100%;
        position: relative;
        padding: 0 10px;

        .label-make{
          padding: 0 4px;
          background: #FFF;
          font-size: 10px;
          font-weight: 500;
          color: #0B1524;
          margin: 0;
          position: absolute;
          bottom: -7px;
          z-index: 1;
        }
      }

      .select{
        border-radius: 10px;
        border: 1px solid #809FB833;
        box-shadow: unset;
        height: 45px;
        font-size: 16px;
        color: #0B1524;
        font-family: 'Inter', sans-serif !important;
      }

      .help-block{
        top: unset;
        position: relative;
      }

    }

  }

  .right-box{
    display: flex;
    flex-direction: column;
    .btn-save{
      background-color: #1FBA75;
      height: 45px;
      border-radius: 10px;
      font-size: 16px;
      border: none;
      align-self: end;
      width: 160px !important;
    }
    .container-map{
      width: 100%;
      height: 100%;
      padding: 26px 0 0 20px;
    }
    #map{
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px solid #809FB833;

      .gmnoprint, .gm-fullscreen-control{
        display: none;
      }
    }
  }

  .bottom-box{
    width: 100%;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    .title-hours{
      font-size: 16px;
      font-weight: 700;
      color: #242424;
      padding-bottom: 5px;
    }
    .btn-new{
      border: 1px solid #809FB833;
      border-radius: 10px;
      background: #FFFFFF;
      color: #2A5AFA;
      margin-top: 10px;
      &:focus{
        border: 1px solid #809FB833 !important;
      }
    }
  }
}

