.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;

  .progress-bar {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 25px;
    border-radius: 5px;
  }

  .features {
    padding-top: 50px;
    width: 95%;
  }
}

.section-content .feature-title {
  font-size: 14px;
  font-weight: 450
}

.section-content .feature-subTitle {
  font-size: 3vw;
  font-weight: bold;
  white-space: nowrap;
}

.section-content .feature-key-point {
  display: flex;

  .key-point-icon {
    padding-right: 10px;
    img {
      width: 2vw;
    }
  }

  .key-point-title {
    font-size: 1.5vw;
    font-weight: bold;
  }
}


.section-content .feature-section-actions {
  padding-top: 20px;
  padding-bottom: 20px;

  a {
    color: #3B86FF;
  }
}

.section-content .next-previous-arrows {
  .dp-icon {
    width: 18px;
    margin: 0 15px 0 15px;
    padding: unset !important;
    svg path {
      fill: #000;
    }
  }
}

.section-content .section-wrapper {
  img {
    max-width: 37vw;
    @media only screen and (max-width: 1024px) {
      max-width: 45vw;
    }
  }
}


@media only screen and (max-width: 1024px) {

  .section-content .next-previous-arrows {
    width: 3vw;
    .dp-icon {
      display: none;
    }
  }

  .section-content .feature-key-point {
    .key-point-icon {
      img {
        width: 2.5vw;
      }
    }

    .key-point-title {
      font-size: 2vw;
    }
  }
}
@media only screen and (max-width: 768px) {
  .section-content .section-wrapper {
    img {
      max-width: 70vw;
    }
  }

  .section-content .feature-title {
    text-align: center;
  }
  .section-content .feature-subTitle {
    font-size: 4vw;
    text-align: center;
  }

  .section-content .feature-key-point {
    .key-point-icon {
      img {
        width: 3.5vw;
      }
    }

    .key-point-title {
      font-size: 2.5vw;
    }
  }
}

@media only screen and (max-width: 560px) {

  .section-content .feature-subTitle {
    font-size: 5vw;
  }

  .section-content .feature-key-point {
    .key-point-icon {
      img {
        width: 4vw;
      }
    }

    .key-point-title {
      font-size: 3.5vw;
    }
  }
}


