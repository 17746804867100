@import "../common/styles/utils";

.general-box-image{
  display: flex;
  padding: 0 0 6px 6px;
  .container-image{
    width: 50px;
    height: 50px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .circle-icon{
      @include item-center;
      position: absolute;
      bottom: -6px;
      left: -6px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background:  transparent linear-gradient(0deg, #0264E1 0%, #14ACFE 100%) 0% 0% no-repeat padding-box;;
      border: 1px solid #F5F6FA;
      .icon-of-image{
        >div{
          @include item-center;
          svg{
            width: 14px;
            height: auto;
            max-height: 14px;
            fill: #FFF;
            max-width: 14px;

          }
        }
      }
    }
  }
}