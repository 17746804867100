@import "src/common/styles/utils";

@media screen and (max-width: 1024px) {
  .container-request-demo{
    display: flex;
    //height: 100vh;
    height: auto;
    justify-content: center;

    .box-demo{
      width: 500px;
      .marketing-form{
        h3{
          font-size: 34px;
        }
        .subtitle{
          font-size: 13px;
        }
        .container-inputs{
          >div{
            width: 100%;
            padding-bottom: 10px;
            input, .select{
              height: 36px;
            }
          }
          .market-btn{
            height: 40px;
            font-size: 16px;
            padding: 0 20px;
            @include item-center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .container-request-demo{
    .box-demo{
      padding: 0 20px 50px 20px;
      width: 100%;
    }
  }

}