@import "../../../../common/styles/constants";
@import "../../../../common/styles/utils";

//.superusers-page{
//  .icon-add-dealer, .icon-add-superuser{
//    svg{
//      fill: #4ad991;
//      g:hover{
//        cursor: pointer;
//      }
//    }
//  }
//
//}
.dp-page-body-content-header-right{
  //padding: 14px;
  #inactive{
    width: 140px;
    float: left;
    margin: 0 5px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    label{
      font-weight: $font-weight-regular;
    }
  }
  .active{
    color: #FFF;
    background-color: $button-color-red;
    border: 1px solid $button-color-red;
  }
  .inactive{
    border: 1px solid $border-button-color-pagination !important;
    background-color: #FFF;
    color: $button-color-red;
  }
  .icon-active, .icon-inactive{
    div{
      @include item-center;
    }
    .svg-inactive{
      .a{
        fill: #ffffff;
      }
    }
  }
//  .all{
//    color: $color-button-all;
//  }
  .inactive, .active{
    .dp-icon{
      height: 17px;
      width: 20px;
      margin-right: 2px;
    }
    //svg{
    //  height: 15px;
    //  width: 15px;
    //  margin-right: 5px;
    //}
    label{
      margin-bottom: 0;
      font-weight: $font-weight-regular;
      font-family: $font-family;
      font-size: 13px;
    }
    &:hover{
      label{
        cursor: pointer;
      }
    }
  }
}
