@import "constants";
@import "utils";

.container-component, .container-component-without-scroll {
  padding-left: 0;

  .message-header {
    width: 100%;

    .header-wrapper {
      //width: 100%;
      justify-content: space-between;
      //margin-left: 0;

      .header-name {
        width: auto;

        .message-name {
          font-size: 15px;
        }
      }

      .pagination-wrapper {
        width: auto;
        margin-right: 10px;

        .customer-pagination {
          padding: 12px 0;
        }
      }
    }
  }

  .message-body {
    padding: 66px 10px 0 10px;

    .row-table-finance .container-check, .row-icon-message .container-check {
      margin-left: 42px;
    }
  }
}

.message-header {
  float: left;
  width: 100%;
  height: $height-container-header;
  display: flex;
  background-color: #fff;
  @include shadow-header;
  position: fixed;
  z-index: 200;

  .header-wrapper {
    display: flex;
    margin-left: 15px;
    width: 91.5%;
  }

  .header-name {
    flex-grow: 1;
    width: 10%;
    display: flex;
    align-items: center;
    float: left;

    .message-name {
      font-size: 20px;
      float: left;
      color: $icon-color;
      width: 95px;
      font-weight: 700;
      margin-left: 10px;
    }
  }

  .links-container {
    flex-grow: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    .links {
      list-style-type: none;
      margin: 0;
      padding: 0;
      line-height: 60px;
      text-align: center;

      .link {
        cursor: pointer;
        font-family: $font-family;
        font-size: 15px;
        display: inline;
        margin-right: 30px;
        font-weight: $font-weight-regular;

        .selected-tab {
          color: $light-blue-color-1;
        }
      }

      .link a {
        text-decoration: none;
        color: $icon-arrow-color;

        .custom-badge {
          span {
            background-color: red !important;
            color: white !important;
            top: 25px !important;
          }
        }
      }
    }
  }

  .linkTab {
    position: absolute;
    color: $icon-arrow-color;
    font-size: 15px;
    cursor: pointer;
    line-height: 60px;
    font-family: $font-family;
  }

  #linkInbox {
    left: 496.2px;
  }

  #linkSent {
    left: 563.2px;
  }

  #linkFinances {
    left: 621.2px;
  }
}

.message-body {
  float: left;
  width: 100%;
  padding: 80px 25px 30px;
  position: relative;
  min-height: 100%;

  .btn-tab {
    position: absolute;
    background-color: #fff;
    left: 50px;
    border: 0;
    width: 70px;
    height: 40px;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 10px 15px -9px #dddee3, -5px 0 15px -4px #dddee3, 0 -5px 15px -9px #dddee3;
    border-left: 4px $light-blue-color-1 solid;

    span {
      float: left;
      font-size: 10px;
      font-family: $font-family;
      padding: 11px 13px;
    }
  }

  .btn-tab-finance {
    cursor: pointer;
    top: 67px;

    span {
      float: left;
      font-size: 10px;
      font-family: "Segoe UI Semibold", sans-serif;
      padding: 11px 13px;
    }
  }

  a:hover {
    text-decoration: none;
    color: #fff;
  }

  .loader-container {
    width: 100%;
    @include item-center;

    .loader {
      border: 7px solid #f3f3f3; /* Light grey */
      border-top: 7px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 64px;
      height: 64px;
      animation: adjust-hue 1s linear infinite;
    }

    @keyframes adjust-hue {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .message-container {
    min-height: 620px !important;
  }

  .message-bar {
    background-color: $green-color;
    left: 0;
  }
}

.message-container, .container-all-applicant {
  width: 100%;
  position: relative;
  float: left;
  background-color: #fff;

  .div-container-sub {
    height: 100%;
  }
}

#message-container-finance {
  width: 100%;
  height: inherit;
  border-radius: 7px;
}

.message-left {
  float: left;
  width: auto;
  border-right: 1px solid rgb(238, 238, 242);

  .header-message-left {
    display: flex;
    position: relative;
    align-items: center;
    padding: 14px 0;
    height: 60px;

    .icon-add-task {
      padding: 2px 20px;
      position: relative;
      display: flex;
      width: 40px;
      height: 40px;

      svg {
        width: 40px;
        height: 40px;
        fill: $green-color;
      }

      &:hover {
        cursor: pointer;
      }

    }
  }
}

.header-left-message {
  height: 88px;
  padding-top: 0;
}

.header-message-left {
  border-bottom: 1px solid #EEEEF2;
  padding: 14px 15px;
  width: 349px;
}

.row-message, .header-message-detail {
  float: left;
  width: 100%;
}

.row-message {
  float: left;
  width: 100%;
}

.btn-container-dp-message {
  float: left;
  padding: 0 15px 0 110px;
  position: relative;
}

.btn-general-message-dp {
  float: left;
  color: #b3b3b3;
  border: 1px solid $border-button-color-pagination;
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
  text-align: left;
  padding: 5px 3px;
  height: 32px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);

  span {
    font-size: $text-size-select;
    color: $text-color;
    font-weight: $font-weight-regular;
    margin-left: 5px;
  }

  i {
    color: $text-color;
    float: right;
    padding-right: 2px;
    padding-top: 2px;
  }
}

.btn-container-dp {
  float: left;
  padding-right: 18px;
}

.btn-message-apply, .btn-message-apply-disabled {
  float: left;
  color: #fff;
  background-color: #3A3A53;
  border: none;
  border-radius: 5px;
  font-weight: $font-weight-regular;
  text-align: center;
  padding: 0 8px;
  width: 67px;
  height: 32px;
  font-family: $font-family;

  .icon-delete-svg {
    width: 12px;
    height: 14px;
    float: left;
    fill: white;
    margin: 2px 0;
  }
}

.btn-message-apply-disabled {
  background-color: $text-color-table;
  &:hover{
    cursor: not-allowed;
  }
}

.container-dp-show-finance {
  position: relative;
  float: left;
  padding-right: 10px;

  a {
    i {
      float: right;
      padding-top: 3px;
    }
  }
}

.container-dp-sort-finance {
  position: relative;
  float: left;
  padding-right: 10px;

  a {
    i {
      float: right;
      padding-top: 3px;
    }
  }
}

.table-message {
  float: left;
  width: 100%;
  font-family: $font-family;

  .subject-text {
    padding-right: 15px !important;
    max-width: 44%;

    .summary-description{
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .col-dateFormat-message {
    text-align: right;
    padding-right: 10px !important;
  }

  table {
    margin-bottom: 0;

    thead {
      tr {
        background-color: $table-thead-color;
        color: $text-color-table-head;
        font-weight: 900;
        font-size: 16px;

        .th-10 {
          width: 10%;
        }

        .th-15 {
          width: 10%;
        }

        .th-20 {
          width: 20%;
        }

        .th-25 {
          width: 500px;
        }

        .th-33 {
          width: 33%;
        }

        .th-40 {
          width: 40%;
        }

        .th-50 {
          width: 50%;
        }

        .th-60 {
          width: 60%;
        }

        .head-check-box {
          width: 1% !important;
          min-height: 105px;
        }

        th {
          border-bottom: 0;
          padding: 15px 0;
        }
      }
    }

    tbody {
      tr {
        color: $icon-arrow-color;
        font-size: 15px;
        font-weight: $font-weight-regular;

        td {
          border-top: 0;
          padding: 10px 0;
        }
      }

    }
  }

  .row-table-message {
    height: 60px;

    td {
      padding: 14px 0;
      height: 50px;
    }

    .row-icon-message .unread-icon {
      display: block;
      height: 14px;
      width: 14px;
      float: left;
      margin-left: 30px;
      margin-top: 2px;
      position: relative;
    }
  }
}

#data-message-table {
  padding-top: 0;
  border-left: 0.6px solid rgba(0, 0, 0, 0.1);
}

#table-finance {
  padding-top: 0;
  height: 512px;
  border-left: 0.6px solid rgba(0, 0, 0, 0.1);
}

#table-finance {
  .row-message-table:hover {
    cursor: pointer;
  }
}

#check-head-message {
  padding-right: 25px;
  width: 10%;
}

#message-details {
  padding: 0 !important;
}

.message-details {
  float: left;
  width: 42%;
  overflow: hidden auto;
  padding: 10px 30px 30px;
  position: relative;

  & > div:first-child {
    height: 100%;
  }

  .message-wrapper {
    float: left;
    width: 100%;
    overflow: auto;
  }

  #row-message-detail-last {
    border-bottom: 1px solid $table-thead-color;
    padding: 18px 0 10px 25px;
  }

  .row-message-detail-next {
    float: left;
    width: 100%;
  }

  .options-reply-message {
    div {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 60px;
      align-items: center;
      position: absolute;
      bottom: 0;

      .btn-message-replay {
        width: 18%;

        svg {
          width: 20% !important;
        }
      }

      .btn-message-forward {
        width: 20%;

        svg {
          width: 17% !important;
        }
      }

      .btn-message-replay, .btn-message-forward {
        height: 33px;
        border: 1px $border-button-color-pagination solid;
        border-radius: 20px;
        color: #8f8f8f;
        background-color: #FFFFFF;
        text-align: center;
        font-family: $font-family;
        line-height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;

        div {
          display: contents;
          width: 30%;
        }

        .icon-reply, .icon-forward {
          fill: $icon-color;
          cursor: pointer;
          margin: 2px 10px 0 0;
          width: 13%;
        }
      }

      .btn-message-forward {
        margin-left: 25px;

        .icon-forward {
          margin: 0 10px -3px 0;
        }
      }
    }
  }
}

.message-details-header {
  float: left;
  border-bottom: 1px solid rgb(245, 246, 250);
  width: 100%;

  .icon-delete-content {
    float: right;
    margin: 15px 15px 0 0;
    cursor: pointer;

    .icon-delete {
      width: 15px;
      height: 15px;
      fill: #929292;
    }
  }
}

#header-message-panel {
  padding: 20px 15px 0 20px;
  width: 100%;
}

.dot-message-user {
  border-radius: 50%;
  border: 1px #e4e5ea solid;
  background-color: $table-thead-color;
  width: 40px;
  height: 40px;
  float: left;
  text-align: center;
}

#initial-name {
  padding-top: 10px;
  color: #b2b9bf;
}

.label-message-user {
  padding-left: 15px;
  color: #929292;
  margin: 0;
  float: left;
  font-size: 13px;
}

.label-message-user-sent {
  padding-left: 15px;
  color: #929292;
  margin: 0;
  float: left;
  font-size: 13px;
  overflow: hidden;
  height: 19px;
}

.header-message-detail {
  .col45 {
    display: inline-grid;
  }

  .date {
    float: right;
    display: inline-flex;
    width: 150px;

    .icon-expand {
      float: right;
      margin-left: 6px;
      width: 10%;

      .ic-icon-expand {
        fill: #797979;
        cursor: pointer;
      }

      .ic-icon-expand:hover {
        fill: #0096f8;
      }

      .ic-icon-zoom-in {
        width: 15px;
        height: 15px;
        fill: #797979;
        cursor: pointer;
      }

      .ic-icon-zoom-in:hover {
        fill: #0096f8;
      }
    }
  }
}

.label-message-email, .label-message-date {
  padding-left: 15px;
  color: #c3c8cb;
  font-weight: $font-weight-regular;
  font-size: 11px;
  font-family: $font-family;
  float: left;
}

.label-message-date {
  float: right;
  font-size: 13px;
  padding-left: 0;
  width: 134px;
}

#header-message-title-name {
  width: 100%;
  position: relative;
  padding: 15px 25px 20px 0;

  #title-name {
    width: 70%;
    padding: 18px 0 0 29.71px;
  }

  #options-fa-icons {
    width: 30%
  }

}

.name-message-selected {
  margin: 0;
  color: #7d7d7d;
}

.header-title-message {
  padding: 0 0 20px 0;

  .title {
    float: left;
    width: 85%;
  }

  .options {
    float: right;
    width: 15%;

    .ic-delete {
      float: right;
    }

    .ic-print {
      float: right;
      margin-right: 16px;
    }
  }
}

#id-fa-icon {
  font-size: 18px !important;
  color: #dadada;
}

#id-fa-icon:hover {
  color: #0096f8;
  cursor: pointer;
}

#row-message-detail {
  padding: 20px 5px 20px 30px;
  border-bottom: 1px solid $table-thead-color;
}

#row-message-detail-preview {
  padding: 0 30px 20px 0;
}

#tag-message-detail {
  padding-left: 0;
  color: #7b7b80;
}


@media (max-width: 1480px) {
  .pagination-wrapper {
    flex-grow: 1;
    margin-right: 30px;
  }

}

@media (max-width: 1280px) {
  .message-container, .container-all-applicant {
    margin-right: 1%;
  }
}

@media (max-width: 1165px) {
  .row-message {
    .status-message, .sort-message {
      display: none;
    }
  }

  .row-message-table {
    td + td + td + td {
      //display: none;
    }
  }
}


