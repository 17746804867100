@import 'src/common/styles/constants';
@import 'src/common/styles/utils';

.closed{
  display: none;
}
.open{
  display: flex;
}

.body-task-info{
  .task-info-column-tit-desc{
    width: 60%;
  }
}


.task-info-column{
  display: flex !important;
  flex: none !important;

  .task-info-status-type{
    .task-info-field{
      .task-select-assigned{
        .user-img{
          width: 21px;
          border-radius: 50%;
        }
      }
    }
  }
}

.task-comments-container{
  .comments-parent{
    .comment-item{
      .icon-user-name{
        img{
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.task-info {
  .applicant-section-header {
    display: flex;
    align-items: center;

    .applicant-section-header-info .applicant-section-header-title {
      font-size: 21px;
      color: $black-font-color;
    }
  }

  .body-task-info {
    .task-info-column-tit-desc .task-info-description {
      #description {
        padding: 15px 13px;
      }
    }

    .task-info-column {
      .task-info-conf {

      }
    }
  }

  .body-cost {
    padding: 0 15px 15px 20px;

    .section-title {
      font-size: 21px;
    }

    .body-cost-header {
      margin-top: 12px;
    }
  }
}

.container-task-edit{
  .container-header .left-container{
    .title, .arrow-right .fa, .subtitle{
      color: $black-font-color !important;
      font-size: 18px;
    }
  }
}
.list-task-edit{
  .icon-delete{
      fill: #A4AFB7 !important;
  }

  .icon-delete:hover{
    fill: #FF6565 !important;
  }
}

