.social-network-review-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  .container-icon-text{
    display: flex;
    .content-icon-social{
      >div{
        display: flex;
        svg{
          width: 18px;
          height: 18px;
        }
      }
    }
    .text{
      padding-left: 5px;
    }
  }

  .sec-switch{
    display: flex;
    .switch{
      margin: 0;
    }
  }
}