@import "constants";
@import "utils";

/*** setting content ***/
.setting-content{
  padding: 8px 0 8px 0;
  .title-section{
    padding: 10px 15px 10px 15px;
    font-size: 17px;
    color: #575757;
    font-weight: 600;
    border-bottom: 1px solid #F8F8F8;
    display: flow-root;
    .opacity-title{
      color: #D7DAE2;
      cursor: pointer;
      font-size: 15px;
    }
    .icon-arrow-content, .icon-add-content, .icon-arrow-content-right{
      padding: 9px 14px !important;
      cursor: pointer;
      .icon-arrow{
        fill: #575757;
        height: 9px;
      }
      .icon-arrow-right{
        fill: #D7DAE2;
        height: 7px;
      }
      .icon-add{
        fill: #2194F1;
        height: 12px;
      }
    }
    .icon-arrow-content-right{
      padding: 0 6px !important;
    }
    .icon-add-content{
      float: right;
      padding: 0 14px !important;
    }
    .title{
      font-size: 15px;
    }
  }
  .body-section{
    padding: 8px 6px 8px 8px;
    float: left;
    position: relative;
    border-bottom: 1px solid #F8F8F8;
    width: 100%;
    .container-home-banner{
      margin: 0;
      width: 100%;
      padding: 5px;
      float: left;
      .container-background{
        width: 100%;
        float: left;
        border-bottom: 1px solid #EFEFEF;
        margin-bottom: 14px;
        .container-drop-zone{
          padding-top: 3px;
          border: 1px solid #EFEFEF;
          text-align: center;
          border-radius: 6px;
          @include item-center;
          .dropzone{
            @include item-center;
            //border: 1px solid #EFEFEF;
            //border-radius: 6px;
            height: 80px;
            flex-direction: column;
            .customer-image-upload{
              margin-top: 2px;
              .cloud-upload{
                width: 20px;
                height: 20px;
                .a{
                  opacity: 1;
                  .b{
                    fill: $light-blue-color-1
                  }
                }
              }
            }
            .label-upload-logo{
              font-weight: 300;
            }
            &:hover{
              cursor: pointer;
              .label-upload-logo{
                cursor: pointer;
              }
            }
          }
        }
        .container-button{
          padding-top: 5px;
        }
        .title-background{
          color: #787878;
          font-size: 12px;
        }
        .background-image{
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
          margin-top: 4px;
        }
        .container-button{
          margin-bottom: 10px;
          width: 100%;
          float: left;
        }
      }
      .input-slogan{
        .title{
          color: #787878;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
    .container-home-inventory, .container-home-financing, .container-home-location, .container-home-testimonies, .container-home-contact{
      width: 100%;
      float: left;
      padding: 4px 8px 20px 8px;
      .row-title, .row-body{
        float: left;
        width: 100%;
        padding: 0;
        .title{
          float: left;
          color: $text-color;
          font-size: 11px;
          font-weight: 300;
        }
      }
      .row-toggle{
        float: left;
        width: 100%;
        display: flex;
        padding: 25px 0 0 0;
        .label-toggle{
          color: $text-color;
          font-weight: 300;
          width: calc(100% - 54px);
        }
      }
      .row-navigation{
        float: left;
        width: 100%;
        .label-btn{
          float: left;
          padding-bottom: 8px;
          width: 100%;
        }
        .tabs{
          .tab-content{
            overflow: unset !important;
          }
        }
        .tab-list-item{
          padding: 0;
        }
        .button-text{
          padding-top: 10px;
          flex-direction: column;
          >div{
            width: 100%;
            padding-right: 10px;
          }
          .title{
            color: $text-color;
            font-size: 11px;
            font-weight: 300;
          }
        }
      }
    }
    .container-home-financing{
      padding: 0;
      .row-title {
        padding: 4px 8px 20px 8px;

        .tab-hide-section{
          .checkbox{
            >div{
              align-items: center;

              >div{
                height: 15px !important;
                width: 15px !important;
              }
            }
          }
          .a{
            fill: none;
          }

          .icon-check, .icon-uncheck{
            height: 15px;
            width: 15px;
            fill: #CACACA !important;
          }
          label{
            color: #3B86FF !important;
            font-family: 'Open Sans', san-serif !important;
            font-size: 10px;
            font-weight: 300;
            padding-left: 0px;
          }
        }
      }
      .row-navigation{
        padding: 2px 8px 15px 8px;
      }
      .row-toggle{
        padding: 20px 8px 0 8px;
      }
      .row-icon{
        float: left;
        width: 100%;
        padding: 0 10px 10px 8px;
        display: inline-flex;
        .container-icon{
          width: calc(100% - 200px);
        }
      }
      .row-body{
        padding-top: 8px;
      }
      .row-cars{
        border-top: 1px solid #EFEFEF;
        float: left;
        width: 100%;
        padding: 10px;
        .car-title{
          color: #575757;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 6px;
        }
        .row-toggle{
          padding-right: 0;
          padding-left: 0;
          .label-toggle{
            font-weight: 500;
          }
        }
        .row-icon{
          padding: 0;
          .container-icon{
            width: 67px;
            .label-make{
              color: $text-color;
              font-size: 11px;
              font-weight: 300;
              .paper-icon{
                padding: 3px 2px !important;
                width: 20px !important;
                height: 20px !important;
              }
            }
            .container-select{
              span{
                color: #787878;
                font-size: 11px;
                font-weight: 300;
                float: left;
                width: 100%;
                margin-bottom: 5px;
                margin-top: 2px;
              }
              .section-icon{
                @include item-center;
                width: 50px;
                float: left;
                height: 34px;
                border-radius: 4px;
                border: 1px solid #ccc;
              }
            }
          }
          .container-title{
            width: 100%;
            .title{
              color: $text-color;
              font-size: 11px;
              font-weight: 300;
            }
          }
        }
        .container-background{
          .title-background{
            color: $text-color;
            font-size: 11px;
            font-weight: 300;
          }
          .background-image{
            object-fit: cover;
            border-radius: 5px;
            width: 100%;
          }
        }
        .financed-car{
          float: left;
          width: 100%;
          border-bottom: 1px solid #EFEFEF;
          padding-bottom: 20px;
          .container-background{
            .container-drop-zone{
              padding-top: 3px;
              .dropzone{
                @include item-center;
                border: 1px solid #EFEFEF;
                border-radius: 6px;
                height: 80px;
                flex-direction: column;
                .customer-image-upload{
                  margin-top: 2px;
                  .cloud-upload{
                    width: 20px;
                    height: 20px;
                    .a{
                      opacity: 1;
                      .b{
                        fill: $light-blue-color-1
                      }
                    }
                  }
                }
                .label-upload-logo{
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }
    .container-footer{
      .container-subscribe{
        float: left;
        width: 100%;
        padding: 11px;
        .row-toggle{
          float: left;
          width: 100%;
          display: flex;
          .label-toggle{
            font-weight: 400;
            font-size: 14px;
            color: #575757;
            max-width: calc(100% - 54px);
            width: 100%;
          }
        }
        .title{
          color: $text-color;
          font-size: 11px;
          font-weight: 300;
        }
      }
    }
    .container-page-setting{
      .container-icon-message{
        display: flex;
        .content-icon-setting{
          float: left;
          padding: 15px 10px;
          width: 100px;
          svg{
            width: 100%;
            height: auto;
          }
        }
        .page-message{
          padding: 15px;
          float: right;
          width: calc(100% - 100px);
          color: $text-color;
          font-weight: 300;
          height: auto;
          display: flex;
          align-items: center;
        }
      }
      .separator{
        width: 100%;
        margin-bottom: 9px;
      }
    }
    .container-page-vehicles{
      .container-icon-message{
        .content-icon-setting{
          width: 40%;
        }
        .page-message{
          width: 60%;
        }
      }
    }

    /*** ABOUT US PAGE SETTINGS ***/
    .container-about-us{
      margin-top: -16px;
      padding-left: 8px;
      h3{
        font-size: 17px;
        color: #575757;
        font-weight: 700;
      }
      .body-sec-media{
        float: left;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #F8F8F8;
        display: flex;
        flex-direction: column;
        h3{
          margin-bottom: 3px;
        }
        .subtitle{
          font-size: 11px;
          color: #787878;
        }
        .uploaded-images{
          width: 100%;
          float: left;
          margin-top: 10px;
          display: inline-block;
          .current-image-container{
            width: 20%;
            float: left;
            padding: 2px;
            position: relative;
            .current-image{
              width: 100%;
              height: 70px;
              object-fit: cover;
              border-radius: 4px;
            }
            .icon-delete-setting{
              display: none;
              position: absolute;
              width: calc(100% - 4px);
              top: 2px;
              height: 70px;
              background-color: #D10C0C;
              opacity: 0.68;
              border-radius: 4px;
              padding: 20px 5px;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              .row-delete{
                text-align: center;
                color: #FDFDFD;
                h5{
                  margin: 0;
                }
              }

              .icon-content-delete-setting{
                padding-top: 0;
              }

              .cloud-delete-content {
                margin-bottom: -10px;
              }

              .cloud-delete {
                width: 16px;
                height: 16px;
                fill: white;
                cursor: pointer;
              }
            }
            .icon-delete-setting:hover{
              display: block;
            }

            &:hover{
              .icon-delete-setting{
                display: block;
              }
            }
          }
        }
        .container-drop-zone{
          float: right;
          padding: 2px;
          width: 20%;
          height: 70px;
          border: 1px solid #EFEFEF;
          border-radius: 4px;
          .customer-image-upload{
            margin-top: 2px;
            .cloud-upload{
              width: 20px;
              height: 20px;
              .a{
                opacity: 1;
                .b{
                  fill: $light-blue-color-1
                }
              }
            }
          }
          .label-upload-logo{
            font-size: 10px;
            font-weight: 400;
            margin: 0;
            color: $light-blue-color-1;
          }
          >div:focus{
            outline: none;
          }
          &:hover{
            cursor: pointer;
          }
          >div{
            height: 100%;
            @include item-center;
            flex-flow: column;

          }
        }
        .container-default-images{
          display: flex;
          flex-wrap: wrap;
          span{
            flex: 0 0 100%;
            font-size: 11px;
          }
          .container-default-image{
            width: 20%;
            padding: 3px;
           .default-image{
             width: 100%;
             height: 70px;
             object-fit: cover;
           }
          }
        }
      }
      .body-sec-description{
        float: left;
        width: 100%;
        border-bottom: 1px solid #F8F8F8;
        .container-input-description, .container-others-terms{
          padding-bottom: 10px;
          .info-label{
            .title{
              font-weight: 300;
              font-size: 11px;
            }
          }
        }
      }
      .body-sec-details{
        .containers-values{
          @include col100;

          .container-value-title{
            float: left;
            width: 100%;
            padding-top: 20px;
            h3{
              float: left;
              margin: 0;
            }
            span{
              float: left;
            }
            .sec-switch{
              float: right;
            }
          }

          label{
            padding-bottom: 4px;
          }

          .form-control{
            border-color: #EFEFEF !important;
            -webkit-box-shadow: none;
            height: 30px;
          }

          .container-select-icon{
            float: left;
            width: 24%;
            span{
              font-size: 11px;
              font-weight: 300;
              display: block;
              padding: 3px 0 4px 0;
            }
            .section-icon{
              width: 100%;
              border: 1px solid #EFEFEF;
              border-radius: 5px;
              height: 30px;
              padding: 6px 4px;
              &:hover{
                cursor: pointer;
              }
              i{
                padding-left: 5px;
                color: #a4afb7;
              }
              .icon-menu-content{
                svg{
                  fill: #575757
                }
              }
            }
          }

          .section-value{
            float: left;
            width: 73%;
            margin-left: 2%;
            .info-label{
              .title{
                font-size: 11px;
                font-weight: 300;
                margin: 0;
              }
              .label-size{
                padding-bottom: 4px;
                margin: 3px 0 0 0;
              }
            }
          }

          .row-value{
            @include col100;
            border: none;
            padding-bottom: 10px;
            border-bottom: 1px solid #F8F8F8;
          }

          .icon-menu-content{
            padding: 0;
          }
        }
      }
      .body-dealer-hours{
        float: left;
        width: 100%;
        padding-top: 20px;
        .container-title-dealer-hours{
          width: 100%;
          display: flow-root;
          h3{
            margin: 0;
            float: left;
          }
          .sec-switch{
            float: right;
          }
        }
        .message-hours{
          font-size: 11px;
          font-weight: 300;
        }
      }
    }

  }

  a{
    &:hover,:focus{
      text-decoration: none;
    }
  }
}

/*** setting site identity***/
.setting-site-identity{
  .title{
    margin-bottom: 4px;
    float: left;
    color: $text-color;
    font-size: 11px;
    font-weight: 300;
  }
}

.label-size{
  margin-bottom: 4px;
  color: $text-color;
  font-size: 11px;
  font-weight: 300;
}

.correct{
  float: right;
}

.finance_description{
  padding-top: 5px;
}

.row-logo {
  float: left;
  width: 100%;
  padding: 10px 20px;

  .dropzone-container{
    &:hover{
      cursor: pointer;
    }
  }

  .img-logo {
    border: 1px solid #dddee0;
    border-radius: 10px;
    text-align: center;
    padding: 10px 0;
    float: left;
    width: 100%;

    img {
      border-radius: 10px;
      height: 80px;
    }

    .upload-image-logo {
      display: flex;
      height: inherit;
      align-items: center;
      justify-content: center;
      >div:focus{
        outline: none;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .container-image-upload{
      @include item-center;
      .customer-image-upload{
        margin-top: 2px;
        .cloud-upload{
          width: 24px;
          height: 24px;
          .a{
            opacity: 1;
            .b{
              fill: $light-blue-color-1
            }
          }
        }
      }
    }
    .label-upload-logo {
      margin: 0;
      color: #A4AFB7;
      font-weight: 300;
      font-size: 12px;
      &:hover{
        cursor: pointer;
      }
    }
  }

}

.row-preview{
  float: left;
  width: 100%;
  padding: 0 20px 20px 20px;
  .body-preview{
    width: 100%;
    border: 1px solid #ccc;
    float: left;
    border-radius: 5px;
    padding: 6px 12px;
    .browser-preview-title, .browser-preview-url, .browser-preview-description{
      width: 100%;
      float: left;
    }
    .browser-preview-title{
      color: #1A0EAB;
      font-size: 12px;
      font-weight: 700;
    }
    .browser-preview-url{
      font-size: 10px;
      color: #176E2E;
      padding: 2px 0 5px;
    }
    .browser-preview-description{
      font-size: 10px;
      color: #565656;
      word-wrap: break-word;
      margin: 0;
      line-height: 1;
    }

  }
}

.row-icon {
  float: left;
  width: 100%;
  padding: 0 20px 20px 20px;

  .col-img-browser {
    float: left;
    padding-right: 15px;
    img {
      height: 95px;
      width: auto;
    }
  }

  .col-icon-browser {
    width: 95px;
    height: 95px;
    float: left;
    img {
      width: 100%;
      height: 100%;
    }
    .dropzone-container{
      height: 100%;
      width: 100%;
      border: 1px solid $box-margin-color;
      border-radius: 6px;
      @include item-center;
      >div{
        display: flex;
        flex-direction: column;
        .container-image-upload{
          text-align: center;
        }
      }
      &:hover{
      cursor: pointer;
      }
    }

    .container-image-upload{
      .customer-image-upload{
        .cloud-upload{
          width: 24px;
          height: 24px;
          .a{
            opacity: 1;
            .b{
              fill: $light-blue-color-1
            }
          }
        }
      }
    }
    .label-upload-logo{
      font-weight: 300;
      &:hover{
      cursor: pointer;
    }
    }
  }

  .browser-icon{
    height: 95px;
    align-items: flex-end;
    display: flex;
    flex-direction: row-reverse;
    #waste-can{
      &:hover{
        fill: $button-color-red
      }
    }
  }

  .icon-photo-content {
    padding: 10px 10px 4px 10px;
    border: 1px solid #f3f3f3;
    border-radius: 7px;
  }

  .icon-photo {
    fill: #efefef;
  }

  .upload-icon {
    text-decoration: underline;
    padding-left: 3px;
  }

  .upload-icon:hover {
    cursor: pointer;
  }
}

.row-template-sm{
  .social-media-title{
    color: $text-color !important;
    font-weight: 600 !important;
  }
}



/*** setting style ***/

.typography-popover{
  padding: 10px 0 !important;
  .dp-scroll-frame{
    width: 100%;
  }
  .options-for-select{
    .container-option-for-select{
      padding: 5px 10px;
      &:hover{
        background-color: #EFEFEF;
        cursor: pointer;
      }
      .option-label-for-select{
        font-size: 13px;
        font-weight: 400;
        color: #979797;
        margin: 0;
        &:hover{
          cursor: pointer;
        }
      }
    }
    .container-option-for-select-checked{
      padding: 5px 10px;
      background-color: #3B86FF;
      color: white;
      label{
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

.container-setting-style{
  padding: 10px;
  float: left;
  .title-section{
    font-weight: 700;
    color: $text-dark-color;
    padding-left: 5px;
  }
  .theme-section{
    float: left;
    border-bottom: 1px solid $box-margin-color;
    .container-theme{
      width: 50%;
      float: left;
      padding: 5px;
      .icon-theme-content{
        width: 100%;
        svg{
          width: 100%;
          height: auto;
          border-radius: 5px;
        }
      }
      .checkbox{
        padding-left: calc(50% - 46px);
        .icon-check>g>g{
          fill: #FFF;
        }
        label{
          padding-left: 0;
          white-space: nowrap;
          color: $text-color !important;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
  }
  .color-section{
    float: left;
    padding-top: 12px;
    .row-color-default{
      padding: 12px 5px 6px 5px;
      label{
        color: $text-dark-color;
      }
    }
    .body-colors{
      .row-color{
        padding: 10px 5px 0 5px;
        .container-labels{
          width: calc(100% - 50px);
          float: left;
          label{
            width: 100%;
            color: $text-dark-color;
            font-weight: 300;
          }
          .subtitle-color{
            font-size: 9px;
          }
        }
      }
    }
  }
  .typography-section{
    float: left;
    padding-top: 20px;
    width: 100%;
    .body-typography{
      width: 100%;
      float: left;
      .dp-select-dropdown{
        padding: 15px 5px 0 !important;
        width: auto;
        .select{
          display: flex;
          justify-content: space-between;
          padding-right: 10px;
          span{
            font-weight: 400;
            font-size: 13px;
            color: #7f7f7f;
          }
        }
      }
    }
  }
}
