@import "src/common/styles/utils";

.container-review{
  display: flex;
  padding: 10px;
  .box-right{
    padding: 0 0 0 15px;
    flex: 1;
    .container-title{
      @include item-center;
      justify-content: space-between;
      .title{
        font-size: 13px;
        color: #535353;
        font-weight: 500;
      }
      .container-tags{
        display: flex;
        button{
          padding: 0;
          width: 15px;
          height: 5px;
          /*width: 50px;
          height: 15px;
          transition: 0.5s;
          margin: 2px;
          transform-origin: right bottom;*/
          border: none;
          margin-right: 5px;

          span{
            display: none;
          }
          //transform: scale(0.3);
        }
        .btn-promoter{
          background-color: $green-color;
        }
        .btn-referral{
          background-color: #5591EF;
        }
        &:hover{
          button{
            width: auto;
            height: fit-content;
            /*transform: scale(1);*/
            padding: 1px 5px;
            border-radius: 2px;
            span{
              display: block;
              color: #FFF;
              font-size: 10px;
            }
          }
        }
      }
    }
    .container-qualification{
      display: flex;
      align-items: center;
      .qualification{
        display: flex;
        svg{
          fill: $border-button-color-pagination;
          width: 10px;
          height: auto;
          cursor: pointer;
        }
        .icon-star-review{
          padding-right: 3px;
          >div{
            display: flex;
          }
        }
        .recommended{
          display: flex;
          font-size: 10px;
          color: #F3A200;
          align-items: center;
          svg{
            fill: #F3A200;
          }
          .icon-recommended{
            padding-right: 3px;
            >div{
              display: flex;
            }
          }
        }
      }
      .date{
        padding-left: 10px;
        color: #787878;
        font-size: 10px;
      }
    }
    .comment{
      padding: 5px 0 10px 0;
      display: flex;
      font-size: 13px;
    }
    .container-reactions-show-comments{
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      #buttonText{
        padding: 8px 15px !important;
      }
      .container-reactions{
        display: flex;
        position: relative;
        z-index: 10;
        .icon-reaction{
          svg{
            width: 20px;
            height: 20px;
            display: flex;
            position: relative;
            &:hover{
              z-index: 10 !important;
              //width: 22px;
              //height: 22px;
            }
          }
        }
      }
      .reaction-text{
        padding-left: 5px;
        font-size: 11px;
        color: #787878;
        cursor: pointer;
        z-index: 11;
      }
    }
    .container-edit-reply{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: end;
      .container-reply-buttons{
        display: flex;
        padding-top: 5px;
        button{
          padding: 0 5px;
        }
        .cancel-button:hover{
          span{
            color: #FF6565 !important;
          }
        }
        .send-button:hover{
          span{
            color: #2194F1 !important;
          }
        }
      }
    }
    .reply-container{
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      border-left: 2px solid $green-color;
      margin-bottom: 10px;
      .comment-reply{
        font-size: 13px;
      }
      .container-reply-by{
        display: flex;
        padding: 4px 0;
        span{
          color: #A4AFB7;
          font-size: 10px;
        }
        button{
          font-size: 10px;
          padding: 0 5px;
        }
      }
    }
    .container-sub-comments{
      padding-left: 12px;
    }
    .options-container{
      display: flex;
      button{
        font-size: 10px;
        padding: 10px 10px 10px 0;
        &:hover{
          svg{
            fill: #2194F1
          }
          span{
            color: #2194F1 !important;
          }
        }
      }
      svg{
        fill: #535353
      }
      .popover-for-select{
        label{
          width: auto !important;
        }
        .container-picture{
          .icon-of-image{
            svg{
              width: 9px;
              height: 9px;
              right: 0;
              bottom: 0;
              position: absolute;
            }
          }
        }
        .user-name{
          padding-left: 10px;
          font-size: 11px;
          color: #333333;
        }
        .reactions-head{
          display: flex;
          padding: 0 5px;
          border-bottom: 1px solid #ebedf0;
          align-items: center;
          .bottom-line{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
          }
          .all-option{
            &:hover{
              cursor: pointer;
            }
            position: relative;
            height: 26px;
            display: flex;
            align-items: center;
            padding: 0 5px;
            span{
              font-size: 11px;
              color: #333333;
              font-weight: 700;
            }
          }
          .reaction-quantity{
            &:hover{
              cursor: pointer;
            }
            display: flex;
            //padding-right: 10px;
            padding: 5px 7px;
            position: relative;
            .icon-of-image{
              svg{
                width: 16px;
                height: 16px;
                display: flex;
              }
            }
            .quantity{
              font-size: 11px;
              font-weight: 700;
              padding-left: 2px;
            }
          }
          .container-reactions-types{
            display: flex;
          }
        }
        .container-reactions-user{
          padding: 7px;
          .reaction-user{
            display: flex;
            align-items: center;
            padding: 1px 0;
            .container-picture{
              position: relative;
              img{
                width: 20px;
                height: 20px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}