.intro-banner {
  padding-top: 80px;
  background: #1D2130;
  color: white;
  z-index: -1;
  height: 710px;
  padding-right: 15px !important;
  padding-left: 15px !important;

  .section-image {
    width: 800px;
    margin-top: 35px;
  }

  .title-font {
    width: 70%;
  }

  @media only screen and (max-width: 1200px) {
    //margin-bottom: 145px;
    .section-image {
      width: 750px;
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 50px;
    height: 700px;
    //margin-bottom: 175px;

    .gdg-button {
      a {
        font-size: 20px;
      }
    }

    .section-image {
      width: 80vw;
      margin-top: 20px;
    }

    .title-font {
      width: 50%;
    }

    .regular-font{
      width: 60%;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 50px;
    height: 700px;

    .gdg-button {
      a{
        font-size: 20px;
      }
    }

    .section-image {
      width: 100%;
      margin-top: 20px;
    }

    .title-font {
      width: 70%;
    }

    .regular-font{
      width: 90%;
      font-size: 16px !important;
    }
  }
  @media only screen and (max-width: 560px) {

    .title-font, .regular-font {
      width: 100% !important;
    }
  }

}
