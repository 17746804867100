
.container-exclamation-message{
  display: flex;
  text-align: left;
  width: 100%;
  float: left;

  .container-icon-exclamation{
    padding: 4px 10px 0 0;
    height: 20px;
    .exclamation-icon{
      width: auto;
      height: auto;
      >div{
        display: flex;
        svg{
          height: 16px;
          width: 16px;
          fill: #ffca83;
          border: 1px solid #fff4e5;
          border-radius: 50px;
        }
      }
    }
    .floating-message{
      display: flex;
      position: absolute;
      background: #FFF;
      border: 1px solid #D7DAE2;
      border-radius: 7px;
      padding: 5px;
      width: 220px;
      font-size: 10px;
      z-index: 1;
      box-shadow: 0 3px 6px #00000029;
      color: #787878;
    }
  }

  .container-message{
    .text-message{
      padding: 2px 6px 0 0;
      color: #787878;
      font-size: 13px;
      margin: 0;
    }
  }
}