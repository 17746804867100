@import "src/common/styles/constants";

@mixin border-container-payment-amount{
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #D7DAE2;
}


.setting-billing-container {
  float: left;
  width: 80%;
  //grid-template-columns: 70% 30%;
  display: flex;
  margin-right: 10px;
  //grid-column-gap: 10px;

  .title-container-hosted{
    float: left;
    padding: 9px 0 9px 25px;
    width: 100%;
    .left-container{
      float: left;
      width: auto;
      padding-right: 20px;
      .title-label-invoice{
        color: #454545;
        font-size: 1.9rem;
        font-weight: 600;
      }
    }
    .right-container{
      width: auto;
      float: right;
      padding-right: 20px;
      display: flex;
      .container-payment-options{
        float: left;
        .payment-amount-container{
          display: flex;
          #full-amount-payment{
            @include border-container-payment-amount;
            display: flex;
            align-items: center;
            margin-right: 10px;
            label{
              white-space: nowrap;
              margin-bottom: 0;
            }
          }
          .new-payment-amount-container{
            margin-right: 10px;
            display: flex;
            @include border-container-payment-amount;
            padding-right: 12px;
            .new-payment-amount-radio{
              width: 144px;
              display: flex;
              align-items: center;
              label{
                margin-bottom: 0;
                white-space: nowrap;
              }
            }
            .new-payment-amount{
              margin-left: 0;
              width: 77px;
              .dollar-prefix{
                position: absolute;
                margin-left: 10px;
                margin-top: 2px;
                font-size: 13px;
                color: #787878;
              }
              input{
                height: 22px;
                font-size: 13px;
                padding: 0 0 0 14px;
                border: none;
                box-shadow: none;
              }
            }
          }
        }
      }

    }
  }

  .container-invoices{
    width: 100%;
    float: left;
    display: block;
  }
  .container-payment{
    width: 100%;
    padding: 0;
    height: 100%;
    .payment-methods{
      width: 100%;
      padding: 15px;
      .container-title{
        display: block;
        .left-container{
          float: left;
          .title-label-invoice{
            color:#787878;
            font-size: 1.8rem;
            font-weight: 600;
          }
        }
        .right-container{
          float: right;
          .add-icon{
            width: 13px;
            fill: #2194F1;
            cursor: pointer;
          }
        }
      }
      .container-body{
        width: 100%;
        float: left;
        .row-payment-method{
          padding: 9px 15px 0 15px;
          margin: 10px 0 10px 0;
          border: 1px solid #D7DAE2;
          display: grid;
          grid-template-columns: 15% 70% 15%;
          grid-column-gap: 5px;
          height: 40px;
          border-radius: 5px;
          .card-icon{
            width: 10px;
          }
          .card-logo{
            width: 50px;
          }
          .card-mastercard{
            width: 28px;
            margin-left: 12px;
          }
          .label-card-number{
            color: #787878;
            font-weight: 200;
            font-size: 1.5rem;
            padding: 0 0 0 15px;
          }
          .options{
            display: flex;
            .icon-edit, .icon-delete{
              width: 26px;
              fill: #A4AFB7;
              padding-right: 10px;
              cursor: pointer;
            }
            .icon-edit{
              &:hover{
                fill: #2194F1;
              }
            }
            .icon-delete{
              &:hover{
                fill: $button-color-red;
              }
            }
          }
          .options-form{
            display: none;
          }
        }
        .row-payment-method:hover{
          .options-form{
            display: flex;
            text-align: right;
          }
        }
      }
      .container-form{
        width: 100%;
        float: left;
        .payment-method-form{
          .row-input{
            padding-bottom: 8px;
            display: flex;
            .checkbox label{
              padding-left: 4px;
            }
          }
          .row-input-one-column{
            padding-bottom: 8px;
          }
          label{
            font-size: 12px !important;
            font-weight: 300 !important;
          }
        }
      }
    }
  }

  .right-container{
    .container-form-message{
      display: flex;
      flex-direction: column;
      .container-message{
        height: 20px;
        position: relative;
        .response-message{
          position: absolute;
          white-space: nowrap;
          right: 0;
        }
      }
    }
    #paymentForm{
      padding: 0;
      .btn-save{
        width: 67px;
      }
    }
    .response-message{
      margin: 0;
    }
  }
}

.setting-billing-right-panel {
  display: flex;
  flex-direction: column;
  width: 26%;

  .current-features-section{
    width: 100%;

    .current-due-header-information {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-text {
        font-size: 21px;
        font-weight: bold;
      }
      .header-button{
        color: #2194F1;
        cursor: pointer;
      }
    }
    .included-item-container,.not-included-item-container {
      display: flex;
      align-items: center;

      .progress-bar-label{
        font-size: 9px;
        color: #787878;
        padding-right: 5px;
        margin-left: auto;
      }
      .progress-bar-container{
        width: 60px;
      }
      svg{
        width: 13px;
        fill: #4AD991;
        margin-right: 10px;
      }
      .icon{
        font-size: 17px;
        margin-left: 2px;
        margin-right: 10px;
        color: #D7DAE2;
      }
      .message-text {
        font-size: 13px;
      }
    }
    .not-included-item-container{
      .message-text{
        color: #D7DAE2;
      }
    }
  }

  .current-due-card{
    background-color: #005294;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 15px 16px;
    margin-bottom: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,.15);

    .due-price{
      font-size: 35px;
      font-weight: bold;
    }
    .subtext{
      color: #A4AFB7;
      font-size: 9px;
    }

  }
  .current-due-information-card{
    margin-top: 10px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 15px 16px;
    box-shadow: 0 2px 6px rgba(0,0,0,.15);
    align-items: center;

    .title{
      font-size: 21px;
      font-weight: bold;
    }
    .subtitle{
      color: #787878;
      font-size: 13px;
    }
  }
  .btn{
    cursor: pointer;
  }
  .select-plan-btn{
    background-color: #4AD991;
    border-radius: 4px;
    height: fit-content;
    padding: 7px 12px;
    color: white;
  }
}

.current-due-container{
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;

  .current-due-section{
    display: flex;
    height: auto;
    background-color: #005294;
    padding: 16px;
    width: 100%;
    .current-due-section-first-column{
      display: flex;
      flex-direction: column;
      .current-due-title{
        font-size: 13px;
        color: #FFFFFF;
      }
      .current-due-value{
        font-size: 35px;
        font-weight: bold;
        color: #FFFFFF;
      }
      .current-due-period{
        font-size: 13px;
        color: #FFFFFF;
      }
    }
    .current-due-section-second-column{
      margin-left: auto;
    }
  }

  .payment-due-section{
    display: flex;
    padding: 8px 16px;
    font-size: 13px;
    color: #454545;
    .payment-due-date{
      margin-left: auto;
    }
  }
}

.payment-methods-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
  background-color: #fff;
  border-radius: 5px;

  .payment-methods-section {
    display: flex;
    height: auto;
    padding: 16px;
    width: 100%;
    flex-direction: column;

    .payment-methods-header {
      display: flex;
      height: auto;
      width: 100%;
      margin-bottom: 10px;

      .payment-method-title {
        font-size: 21px;
        font-weight: bold;
      }

      .icon-add-new-payment-method {
        margin-left: auto;

        div {
          height: 100%;
          display: flex;
        }

        .icon-add-svg {
          width: 15px;
          height: 24px;
          cursor: pointer;
          fill: $light-blue-color-1;
        }
      }
    }

    .payment-methods-list {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      max-height: 97px;
      overflow: hidden;

      .payment-method-item {
        display: flex;
        width: 100%;
        border: 2px solid #D7DAE2;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 10px;

        .icon-credit-card-logo{
          width: 44px;
          height: 19px;
        }

        .credit-card-number {
          margin-left: 12px;
          font-size: 13px;
        }

        .payment-methods-options-list {
          display: flex;
          margin-left: auto;
          //width: 40px;
          justify-content: space-between;

          .icon-payment-method-option {
            width: 14px;
            height: 14px;
            fill: #A4AFB7;

            :hover {
              cursor: pointer;
              fill: $light-blue-color-1;
            }
          }
        }
      }
    }
    .payment-methods-list.extended{
      max-height: 245px;
      overflow: auto;
    }

    .show-more-option{
      text-align: center;
      padding: 5px 0;
      font-size: 13px;
      color: #A4AFB7;
      cursor: pointer;
    }
  }

  .panel-payment-method-container {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 16px;
    margin-top: 15px;
    border-top: 1px solid #D7DAE2;

    label {
      font-size: 9px;
      font-weight: normal;
    }

    .col-md-6, .col-md-10, .col-md-3, .col-md-2, .col-md-9{
      padding-left: 0px;
    }

    .bnt-save, .btn-skip {
      width: 67px;
      height: 32px;
      border-radius: 4px;
      padding: 4px 0;
      text-align: center;
      float: right;
      color: #fff;
      background-color: #4AD991;
      cursor: pointer;
      border: none;
      font-size: 13px;
      font-weight: $font-weight-regular;
      font-family: $font-family;
    }

    .btn-skip {
      background-color: #2194F1;
      float: none;
    }

    .back {
      width: 67px;
      height: 32px;
      border-radius: 4px;
      text-align: center;
      float: left;
      color: #fff;
      background-color: #2194F1;
      cursor: pointer;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img-back {
      width: 10px;
      margin: 0 5px 0 0;

      > div {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}