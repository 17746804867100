@import "src/common/styles/utils";

.modal-receipt-header{
  .options-back{
    padding-top: 17px;
    .icon-back{
      .a{
        fill: #FFF;
      }
    }
    b{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .options-paginator{
    padding-top: 20px !important;
  }

  .options-edit{
    grid-template-columns: auto auto auto auto auto !important;
    padding-top: 10px;
    .btn-save{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

.receipts-images{
  .right, .left{
    top: calc(50% + 36px) !important;
    >div{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg{
      fill: #FFF;
    }
  }
  .container-image{
    height: calc(100% - 112px);
    top: 112px !important;
  }
}

.container-alert{
  position: absolute;
  width: 100%;
  padding: 60px 50px;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 201;
  height: 100%;
  backdrop-filter: brightness(0.3);
  .button-text{
    padding: 8px ;
  }
}

@media screen and (max-width: 1024px){
  .modal-receipt-header{
    padding: 1% 30px !important;
  }

  .user-modal-body{
    .right{
      right: 18px !important;
    }
    .left{
      left: 18px !important;
    }
  }

  .container-alert{
    padding: 60px 30px;
  }
}

@media screen and (max-width: 768px){
  .user-modal-body{
    .receipts-images{
      .container-image{
        top: 85px;
      }
    }
  }
  .modal-receipt-header{
    grid-template-columns: 1fr 60px 1fr !important;
    .options-paginator{
      width: 60px !important;
    }
  }
}

@media screen and (max-width: 500px){
  .modal-receipt-header{
    padding: 1% 20px !important;
    grid-template-columns: auto 1fr !important;
    .options-paginator{
      top: 70px;
      position: absolute;
      width: 100% !important;
      left: 0;
    }
  }

  .user-modal-body{
    .right{
      right: 8px !important;
    }
    .left{
      left: 8px !important;
    }
  }

  .container-alert{
    padding: 60px 20px;
    .container-alert-message{
      height: fit-content;
      padding: 8px 12px;
    }
  }
}