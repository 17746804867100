@import "src/common/styles/utils";

.button-loading{
  background: #D7DAE2;
  border: 1px solid !important;
  color: #FFFFFF;
  display: flex;
  transition: width .4s;
  position: relative;
  align-items: center;
  border-radius: 4px;
  min-width: 90px !important;
  width: auto !important;

  .loading-content{
    display: flex;
    align-items: center;

    .loader-container{
      position: relative;
      padding-right: 8px;

      .loader{
        position: relative;
        border: 2px solid #f3f3f3;
        border-top: 2px solid #3498db;
        width: 13px;
        height: 13px;
      }
    }

    span{
      white-space: nowrap;
    }
  }

  &:focus{
    border: 1px solid !important;
    border-color: #CCC !important;
  }

}