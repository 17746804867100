.container-change-password{
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #F5F6FA;
  font-family: Montserrat, serif;

  .form-change-password{
    padding: 0 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin: 0 auto;
    height: 580px;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 1px;
    box-shadow: 0px 0px 22px #D7DAE276;

    .logo-godealergo{
      width: 160px;
      margin: 0px auto;
    }

    .id-form-content-reset{
      font-size: 30px;
      font-weight: bold;
      height: 60px;
      padding: 17px 25px;
      color: #292757;
      width: 80%;
      text-align: center;
      border-bottom: 0;
    }

    .id-form-content-email{
      padding: 17px 25px;
      color: rgb(209, 215, 219);
      width: 80%;

      .new-password-recover{
        display: flow-root;
        > label{
          color: #292757;
          font-weight: bold;
          font-size: 12px;
        }
      }

      .confirm-password-recover{
        margin-top: 20px;
        > label{
          color: #292757;
          font-weight: bold;
          font-size: 12px;
        }
      }

      .input-new-password-recover{
        position: relative;
        input{
          height: 35px;
          font-size: 15px;
          color: #6d6f7a;
          border: 0.5px solid #D9D9D9;
          border-radius: 1px;
          width: 100%;
        }

        input:focus-visible{
          outline: none;
        }
      }

      .img-password-recover{
        position: absolute;
        bottom: 5px;
        right: 6px;
        width: 21px;
        height: 21px;

        >div{
          svg{
            fill: #4AD991;
          }
        }
      }

      .requirements-box{
        padding-top: 20px;

        .p-review-check{
          display: flex;
          color: #292757;

          .icon-arrow-green{
            width: 17px;
            padding-top: 4px;
            margin-right: 7px;

          }
        }
      }
    }

    .form-btn-sign-in{
      width: 70%;
      text-align: right;
      border-top: 0;
      display: flex;
      justify-content: center;
      padding: 0;

      .btn-login{
        width: 100%;
        background-color: #057FFA;
        border-radius: 1px !important;
        font-weight: 700;
        color: #FFFFFF;
        border: none;
        height: 36px;
      }
    }
  }
}

@media (max-width: 880px){
  .container-change-password{
    .form-change-password{
      width: 60%;
    }
  }
}

@media (max-width: 600px){
  .container-change-password{
    .form-change-password{
      width: 85%;
    }
  }
}