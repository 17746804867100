.container-reviews-landing{
  display: flex;
  height: 100%;
  float: left;
  padding: 15px 15px 15px 65px;
  background-color: #f3f4f8;

  .column-reviews-landing{
    width: 100%;
    min-width: 650px;
    padding: 35px;
    background-color: #FFFFFF;
    height: fit-content;
    border-radius: 7px;
    padding-bottom: 0;
    max-width: 730px;
  }
}