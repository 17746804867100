.style-guide-container{
  font-family: 'Inter', sans-serif;

  .sg-top{
    width: 100%;
    height: 159px;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sg-title{
      font-size: 32px;
      font-weight: bold;
    }

    .sg-switch-mode{
      display: flex;
      align-items: center;
      font-weight: bold;
    }
  }

  .sg-section{
    background-color: #F5F6FA;
    min-height: 250px;
    border-top: #809FB8 .7px solid;

    .sg-section-title{
      background-color: #809FB8;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      padding: 20px 10px 20px 10px;
      font-size: 28px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}
