
.container-confirm-sent{
  font-family: 'Montserrat' !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  .logo-godealergo{
    width: 200px;
    margin: 40px auto 40px;
  }

  .container-text{
    .dp-icon{
      svg{
        width: 10px;
        fill: #4AD991;
        height: auto;
      }
    }
    .text{
      color: #4AD991;
      font-weight: 300;
    }
  }

  .title{
    color: #43425D;
    font-size: 33px;
    font-weight: 700;
    max-width: 452px;
    text-align: center;
    line-height: 1.3;
    padding: 8px 0 20px;
  }

  .gdg-button{
    margin: 40px 0;
    padding: 1.3rem;
    a:hover{
      font-weight: 600;
    }
  }

  .subtitle, .phone-number, .email{
    color: #4D4F5C;
  }

  .phone-number, .email{
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 768px){
  .container-confirm-sent{
    padding: 0 20px;
  }
}