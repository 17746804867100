@import "src/common/styles/constants";

@mixin payment-method-style() {
  .payment-method-item-container {
    display: flex;
    width: 32%;

    .payment-method-item, .payment-method-selected-item {
      display: flex;
      width: 100%;
      border: 1px solid #D7DAE2;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
        border: 1px solid $light-blue-color-1;
      }

      .icon-credit-card-logo {
        width: 44px;
        height: 19px;
      }

      .credit-card-number {
        margin-left: 15px;
      }

      .payment-methods-options-list {
        display: flex;
        margin-left: auto;
        width: 40px;
        justify-content: space-between;

        .icon-payment-method-option {
          width: 14px;
          height: 14px;
          fill: #A4AFB7;

          :hover {
            cursor: pointer;
            fill: $light-blue-color-1;
          }
        }
      }
    }

    .payment-method-selected-item {
      border: 1px solid $light-blue-color-1;
    }
  }
}

.payment-modal .modal-dialog {
  height: 310px;
  width: 580px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  .modal-content .modal-body  {
    padding: 20px 0;
    height: auto;
    .container-icon-close {
      margin-left: 20px;
      cursor: pointer ;
      .icon-close {
        position: absolute;
        top: -13px;
        right: -13px;
        width: 30px;
        height: 30px;
      }
    }
    .modal-title {
      color: $icon-color;
      font-weight: bold;
      font-size: 21px;
      margin-left: 28px;
      margin-bottom: 10px;
    }
    .payment-amount-container {
      margin-top: 18px;
      margin-left: 30px;

      .new-payment-amount-container {
        margin-top: 5px;
        display: flex;
        align-items: center;
        .new-payment-amount-radio {
          width: 175px;

          label {
            margin-bottom: 0;
          }
        }

        .new-payment-amount {
          width: 77px;
          margin-left: -30px;
          .inputSale input{
            height: 28px;
          }

          .dollar-prefix {
            position: absolute;
            margin-left: 10px;
            margin-top: 5px;
            font-size: 13px;
            color: #787878;
          }
        }
      }
    }
    .payment-type-options {
      width: 100%;
      margin-top: 28px;
      display: flex;
      flex-direction: row;

      .btn-credit-card-option, .btn-ach-option {
        margin-left: 30px;
        padding-bottom: 8px;
        color: $icon-color;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          color: $light-blue-color-1;
          border-bottom: 2px solid $light-blue-color-1;
        }
      }

      .active{
        color: $light-blue-color-1;
        border-bottom: 2px solid $light-blue-color-1;
      }

    }
    hr {
      margin-top: -1px;
      border-top: 1px solid $border-button-color-pagination;
      margin-bottom: 10px;
    }
    .container-current-bill-details {
      display: flex;
      flex-direction: column;
      font-size: 13px;

      .current-bill-row {
        display: flex;
        margin: 0 28px;

        .current-bill-row-header {
          font-weight: bold;
        }

        .current-bill-row-order-status {
          margin-left: auto;
          color: #F3A200;
        }

        .current-bill-row-text {
          margin-left: auto;
        }
      }
    }
  }

  .saved-credit-card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 28px;

    .saved-credit-card-wrapper {
      width: 160px;
      height: 37px;
      border: 1px solid $border-button-color-pagination;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-right: 10px;
      cursor: pointer;

      .card-logo {
        width: 51px;
      }

      .card-mastercard {
        width: 23px;
      }
      span {
        color: $icon-color;
        font-size: 13px;
      }
    }

    .btn-add-new-card {
      width: 132px;
      height: 36px;
      border: 1px solid $light-blue-color-1;
      border-radius: 4px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    @include payment-method-style;

  }

  .footer-container {
    display: flex;
    padding: 0 28px;
    margin-top: 10px;

    .pay-btn {
      min-width: 67px;
      width: auto;
      height: 32px;
      border-radius: 4px;
      padding: 4px 10px;
      text-align: center;
      color: #fff;
      background-color: #4AD991;
      cursor: pointer;
      border: none;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 13px;
      margin-left: auto;
    }
  }

  form {
    padding: 10px 28px 0;
    height: 280px;

    .form-group {
      margin-bottom: 8px;

      label {
        font-size: 9px;
        font-weight: normal;
        color: $icon-color;
      }
    }

    .col-md-6, .col-md-3, .col-md-2, .col-md-10 {
      padding-left: 0;
      padding-right: 10px;
    }

    .content-check {
      width: auto;
      margin-top: 10px;
      display: flex;

      .checkbox {
        width: auto !important;
        margin: 0;
      }
    }

    .container-label {
      color: $icon-color;
      font-size: 13px;
      font-weight: normal;
      margin: 2px 0;
    }

    .btn-save {
      color: white !important;
      margin-top: 10px;
      width: auto;
      padding: 0 10px;
      min-width: 60px;
    }
  }

  .btn-pay {
    margin: 28px 28px;
  }

  .message {
    margin: 10px 30px;
  }

  .payment-container {
    flex-direction: column;
  }
}

.open-new-credit-card .modal-dialog{
  height: auto;
}

.fade.modal-select-tier.modal-large {
  .modal-dialog {
    width: 780px;
  }
}

.fade.modal-select-tier.modal-medium {
  .modal-dialog {
    width: 580px;
  }
}
.modal-backdrop.in{
  opacity: 0.5;
}
