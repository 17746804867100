.section-box-customer{

  .section-right-customer{
    .great-container-customer{
      display: flex;

      .customer-import-container{
        width: 60%;

        .container-buttons{
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .title-description1{
          font-size: 42px;
          color: black;
          font-weight: 700;
          padding-top: 7px;
          margin: 0;
          line-height: 1;
        }

        .title-description2{
          font-size:32px;
          color: #2194F1;
          font-weight: 700;
          margin: 0;
        }

        .text-description{
          font-size: 10px;
          padding: 22px 0px;
          margin: 0;
        }

        .container-help-us{
          height: 42px;
          display: flex;
          align-items: center;

          .help-text-description{
            font-size: 9px;
            font-weight: 300;
            padding-right: 5px;
          }

          .link-help-text-description{
            font-size: 9px;
            font-weight: 300;
            color: #2194F1;
          }
        }
        .new-customer-button{
          color: #4ad991;
          display: flex;
          align-items: flex-end;
          cursor: pointer;
          .icon-add{
            fill: #4ad991;
          }
        }
      }

      .gif-container{
        width: 300px;
        height: 260px;

        img{
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

  }
}

.modal-deactivate{
  .modal-backdrop.in{
    background: #A4AFB74A 0 0 no-repeat padding-box;
    opacity: 1;
  }

  .modal-content{
    box-shadow: 0 0 30px #00000029;
    width: 597px !important;
    height: 310px !important;
  }
}

