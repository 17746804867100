@import "src/common/styles/utils";

.video-modal{
  display: flex !important;
  @include item-center;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);

  .modal-dialog{
    width: 70%;
    height: auto;

    .modal-content{
      border-radius: 0;
      border: 1px solid transparent;
      .modal-body{
        padding: 0;
      }
    }
  }
  .container-video{
    position: relative;
    display: flex;
    height: 100%;

    .close-video{
      position: absolute;
      right: -40px;

      svg{
        height: 20px;
        width: 20px;
        fill: #FFF;
        &:hover{
          cursor: pointer;
        }
      }
    }

    .video-controls{
      bottom: 0;
      right: 0;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 30px;

      .left-controls{
        display: flex;
      }

      .circle-container{
        @include item-center;
        background-color: #000;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        &:hover{
          cursor: pointer;
        }

        .pause-video, .play-video, .sound-active-video, .muted-video, .full-screen-video{
          width: unset;
          height: unset;
          >div{
            display: flex;
            svg{
              fill: #FFF;
              height: 16px;
              width: auto;
              max-width: 20px;
            }
          }
        }
      }
    }
  }
}