@import "../../common/styles/constants";
@import "../../common/styles/utils";

.dp-page{
  .dp-page-header{
    display: flex;
    background: white;
    position: fixed;
    @include shadow-header;
    height: $height-container-header;
    width: 100%;
    align-items: center;
    z-index: 1;
    .dp-page-title{
      padding-left: 40px;
      font-size: 21px;
      color: $icon-color;
      font-weight: $font-weight-bold;

      .arrow-back {
        padding-right: 10px;
        float: left;

        svg {
          width: 16px;
          fill: #2194F1;

          .a {
            fill: #2194F1;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .dp-page-body{
    padding: 80px 40px 0 40px;
    .dp-page-body-content{
      min-height: 100%;
      background: white;
      border-radius: 7px;
      @include shadow-body;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
      .dp-page-body-content-header{
        width: 100%;
        position: relative;
        height: 60px;
        display: flex;
        padding: 14px 20px 14px 40px;

        .dp-page-body-content-header-left{
          display: flex;
          flex: 1;

        }
        .dp-page-body-content-header-right{
          display: flex;
          flex: 1;
          justify-content: flex-end;
        }

        .container-right{
          float: right;
          display: flex;
        }
      }
    }
  }
}

//We might need to move to a more proper place
.dealer-button-add, .superusers-button-add{
  display: flex;
  align-items: center;
  padding-left: 10px;
  .icon-add-dealer, .icon-add-superuser{
    svg{
      fill: #4ad991;
      g:hover{
        cursor: pointer;
      }
    }
    align-items: center;
    display: flex;
    margin-top: 7px;
  }
}
