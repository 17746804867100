@import "constants";
@import "utils";

/***************task********************************/
.container-task-edit {
  height: 100%;
  .container-header {
    .left-container {
      width: calc(100% - 200px);

      .subtitle {
        @include one-single-line-text;
      }
    }

    .right-container {
      width: 200px;
    }
  }
}

.body-edit-task {
  width: 100%;
  float: left;
  padding: 80px 20px 20px;

  .car-header {
    display: flex;
    align-items: center;
    padding: 0 15px 0 15px;

    h5 {
      padding: 0 0 0 15px;
      margin: 0;
      font-weight: $font-weight-bold;
      font-size: 21px;
      color: #787878;
      font-family: $font-family;
    }
  }

  .applicant-section-header {
    width: 100%;
    margin-left: 16px;
    display: inline-block;

    .applicant-section-header-info {
      float: left;
      margin-left: 12px;

      .icon-question-content {
        cursor: pointer;
        margin-left: 8px;
        margin-top: 5px;
      }
    }

    .applicant-section-header-subtitle {
      font-size: 13px;
      font-family: $font-family;
      color: #515151;
      font-weight: 500;
    }
  }

  .applicant-section-header-title {
    font-family: $font-family;
    font-size: 21px;
    font-weight: $font-weight-bold;
    color: #515151;
  }

  .applicant-section-header-icon {
    background-color: #2194F1;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    float: left;
    position: relative;
    cursor: pointer;
  }

  .task-info-disabled, .task-frequency-disabled, .task-comments-container-disabled {
    pointer-events: none;
    background: rgba(164, 175, 183, 0.27) !important;
    border-bottom: none !important;

    .task-input {
      background: rgba(164, 175, 183, 0) !important;
    }
  }

  .task-info, .task-info-disabled {
    background: white;
    border-radius: 5px 5px 0 0;
    float: left;
    width: 100%;
    padding: 20px 0 20px 16px;
    border-bottom: 1px solid #EFEFEF;

    .body-task-info {
      float: left;
      width: 100%;
      padding: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr auto 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
      "title        title       title        title       title       type      status      car"
      "description  description description  description description assigned  priority    dueDate "
      "description  description description  description description blank     blank       blank  ";
      gap: 10px;

      .task-info-title {
        grid-area: title;
      }
      .task-info-description {
        grid-area: description;
      }
      .task-info-status {
        grid-area: status;
      }
      .task-info-type {
        grid-area: type;
      }
      .task-info-assigned {
        grid-area: assigned;
      }
      .task-info-priority {
        grid-area: priority;
      }
      .task-info-dueDate {
        grid-area: dueDate
      }
      .task-info-car {
        grid-area: car
      }
      .task-info-title .task-input,  .task-info-description .task-input{
        width: 100%;
      }
      .task-info-title .task-input {
        padding: 5px;
      }

      .task-info-title, .task-info-description {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        color: #787878;
      }

      .task-input {
        border: 1px solid $border-color-dropdown;
        float: left;
        background-color: #fff;
        border-radius: 5px;
        width: 95%;
        padding: 10px;
        outline: none;
      }

      .task-input:focus {
        border: 1px solid $border-color-input;
      }

        .task-info-status-type {
          padding-bottom: 25px;
        }

         .task-info-field, .task-info-field-date, .task-info-field-priority{
            width: 170px;

            .task-dueDate {
              width: 100%;
              border: 1px solid #ccc;
              border-radius: 4px;
              height: 31px;

              #dueDate {
                color: #4D4F5C !important;
                width: 100%;
              }

              .MuiInputBase-input::placeholder{
                color: #000 !important;
              }

              div {
                height: 30px !important;
                padding-left: 5px;
              }

              .MuiInput-underline::before {
                border-bottom: none;
              }

              .MuiInput-underline::after {
                border-bottom: none;
              }
            }

            .low {
              color: $light-blue-color-1;
            }
            .medium {
              color: $orange-color;
            }
            .high {
              color: $button-color-red;
            }
          }

        .task-info-conf .task-info-field{
          padding-bottom: 15px;
        }
        .task-info-field-date{
          display: block;
        }

      .title {
        color: #787878;
        font-weight: $font-weight-regular;
        font-size: 13px;
      }

      .validation-field{
        color: $button-color-red;
        padding-left: 5px;
      }
    }

    .body-subtask-info {
      float: left;
      width: 100%;
      padding: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
      "title        title       assigned  status"
      "description  description priority  dueDate"
      "description  description blank     blank";
      gap: 10px;

      .task-info-title {
        grid-area: title;
      }
      .task-info-description {
        grid-area: description;
      }
      .task-info-assigned {
        grid-area: assigned;
      }
      .task-info-status {
        grid-area: status;
      }
      .task-info-priority {
        grid-area: priority;
      }
      .task-info-dueDate {
        grid-area: dueDate
      }

      .task-info-title, .task-info-description {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        color: $text-color;
      }

      .task-input {
        border: 1px solid $border-color-dropdown;
        float: left;
        background-color: $background-color-input;
        border-radius: 5px;
        width: 95%;
        padding: 10px;
        outline: none;
      }

      .task-input:focus {
        border: 1px solid $border-color-input;
      }

      .task-info-status-type {
        padding-bottom: 25px;
      }

      .task-info-field, .task-info-field-date, .task-info-field-priority{
        width: 170px;

        .task-dueDate {
          width: 100%;
          border: 1px solid $border-color-taskDueDate;
          border-radius: 4px;
          height: 31px;

          #dueDate {
            color: $text-color-taskDueDate !important;
            width: 100%;
          }

          .MuiInputBase-input::placeholder{
            color: #000 !important;
          }

          div {
            height: 30px !important;
            padding-left: 5px;
          }

          .MuiInput-underline::before {
            border-bottom: none;
          }

          .MuiInput-underline::after {
            border-bottom: none;
          }
        }

        .low {
          color: $light-blue-color-1;
        }
        .medium {
          color: $orange-color;
        }
        .high {
          color: $button-color-red;
        }
      }

      .task-info-conf .task-info-field{
        padding-bottom: 15px;
      }
      .task-info-field-date{
        display: block;
      }

      .title {
        color: $text-color;
        font-weight: $font-weight-regular;
        font-size: 13px;
      }

      .validation-field{
        color: $button-color-red;
        padding-left: 5px;
      }
    }
  }

  .task-select, .task-select-assigned, .task-select-disabled {
    @include border-box;
    height: 31px;
    margin-right: 5px;

    .icon-arrow-content {
      float: right;
      padding: 1px 5px;

      .icon-arrow-expand {
        height: $icon-arrow-height-svg;
        fill: #A4AFB7;

        .a {
          fill: #A4AFB7;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    label:hover {
      cursor: pointer;
    }

    label {
      color: #4D4F5C;
      font-weight: $font-weight-regular;
      font-size: 13px;
    }
  }

  .task-select-assigned {
    padding: 4px 1px 5px 7px;

    label {
      color: #4D4F5C;
      font-size: 12px;
    }

    .user-img {
      width: 21px;
      height: 21px;
      border-radius: 16px;
      border: 1px solid #FFFFFF;
      box-shadow: 1px 1px 4px 2px rgba(164, 175, 183, 1);
      margin-right: 8px;
    }
  }

  .task-select-disabled {
    border: 1px solid #ccc;
    cursor: not-allowed !important;

    label {
      color: #ccc !important;
      font-weight: $font-weight-regular;
      cursor: not-allowed !important;
    }

    .icon-arrow-expand {
      height: $icon-arrow-height-svg;
      fill: #ccc !important;
    }
  }

  .task-frequency, .task-stage, .task-frequency-disabled {
    background: white;
    float: left;
    width: 100%;
    padding: 20px 0 20px 16px;
    border-bottom: 1px solid #EFEFEF;

    .body-task-frequency, .body-task-stage {
      float: left;
      width: 100%;
      padding: 15px;
      flex-direction: column;

      .row-container {
        display: flex;
        padding-bottom: 10px;

        .icon-uncheck, .icon-check {
          fill: #3B86FF;
          height: 24px;
        }

        .task-freq-label {
          color: #787878;
          font-size: 13px;
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;
          font-weight: $font-weight-regular;
        }

        .task-freq-parent-input-after {
          margin: 0 20px 0 0 !important;
        }

        .task-freq-parent-input, .task-freq-parent-input-after {
          margin: 0 20px;
          width: 45px;

          .freq-task-input {
            width: 45px;
            padding: 0 8px;
            height: 100%;
            color: #787878;
            font-family: $font-family;
            font-weight: $font-weight-regular;
          }

          input:focus {
            box-shadow: none !important;
          }

          div {
            height: 100%;
          }
        }

        .task-freq {
          width: 120px;

          label {
            color: #787878 !important;
            font-weight: $font-weight-regular;
            font-size: 13px;
          }
        }

        .task-freq-on-check {
          width: auto !important;
          padding-right: 44px;
        }

        .task-freq-on-check-after {
          width: auto !important;
          padding-right: 34px;
        }
      }
    }
  }

    .subtasks {
      background: white;
      float: left;
      width: 100%;
      padding: 20px 0 20px 16px;
      border-bottom: 1px solid #EFEFEF;
      }

  .task-files {
    background: white;
    float: left;
    width: 100%;
    padding: 20px 0 20px 16px;
    border-bottom: 1px solid #EFEFEF;

    .body-task-files {
      float: left;
      width: 100%;
      padding: 15px;
    }

    #task-files-dropzone {
      border: 1px solid #D7DAE2 !important;
      background-color: #FFFFFF;
      height: 35px !important;
      width: 170px !important;
    }

    .dropzone-content {
      @include item-center;
      color: #2194F1;
      border: 1px solid #D7DAE2 !important;
      background-color: #FFFFFF;
      height: 35px;
      width: 170px;
      border-radius: 5px;

      .cloud-upload-content {
        width: 21px;
        float: left;
        margin-right: 10px;

        .cloud-upload {
          fill: #2194F1;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .files-container {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;

      .document-item {
        width: 280px;
        height: 65px;
        display: flex;
        margin-right: 15px;
        margin-bottom: 15px;
        border-radius: 3px;
        border: 1px solid #D7DAE2;

        .icon-file-container {
          width: 60px;
          @include item-center;

          .icon-file-parent {
            .icon-file {
              width: 20px;
              height: auto;
            }
          }

          img {
            width: 90%;
            height: 90%;
            object-fit: cover;
          }
        }

        .file-info-container {
          display: flex;
          width: 100%;

          .file-info-container-text {
            width: 85%;
            float: left;
            display: flex;
            flex-direction: column;
            padding: 10px 5px;

            .file-weight {
              color: #787878;
            }

            .title {
              color: #787878;
            }
          }

          .file-info-container-icon {
            float: right;
            padding: 20px 10px;
            display: flex;

            .icon-delete-cont {
              align-self: flex-end;
              height: 15px;
              margin-bottom: 10px;
              float: left;
              margin-left: 10px;
              display: none;

              .icon-delete {
                width: 15px;
                height: auto;
                margin-top: 2px;
                margin-right: 2px;
                fill: $button-color-red;
              }

              &:hover {
                cursor: pointer;
              }

              .icon-download {
                width: 15px;
                height: auto;
                margin-top: 2px;
                margin-right: 2px;
                fill: #2194f1;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }


        }
      }

      .document-item:hover {
        .file-info-container {
          .file-info-container-icon {
            .icon-delete-cont {
              display: flex;
            }
          }
        }
      }
    }
  }

  .task-stage {
    background: white;
    float: left;
    width: 100%;
    padding: 20px 0 20px 16px;
    border-bottom: 1px solid #EFEFEF;

    .body-task-stage {
      float: left;
      width: 100%;
      padding: 15px;
    }
  }

  .task-dueDate {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 31px;

    #dueDate {
      color: #000000 !important;
    }

    div {
      height: 30px !important;
      padding-left: 5px;
      font-family: $font-family !important;
      font-size: 13px !important;
      color: #787878 !important;
      font-weight: $font-weight-regular !important;
    }

    div.MuiInput-underline::before {
      border-bottom: none;
    }

    div.MuiInput-underline::after {
      border-bottom: none;
    }
  }

  .task-popover-child {
    position: relative;

    .task-item-option {
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .task-comments-container, .task-comments-container-disabled {
    border-radius: 0 0 5px 5px;
    background-color: #FFFFFF;
    float: left;
    padding: 20px 20px 20px 31px;
    width: 100%;
    border-top: 1px solid #EFEFEF;

    .comment-title {
      color: $black-font-color;
      font-size: 21px;
      padding-bottom: 20px;
    }

    .comments-parent {
      .comment-item {
        padding-bottom: 20px;
        display: flex;
        align-items: center;

        .comment-right {
          border: 1px solid #EFEFEF;
          color: #242424;
          min-height: 50px;
          position: relative;
          width: 95%;
          margin-right: 17px;
          border-radius: 8px;
          background-color: #F6F5FB;
          display: flex;

          label {
            padding: 10px;
            word-wrap: break-word;
            font-weight: 400;
            display: flex;
            align-items: center;
            margin: 0;
          }
        }

        .comment-right:after, .comment-right:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        .comment-right:after {
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #F6F5FB;
          border-width: 8px;
          margin-top: -8px;
        }

        .comment-right:before {
          border-color: rgba(215, 218, 226, 0);
          border-left-color: #F6F5FB;
          border-width: 9px;
          margin-top: -9px;
        }

        .comment-left {
          border: 1px solid #EFEFEF;
          color: #242424;
          min-height: 50px;
          position: relative;
          width: 95%;
          margin-left: 17px;
          border-radius: 8px;
          display: flex;
          background-color: $text-disabled-background-color;

          label {
            padding: 10px;
            word-wrap: break-word;
            font-weight: 400;
            display: flex;
            align-items: center;
            margin: 0;
          }
        }

        .comment-left:after, .comment-left:before {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        .comment-left:after {
          border-color: rgba(255, 255, 255, 0);
          border-right-color: #FFFFFF;
          border-width: 8px;
          margin-top: -8px;
        }

        .comment-left:before {
          border-color: rgba(215, 218, 226, 0);
          border-right-color: #D7DAE2;
          border-width: 9px;
          margin-top: -9px;
        }

        .icon-user-name {
          @include item-center;
          width: 33px;
          height: 33px;
          background-color: #F5F6FA;
          border: 1px solid #D7DAE2;
          border-radius: 16px;
          color: #A4AFB7;
          font-size: 17px;
          font-weight: 600;

          img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
      }
    }

    .new-comment-container {
      display: flex;
      align-items: center;

      .input-comment {
        width: 95%;
        border-radius: 5px;
        border: 1px solid #EFEFEF;
        height: 44px;
        padding-left: 10px;
        margin-right: 15px;
        outline: none;
        font-family: $font-family;
        font-weight: $font-weight-regular;
        font-size: 15px;
      }

      .icon-send-container {
        @include item-center;
        width: 33px;
        height: 33px;
        background-color: #2194F1;
        border: 1px solid #D7DAE2;
        border-radius: 16px;

        .icon-send {
          @include item-center;
          width: 15px;
          height: auto;
          fill: #FFFFFF;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .task-info {
    .body-cost {
      .cost-container {
        gap: 8px;
        display: grid;
        grid-template-columns: 1fr auto auto auto auto;
        padding-right: 0;
        width: 100%;

        .body-cost-fields, .body-subtask-cost-fields {
          display: contents;
          .info-title {
            .cost-input {
              border: 1px solid #D9D9D9;
              float: left;
              background-color: #fff;
              border-radius: 5px;
              width: 100%;
              padding: 10px;
              outline: none;
            }
          }
          .info-price {
            .title-input {
              display: none;
            }
          }
          .container-drop-zone {
            display: none;
          }
        }
        .body-cost-fields:first-child .title-input,
        .body-subtask-cost-fields:first-child .title-input {
          display: inline;
        }
        .body-cost-fields:first-child .info-icon,
        .body-subtask-cost-fields:first-child .info-icon{
          margin-top: 28px !important;
        }
        .body-cost-fields:first-child .currencyinput,
        .body-subtask-cost-fields:first-child .currencyinput {
          margin-top: 31px;
        }
        .footer-cost {
          display: contents;
        }
        .footer-cost .label-sub-total{
          margin-right: 10px;
          text-align: right;
          padding-top: 8px;
        }
      }
      .body-cost-header {
        display: none;
        .info-title {
          label {
            padding: 0;
          }
        }
      }
    }
  }
  .body-subtasks {
    padding: 15px;
    width: 100%;
    .subtask-header{
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
        font-size: 21px;
        font-weight: bold;
        margin-right: 20px;
      }
      progress {
        margin-right: 20px;
        width: 150px;
      }
      .styled-progress {
        height: 6px;
        appearance: none;

        &::-webkit-progress-bar {
          background-color: #e0e0e0;
          border-radius: 3px;
        }

        &::-webkit-progress-value {
          background-color: $light-blue-color-1;
          border-radius: 3px;
        }
      }
      .task-completed {
          color: #2194F1;
          font-weight: normal;
      }
    }
    .subtask-table {
      table {
        width: 100%;
        margin-bottom: 5px;
        table-layout: fixed;
        thead {
          border-bottom: 1px solid #EFEFEF;
        }
        th {
          color: #787878;
          font-weight: normal;

          &.title {
            width: 50%;
          }
          &.priority {
            width: 15%;
          }
          &.status {
            width: 15%;
          }
          &.costs {
            width: 15%;
          }
          &.x {
            width: 5%;
          }
        }
        td {
        padding-top: 6px;
        padding-bottom: 6px;
        border-bottom: 1px solid #EFEFEF;
          .dp-select-dropdown {
            width: 100%;

            .select{
              border: none;

              &:hover{
                cursor: pointer;
                border: 1px solid $border-color-dropdown;
              }
            }

            .container-label {
              label {
                color: $text-color;
              }
            }

          }
          .info-icon {
            width: 15.5px;
            display: block;

            &:hover {
              fill: #FF6565;
              cursor: pointer;
            }
          }
          .subTask-title{
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            width: 100%;
            max-width: 100%;
          }
        }
        .low {
          color: $light-blue-color-1;
        }
        .medium {
          color: $orange-color;
        }
        .high {
          color: $button-color-red;
        }
      }
      .add-subtask {
        color: #2194F1;
        span {
          cursor: pointer;
        }
      }
    }
  }
}

.body-content-task{
  height: calc(100vh - 160px);
  background-color: white;
}

.body-cost .info-icon .icon-action-content-delete {
  width: 15.1px !important;
}

@media screen and (max-width: 1400px) {
  .body-content-task{
    height: calc(100vh - 160px);
    background-color: white;
  }
}

@media screen and (max-width: 1200px) {
  .body-edit-task .task-info .body-task-info .task-info-column .task-info-conf {
    .task-info-field {
      width: 160px;
    }
  }

  .body-content-task{
    height: calc(100vh - 160px);
    background-color: white;
  }
}

@media screen and (max-width: 1140px) {
  .body-edit-task .task-info .body-task-info .task-info-column .task-info-conf .task-info-field {
    width: 170px;
  }

  .body-edit-task .task-select-disabled {
    margin-right: 2px;
    padding: 3px 3px 3px 3px;
  }
}

@media screen and (max-width: 1060px) {
  .body-edit-task {
    padding: 80px 15px 0 15px;
  }
}

@media screen and (max-width: 980px) {
  .body-edit-task .task-files .files-container .document-item {
    width: 47%;

    .file-info-container .file-info-container-icon {
      padding: 10px 0 10px 25px;
    }
  }
}

@media screen and (max-width: 840px) {
  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-icon {
    padding: 10px 0 10px 20px;
  }
}


@media screen and (max-width: 1024px) {
  .container-task-edit {
    .container-header {
      float: left;
      display: block;

      .left-container {
        width: 90%;

        .arrow-back {
          margin: 0 15px;
        }

        .title {
          width: 60px;
          margin-left: 0;
          font-size: 21px;
          font-weight: $font-weight-bold;
        }

        .subtitle {
          font-weight: $font-weight-bold !important;
        }

        .data {
          width: 80%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .right-container {
        position: absolute;
        right: 15px;

        .option-edit {
          display: block;
          padding-right: 0;
        }
      }
    }
  }

  .container-task-edit {
    .container-header {
      height: 40px;

      .left-container {
        height: inherit;
        width: calc(100% - 107px);

        .title {
          font-size: 14px;
          width: auto !important;
        }

        .subtitle {
          margin: 0;
          padding-left: 10px;
          font-size: 14px;
        }

        .arrow-right {
          margin-top: 2px;

          i {
            font-size: 14px !important;
          }
        }
      }

      .right-container {
        height: inherit;
        justify-content: flex-end;

        .option-edit {
          .list-task-edit {
            .options-archived-removed {
              i {
                padding: 4px;
              }
            }
          }
        }
      }
    }
  }

  .body-edit-task .task-info {
    padding: 20px 0 20px 0;

    .body-task-info {
      flex-direction: column-reverse;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
      "title        title       type      car"
      "description  description assigned  priority"
      "description  description status       dueDate";
      gap: 10px;

      .task-info-title {
        padding-bottom: 0;

        .task-input {
          width: 100%;
          padding: 5px;
        }
      }
      .task-info-type .task-select, ,.task-info-status .task-select, .task-info-priority .task-select, .task-info-car .task-select, .task-info-assigned .task-select-assigned,
      .task-info-type .task-select-disabled, ,.task-info-status .task-select-disabled, .task-info-priority .task-select-disabled, .task-info-car .task-select-disabled, .task-info-assigned .task-select-assigned {
        width: 100%;
      }
    }

    .body-subtask-info {
      flex-direction: column-reverse;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
      "title        title       assigned  priority"
      "description  description status    dueDate"
      "description  description blank     blank";
      gap: 10px;
    }

    .body-cost {
      padding: 15px;

      .body-cost-header {
        .info-column {
          display: none;
        }
      }

      .cost-container {
        gap: 8px;
        display: grid;
        grid-template-columns: 1fr auto auto auto auto;
        padding-right: 0;

        .body-cost-fields, .body-subtask-cost-fields {
          display: contents;
          //flex-direction: row;

          //.info-column-tit-desc {
          .info-title {
            .cost-input {
              border: 1px solid #D9D9D9;
              float: left;
              background-color: #fff;
              border-radius: 5px;
              width: 100%;
              padding: 10px;
              outline: none;
            }

            input {
              width: 100%;
            }

            .title-input {
              //display: block;
              color: $icon-arrow-color;
              font-weight: 400;
              font-size: 11px;
              outline: none;
              //padding-left: 10px;
            }
          }

          //}

          //.info-column {

          //.info-conf {
          .info-price {
            //width: calc(33.3% - 18px);
            width: auto;
            margin-right: 0;

            .title-input {
              //display: block;
              color: $icon-arrow-color;
              font-weight: 400;
              font-size: 11px;
              padding-left: 10px;
            }

            input {
              width: 100%;
            }

            .cost-input {
              width: 100px;
            }
          }

          .container-drop-zone {
            display: none;
          }

          .info-icon {
            //margin-top: 0px !important;
            //padding-top: 28px;
            .dropzone-container {
              @include item-center;
              height: 100%;
            }
          }

          .container-drop-zone {
            display: none;
          }

          //}
          //}
        }

        .footer-cost {
          display: contents;
          .add-cost {
            grid-column: 1/2;
          }
          .label-sub-total {
            grid-column: 2/4;
            padding-top: 10px;
            text-align: right;
          }

          .sub-total-cost {
            flex-wrap: nowrap;
            grid-column: 1/4;
          }

          .info-price.sub-total {
            grid-column: 4;
            width: auto;
            input {
              width: 100px;
            }
            margin-right: 0;
            .cost-total {
            }
          }

          .cost-input {
            width: 100px;
          }
        }
      }
    }
  }

  .body-edit-task {
    padding: 60px 20px 20px;

    .subtasks {
      padding: 0;

      .body-subtasks .subtask-table {
        .title {
          width: 50%;
        }

        .priority {
          width: 15%;
        }

        .status {
          width: 15%;
        }

        .costs {
          width: 15%;
        }

        .x {
          width: 5%;
        }

        .dp-select-dropdown .select {
          padding-left: 0;
        }
      }
    }

    .task-comments-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .body-edit-task .task-info .body-cost .cost-container .body-cost-fields:first-child .title-input,
  .body-edit-task .task-info .body-cost .cost-container .body-subtask-cost-fields:first-child .title-input{
    display: inline;
  }
  .body-edit-task .task-info .body-cost .cost-container .body-cost-fields .title-input,
  .body-edit-task .task-info .body-cost .cost-container .body-subtask-cost-fields .title-input {
    display: none;
  }
  .body-cost-fields .info-title, .body-cost-fields .info-price,
  .body-subtask-cost-fields .info-title, .body-subtask-cost-fields .info-price {
    display: flex;
    flex-direction: column;
  }
  .body-cost-fields .currencyinput,
  .body-subtask-cost-fields .currencyinput {
    margin-top: 11px;
  }

  .body-content-task{
    height: calc(100vh - 140px);
    background-color: white;
  }

  .body-edit-task .task-info .body-cost .cost-container .body-cost-fields:first-child .currencyinput,
  .body-edit-task .task-info .body-cost .cost-container .body-subtask-cost-fields:first-child .currencyinput {
    margin-top: 27px;
  }
}

@media (max-width: 768px){
  .body-edit-task{
    position: relative;
    .applicant-section-header {
      display: flex;
      .applicant-section-header-icon{
        width: 25px;
        height: 25px;
      }
      .applicant-section-header-info{
        display: flex;
        align-items: center;
      }
    }
  }

  .body-edit-task .task-select-disabled {
    margin-right: 2px;
    padding: 4px 5px 5px 7px;
  }

  .body-edit-task .task-dueDate{
    width: 100%;
  }

  .body-edit-task .task-info {
    .body-subtask-info {
      flex-direction: column-reverse;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
      "title        title"
      "description  description"
      "description  description"
      "assigned     status"
      "priority     dueDate";
      gap: 10px;

      .task-input {
        width: 100%;
      }

      .task-info-title, .task-info-description {
        padding-bottom: 0px;
      }
    }

    .body-cost {
      padding: 0 15px 15px 15px;
    }
  }

  .body-edit-task .task-info-disabled {
    .body-subtask-info {
      .task-input {
        width: 100%;
      }

      .task-info-title, .task-info-description {
        padding-bottom: 0px;
      }
    }
  }

  .body-content-task{
    height: calc(100vh - 120px);
    background-color: white;
  }
}

@media screen and (max-width: 760px) {
  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-icon {
    padding: 10px 0 10px 15px;
  }
}

@media screen and (max-width: 660px) {
  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-icon {
    padding: 10px 0 10px 10px;
  }
}

@media screen and (max-width: 600px) {
  .body-edit-task .task-files .files-container .document-item {
    width: 100%;

    .file-info-container .file-info-container-icon {
      padding: 10px 0 10px 30px;
    }
  }

  .container-task-edit {
    .body-edit-task {
      .task-info {
        .body-cost {
          .info-column {
            .info-conf {
              .info-price {
                .currencyinput {
                  margin: 13px 15px;
                }

                input {
                  padding: 13px 6px 10px 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-icon {
    padding: 10px 0 10px 25px;
  }

  .body-edit-task .task-info {
    .body-cost {
      .total-section .total h3{
        font-size: 15px;
      }

      .total-section .total .total-value {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .body-edit-task .task-info, .body-edit-task .task-frequency, .body-edit-task .task-stage, .body-edit-task .task-files {
    padding: 20px 0 0 0;
  }

  .body-edit-task .task-info .body-task-info .task-info-column-tit-desc .task-input, .cost-input {
    width: 100%;
  }

  .body-edit-task .task-info .body-task-info .task-info-column .task-info-status-type .task-info-field {
    width: 100%;
  }

  .body-edit-task .task-info .body-task-info .title {
    padding-top: 10px;
  }

  .body-edit-task .task-info .body-task-info {
    padding: 5px 15px 15px 15px;
  }

  .body-edit-task .task-info .body-task-info {
    .task-info-column .task-info-status-type, .task-info-column-tit-desc .task-info-title {
      padding-bottom: 0;
    }
  }

  .body-edit-task .task-info .body-task-info .task-info-column .task-info-conf .task-info-field {
    padding-bottom: 0;
    width: 100%;
  }

  .body-edit-task .task-select-assigned, .body-edit-task .task-select, .body-edit-task .task-select-disabled {
    margin: 0;
  }
  .body-edit-task .task-info .body-task-info {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-areas:
      "title        title       "
      "description  description "
      "description  description "
      "type         status      "
      "assigned     priority    "
      "car          dueDate     ";
    gap: 8px;
    .task-info-title {
      .task-input {
        width: 100%;
      }
    }
    .task-info-description {
      .task-input {
        width: 100%;
      }
    }
  }

  .body-edit-task .task-info .body-cost {
    .total-section {
      justify-content: space-between;
      gap: 5px;
    }
    .body-cost-fields, .body-subtask-cost-fields {
      flex-direction: column;
      gap: 5px;
    }
    .sub-total-cost{
      display: none;
    }
  }
  .body-edit-task .subtasks {
    padding-left: 0;
    overflow-x: auto;

    .subtask-table {
      width: 550px;
      .title {
        width: 40%;
      }
      .priority {
        width: 15%;
      }
      .status {
        width: 20%;
      }
      .costs {
        width: 10%;
      }
      .x{
        width: 5%;
      }
      .dp-select-dropdown .select {
        padding-left: 0;
      }
    }
  }
  .body-edit-task .subtasks .subtask-header {
    .styled-progress {
      width: 100%;
    }
    .task-completed {
      font-size: 12px;
      width: 100%;
    }
  }
  .body-edit-task {
    margin-top: 40px;
    padding: 0;
  }

  .body-edit-task {
    .task-info {
      .body-cost {
        padding-left: 15px;
        padding-bottom: 15px;
        padding-right: 0;

        .total-section {
          padding-right: 15px;
        }
        .scroll-container {
          overflow-x: auto;
          .cost-container {
            gap: 8px;
            display: grid;
            grid-template-columns: 1fr auto auto auto auto;
            padding-right: 0;
            width: 600px;

            .body-cost-fields, .body-subtask-cost-fields {
              display: contents;
              .info-title {
                .cost-input {
                  border: 1px solid #D9D9D9;
                  float: left;
                  background-color: #fff;
                  border-radius: 5px;
                  width: 100%;
                  padding: 10px;
                  outline: none;
                }
              }
              //.info-price .title-input, .info-title .title-input {
              //    display: none;
              //}

              .container-drop-zone {
                display: none;
              }
            }
            .body-cost-fields .title-input,
            .body-subtask-cost-fields .title-input {
              display: none;
            }
            .body-cost-fields:first-child .title-input,
            .body-subtask-cost-fields:first-child .title-input {
              display: inline;
            }
            .body-cost-fields .info-title, .body-cost-fields .info-price,
            .body-subtask-cost-fields .info-title, .body-subtask-cost-fields .info-price {
              display: flex;
              flex-direction: column;
            }
            .footer-cost {
              display: contents;
            }
          }
        }
        .body-cost-header {
          .info-title {
            label {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .body-edit-task .subtasks .body-subtasks .subtask-table td {
    width: inherit;
    text-overflow: ellipsis;
  }

  .body-content-task{
    height: calc(100vh - 80px);
    background-color: white;
  }
}

@media screen and (max-width: 420px) {
  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-icon {
    padding: 10px 0 10px 20px;
  }

  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-text {
    font-size: 13px;
  }

  .body-edit-task .task-info {
    .body-cost {
      .total-section .total h3{
        font-size: 14px;
      }

      .total-section .total .total-value {
        font-size: 13px;
      }
    }
  }

  .body-edit-task .task-info .body-cost .cost-container .body-cost-fields:first-child .currencyinput {
    margin-top: 26px;
  }

  .body-edit-task .task-info .body-cost .cost-container .body-subtask-cost-fields:first-child .currencyinput {
    margin-top: 27px;
  }
}

@media screen and (max-width: 400px) {
  .body-edit-task .task-info {

    .body-cost {
      padding: 10px;

      .body-cost-fields, .body-subtask-cost-fields {
        .info-column {
          .info-conf {
            .info-price {
              input {
                padding: 13px 6px 10px 20px;
              }

              .currencyinput {
                margin: 13px 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-icon {
    padding: 10px 0 10px 15px;
  }

  .body-edit-task .task-info {
    .body-cost {
      .total-section .total h3{
        font-size: 13px;
      }

      .total-section .total .total-value {
        font-size: 12px;
      }
    }
  }

  .body-edit-task .task-info .body-cost .cost-container .body-cost-fields:first-child .currencyinput,
  .body-edit-task .task-info .body-cost .cost-container .body-subtask-cost-fields:first-child .currencyinput {
    margin-top: 27px;
  }
}

@media screen and (max-width: 360px) {
  .body-edit-task .task-info .body-cost .cost-container .body-subtask-cost-fields:first-child .currencyinput {
    margin-top: 26px;
  }
}

@media screen and (max-width: 340px) {
  .body-edit-task .task-files .files-container .document-item .file-info-container .file-info-container-icon {
    padding: 10px 0 10px 10px;
  }
}

